<template>
  <template v-if="dataInfoArray">
    <div class="tw-flex tw-gap-3">
      <template v-for="(data, index) in dataInfoArray" :key="index">
        <template v-if="data.type && data.type === 'groupRecipients'">
          <BlockStack :gap="600">
            <p class="tw-m-0 tw-text-lg tw-text-warning-500">
              Please choose the group you'd like add your selected recipients.
            </p>
            <BlockStack :gap="400">
              <RecipientsGroupsButtonDropdown
                :alignLeft="true"
                @handleGroupSelection="formComplete"
                size="large"
              ></RecipientsGroupsButtonDropdown>
              <div class="tw-flex tw-gap-2">
                <TheButton
                  :disabled="!isFormComplete"
                  variant="success"
                  size="large"
                  @click="confirm()"
                >
                  <template #text>Save</template>
                </TheButton>
                <TheButton variant="tertiary" size="large" @click="cancel()">
                  <template #text>Cancel</template>
                </TheButton>
              </div>
            </BlockStack>
          </BlockStack>
          <div
            class="tw-w-full tw-bg-gray-50 tw-border-solid tw-border tw-rounded-lg"
          >
            <ul class="tw-list-none tw-h-full tw-p-4 tw-m-0">
              <li class="tw-h-full">
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-h-full"
                >
                  <p class="tw-text-3xl tw-m-0 tw-font-bold">
                    {{ data.data.length }}
                  </p>
                  <p class="tw-text-xl tw-m-0">
                    Recipients will be added to group
                  </p>
                </div>
              </li>
              <!-- </template> -->
            </ul>
          </div>
        </template>

        <template v-if="data.type && data.type === 'vendors'">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <template v-if="vendors.action === 'status'">
              <p class="tw-m-0 tw-text-lg tw-text-warning-500">
                Please choose a new status for the selected vendors.
              </p>
              <template v-if="vendorStatusSelection !== ''">
                <div
                  class="tw-flex tw-items-center tw-gap-1 tw-border-solid tw-border-orange-400 tw-border-2 tw-p-4 tw-rounded-md"
                >
                  <div class="tw-flex tw-items-top tw-gap-2">
                    <b-icon-exclamation-triangle-fill
                      class="tw-text-orange-400 tw-flex-shrink-0 tw-mt-0.5"
                    ></b-icon-exclamation-triangle-fill>
                    <template v-if="vendorStatusSelection == false">
                      <p class="tw-m-0 tw-text-gray-900">
                        <span class="tw-font-bold">Offline</span> vendors will
                        be hidden from the customer-facing gift card collection.
                      </p>
                    </template>
                    <template v-if="vendorStatusSelection === true">
                      <p class="tw-m-0 tw-text-gray-900">
                        <span class="tw-font-bold">Online</span> vendors will go
                        live on the customer-facing gift card collection.
                      </p>
                    </template>
                  </div>
                </div>
              </template>
              <select
                v-model="vendorStatusSelection"
                id="vendorStatusSelection"
                name="vendorStatusSelection"
                :class="{ 'tw-ring-red-300': !vendorStatusSelection }"
                class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
              >
                <option value="" class="" disabled>Select status</option>
                <option
                  v-for="option in vendorStatusOptions"
                  :value="option.value"
                  :key="option.index"
                  class="tw-capitalize"
                >
                  {{ option.text }}
                </option>
              </select>
              <div class="tw-flex tw-gap-2">
                <TheButton
                  :disabled="vendorStatusSelection === ''"
                  variant="success"
                  size="large"
                  @click="confirm()"
                >
                  <template #text>Save</template>
                </TheButton>
                <TheButton variant="tertiary" size="large" @click="cancel()">
                  <template #text>Cancel</template>
                </TheButton>
              </div>
            </template>
            <template v-if="vendors.action === 'region'">
              <p class="tw-m-0 tw-text-lg tw-text-warning-500">
                Please choose a new region for the selected vendors.
              </p>
              <select
                v-model="vendorRegionSelection"
                id="vendorRegionSelection"
                name="vendorRegionSelection"
                :class="{ 'tw-ring-red-300': !vendorRegionSelection }"
                class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
              >
                <option value="" class="" disabled>Select region</option>
                <option
                  v-for="option in vendorRegionOptions"
                  :value="option.value"
                  :key="option.index"
                  class="tw-capitalize"
                >
                  {{ option.text }}
                </option>
              </select>
              <div class="tw-flex tw-gap-2">
                <TheButton
                  :disabled="vendorRegionSelection === ''"
                  variant="primary"
                  size="large"
                  @click="confirm()"
                >
                  <template #text>Save</template>
                </TheButton>
                <TheButton variant="tertiary" size="large" @click="cancel()">
                  <template #text>Cancel</template>
                </TheButton>
              </div>
            </template>
          </div>
          <div
            class="tw-w-full tw-bg-gray-50 tw-border-solid tw-border tw-rounded-lg"
          >
            <ul class="tw-list-none tw-h-full tw-p-4 tw-m-0">
              <li class="tw-h-full">
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-h-full"
                >
                  <p class="tw-text-5xl tw-m-0 tw-font-bold">
                    {{ data.data.length }}
                  </p>
                  <template v-if="data.type === 'groupRecipients'">
                    recipients will be added to group
                  </template>
                  <template v-else>
                    <p class="tw-text-xl tw-m-0">
                      {{ data.type }} will be edited
                    </p>
                  </template>
                </div>
              </li>
              <!-- </template> -->
            </ul>
          </div>
        </template>
      </template>
    </div>
  </template>
</template>

<script>
import TheButton from "../actions/TheButton.vue";
import RecipientsGroupsButtonDropdown from "../elements/dropdown/RecipientsGroupsButtonDropdown.vue";
import BlockStack from "../layout/BlockStack.vue";

export default {
  name: "BulkEditData",
  emits: [
    "confirmBulkEdit",
    "closeModal",
    "bulkUpdateVendorStatus",
    "bulkUpdateVendorRegion",
  ],
  components: {
    BlockStack,
    RecipientsGroupsButtonDropdown,
    TheButton,
  },
  props: {
    vendors: {
      type: Object,
      required: false,
    },
    members: {
      type: Array,
      required: false,
    },
    campaigns: {
      type: Array,
      required: false,
    },
    recipients: {
      type: Array,
      required: false,
    },
    groupRecipients: {
      type: Array,
      required: false,
    },
    media: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selectedGroup: null,
      lifecycleStageOptions: [
        { text: "Center of Influence", value: "Center of Influence" },
        { text: "Cold Lead", value: "Cold Lead" },
        { text: "Customer", value: "Customer" },
        { text: "Unlisted Lead", value: "Unlisted Lead" },
        { text: "Warm Lead", value: "Warm Lead" },
      ],
      vendorStatusOptions: [
        { text: "Offline", value: false },
        { text: "Online", value: true },
      ],
      vendorRegionOptions: [
        { text: "Baltimore", value: "Baltimore" },
        { text: "Birmingham", value: "Birmingham" },
        { text: "Charlotte", value: "Charlotte" },
        { text: "Columbia", value: "Columbia" },

        { text: "Ft. Lauderdale", value: "Ft. Lauderdale" },
        { text: "Kansas City", value: "Kansas City" },
        { text: "Louisville", value: "Louisville" },
        { text: "St. Louis", value: "St. Louis" },
      ],
      lifecycleStageSelection: "",
      vendorStatusSelection: "",
      vendorRegionSelection: "",
      isFormComplete: false,
    };
  },
  computed: {
    dataInfoArray() {
      const dataTypes = [
        { type: "campaigns", data: this.campaigns },
        { type: "vendors", data: this?.vendors?.ids },
        { type: "members", data: this.members },
        { type: "recipients", data: this.recipients },
        { type: "groupRecipients", data: this.groupRecipients },
        { type: "media", data: this.media },
      ];

      return dataTypes.filter((dataInfo) => dataInfo.data);
    },
  },
  methods: {
    confirm() {
      if (this.groupRecipients) {
        const newGroupMemberIds = this?.groupRecipients?.map(
          (recipient) => recipient._id
        );
        const existingGroupMemberIds = this.selectedGroup.members.map(
          (recipient) => recipient.doc
        );
        const recipientsIds = this.combineIds(
          existingGroupMemberIds,
          newGroupMemberIds
        );
        const groupData = {
          groupId: this.selectedGroup._id,
          recipientsIds: recipientsIds,
        };
        this.$emit("confirmBulkEdit", groupData);
      }
      if (
        this.vendorStatusSelection === true ||
        this.vendorStatusSelection === false
      ) {
        this.$emit("bulkUpdateVendorStatus", this.vendorStatusSelection);
      }
      if (this.vendorRegionSelection) {
        this.$emit("bulkUpdateVendorRegion", this.vendorRegionSelection);
      }
    },
    combineIds(existingGroupMemberIds, newGroupMemberIds) {
      // Combine both arrays and create a Set to keep only unique values
      const combinedUniqueIds = [
        ...new Set([...existingGroupMemberIds, ...newGroupMemberIds]),
      ];

      // Map the unique IDs to the specified structure
      return combinedUniqueIds.map((id) => ({
        doc: id,
        metadata: {},
      }));
    },
    formComplete(group) {
      this.selectedGroup = group;
      this.isFormComplete = true;
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
