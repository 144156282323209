<template>
  <div class="mb-3">
    <div class="bg-bold-xchange-blue text-start">
      <div class="animated fadeInUp">
        <h1 class="display-4 text-white text-center py-3">
          <slot name="heading">Add customer hero heading</slot>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompactHero",
};
</script>

<style scoped></style>
