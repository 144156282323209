<template>
  <template v-if="showLandingPagePreview">
    <Teleport to="#modal">
      <TheModal
        @closeModal="toggleLandingPagePreview"
        :open="showLandingPagePreview"
        :closeOnClickOutside="true"
      >
        <LandingPageContent
          :giftView="true"
          :campaign="campaign"
          @closeModal="toggleLandingPagePreview"
        ></LandingPageContent>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showRegionModal">
    <Teleport to="#modal">
      <TheModal
        class="tw-overflow-y-visible"
        @closeModal="toggleRegionModal"
        :open="showRegionModal"
        :closeOnClickOutside="false"
      >
        <div class="tw-text-start tw-overflow-visible">
          <BlockStack :gap="400" class="tw-w-full">
            <div class="tw-flex tw-items-center tw-gap-4 tw-w-full">
              <img
                src="../../assets/svg/illustrations-test/13_gift_box.png"
                alt="product.imageAlt"
                class="tw-h-full tw-max-w-40 tw-rounded tw-object-cover tw-object-center tw-hidden md:tw-block"
              />
              <BlockStack :gap="100" class="tw-w-full">
                <div class="tw-flex tw-justify-between">
                  <p
                    class="tw-text-xs tw-m-0 tw-text-blue-600 tw-uppercase tw-font-bold tw-tracking-wide"
                  >
                    Multiple regions available
                  </p>
                  <template v-if="isCampaignBuilder">
                    <template v-if="!getIsRegionSelected">
                      <TheLink variant="critical" @click="handleStepChange(4)"
                        ><template #text> Exit </template></TheLink
                      >
                    </template>
                    <template v-if="getIsRegionSelected">
                      <TheLink variant="critical" @click="closeRegionModal()"
                        ><template #text> Cancel </template></TheLink
                      >
                    </template>
                  </template>
                  <template v-if="!getIsRegionSelected && !isCampaignBuilder">
                    <TheLink variant="critical" @click="routeUserHome()"
                      ><template #text> Return home </template></TheLink
                    >
                  </template>
                  <template v-if="getIsRegionSelected && !isCampaignBuilder">
                    <TheLink variant="critical" @click="closeRegionModal()"
                      ><template #text> Cancel </template></TheLink
                    >
                  </template>
                </div>

                <h2 class="tw-text-xl tw-font-bold tw-m-0 tw-text-gray-900">
                  Find the perfect eGift near you
                </h2>
                <ul
                  class="tw-leading-6 tw-shadow-xs tw-text-lg tw-bg-gray-50 tw-rounded tw-list-none tw-p-2 tw-m-0 tw-overflow-auto tw-max-h-44"
                >
                  <li
                    v-for="region in regionOptions"
                    :key="region.id"
                    @click="selectRegion(region)"
                    :class="[
                      'tw-select-none tw-rounded tw-p-2 tw-cursor-pointer hover:tw-bg-gray-200 tw-my-1',
                      selectedRegions[0]?.id === region.id && 'tw-bg-gray-200',
                    ]"
                  >
                    <BlockStack :gap="400">
                      <div
                        class="tw-flex tw-items-center tw-justify-between tw-gap-2"
                      >
                        <div
                          class="tw-flex tw-items-center tw-gap-4 tw-select-none"
                        >
                          <div
                            :class="[
                              'tw-flex tw-h-6 tw-w-6 tw-flex-none tw-items-center tw-justify-center tw-rounded-lg',
                              selectedRegions[0]?.id === region.id
                                ? 'tw-bg-blue-600'
                                : 'tw-bg-gray-400',
                            ]"
                          >
                            <svg
                              v-if="selectedRegions[0]?.id === region.id"
                              class="tw-h-6 tw-w-6 tw-text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 13l4 4L19 7"
                              ></path>
                            </svg>
                            <svg
                              v-if="selectedRegions[0]?.id !== region.id"
                              class="tw-h-5 tw-w-5 tw-text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 13l4 4L19 7"
                              ></path>
                            </svg>
                          </div>
                          <p
                            :class="[
                              'tw-text-lg tw-font-medium tw-m-0',
                              selectedRegions[0]?.id === region.id
                                ? 'tw-text-gray-900'
                                : 'tw-text-gray-700',
                            ]"
                          >
                            {{ region.text }}
                          </p>
                        </div>
                        <template v-if="selectedRegions[0]?.id === region.id">
                          <TheButton
                            variant="secondary"
                            @click="handleFilteringVendors(true)"
                          >
                            <template #text>View gift cards</template>
                          </TheButton>
                        </template>
                      </div>
                      <template
                        v-if="
                          !memberHomeRegions &&
                          selectedRegions[0]?.id === region.id &&
                          (this.isBoldXchangeAdmin || this.isGiftingLead)
                        "
                      >
                        <div class="tw-bg-blue-100 tw-px-2 tw-py-1">
                          <p class="tw-m-0 tw-text-sm">
                            We'll save this as your Home Region. You can update
                            this in your
                            <RouterLink to="/account"
                              >Account Settings</RouterLink
                            >
                            anytime.
                          </p>
                        </div>
                      </template>
                    </BlockStack>
                  </li>
                </ul>
              </BlockStack>
            </div>
          </BlockStack>
        </div>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showFilterModal">
    <Teleport to="#modal">
      <TheModal
        title="Filters"
        @closeModal="toggleFilterModal"
        :open="showFilterModal"
        :closeOnClickOutside="false"
        :showModalHeader="true"
      >
        <ModalBody>
          <div
            class="tw-text-start tw-max-h-[600px] tw-overflow-y-auto tw-overflow-x-hidden"
          >
            <BlockStack :gap="400">
              <BlockStack :gap="100">
                <h4 class="">Category</h4>
                <div
                  class="tw-flex tw-items-center tw-gap-2 tw-justify-center tw-text-center tw-font-bold tw-text-xl tw-rounded tw-border-solid tw-border tw-border-gray-300 tw-p-4"
                >
                  <template v-if="activeTab === 0">
                    <b-icon-trophy></b-icon-trophy>
                  </template>
                  <template v-if="activeTab === 'Casual Dining'"
                    ><b-icon-suit-heart></b-icon-suit-heart>
                  </template>
                  <template v-if="activeTab === 'Coffee & Cafes'"
                    ><b-icon-cup-hot></b-icon-cup-hot>
                  </template>
                  <template v-if="activeTab === 'Fine Dining'"
                    ><b-icon-gem></b-icon-gem>
                  </template>
                  <template v-if="activeTab === 'Ice Cream & Desserts'"
                    ><b-icon-cake></b-icon-cake>
                  </template>
                  <template v-if="activeTab === 'Wine & Spirits'">
                    <b-icon-brightness-high></b-icon-brightness-high>
                  </template>
                  <p class="tw-m-0 tw-text-blue-800 tw-text-2xl">
                    <template v-if="activeTab === 0"
                      >Amazing gift cards</template
                    >
                    <template v-if="activeTab === 'Casual Dining'"
                      >Casual Dining</template
                    >
                    <template v-if="activeTab === 'Coffee & Cafes'"
                      >Coffee & Cafes
                    </template>
                    <template v-if="activeTab === 'Fine Dining'"
                      >Fine Dining
                    </template>
                    <template v-if="activeTab === 'Ice Cream & Desserts'"
                      >Ice Cream & Desserts
                    </template>
                    <template v-if="activeTab === 'Wine & Spirits'">
                      Wine & Spirits
                    </template>
                  </p>
                </div>
              </BlockStack>
              <BlockStack :gap="100">
                <h4>Region</h4>
                <ul
                  class="tw-leading-6 tw-shadow-xs tw-bg-gray-50 tw-text-lg tw-list-none tw-m-0 tw-p-2 tw-overflow-auto tw-max-h-44"
                >
                  <template v-if="selectedRegions.length === 0">
                    <li
                      v-for="region in regionOptions"
                      :key="region.id"
                      @click="selectRegion(region)"
                      :class="[
                        'tw-flex tw-items-center tw-justify-between tw-gap-2  tw-select-none tw-rounded tw-p-2 tw-cursor-pointer hover:tw-bg-gray-200 tw-my-1',
                        selectedRegions[0]?.id === region.id &&
                          'tw-bg-gray-200',
                      ]"
                    >
                      <div
                        class="tw-flex tw-items-center tw-gap-4 tw-select-none"
                      >
                        <div
                          :class="[
                            'tw-flex tw-h-6 tw-w-6 tw-flex-none tw-items-center tw-justify-center tw-rounded-lg',
                            selectedRegions[0]?.id === region.id
                              ? 'tw-bg-blue-600'
                              : 'tw-bg-gray-400',
                          ]"
                        >
                          <svg
                            v-if="selectedRegions[0]?.id === region.id"
                            class="tw-h-6 tw-w-6 tw-text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                          <svg
                            v-if="selectedRegions[0]?.id !== region.id"
                            class="tw-h-5 tw-w-5 tw-text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p
                          :class="[
                            'tw-text-lg tw-font-medium tw-m-0',
                            selectedRegions[0]?.id === region.id
                              ? 'tw-text-gray-900'
                              : 'tw-text-gray-700',
                          ]"
                        >
                          {{ region.text }}
                        </p>
                      </div>
                      <template v-if="selectedRegions[0]?.id === region.id">
                        <TheButton
                          variant="secondary"
                          @click="handleFilteringVendors(true)"
                        >
                          <template #text>View gift cards</template>
                        </TheButton>
                      </template>
                    </li>
                  </template>
                  <template v-else>
                    <li
                      v-for="region in regionOptions"
                      :key="region.id"
                      @click="setFilterRegion(region)"
                      :class="[
                        'tw-flex tw-items-center tw-justify-between tw-gap-2  tw-select-none tw-rounded tw-p-2 tw-cursor-pointer hover:tw-bg-gray-200 tw-my-1',
                        filter.selectedRegions[0]?.id === region.id &&
                          'tw-bg-gray-200',
                      ]"
                    >
                      <div
                        class="tw-flex tw-items-center tw-gap-4 tw-select-none"
                      >
                        <div
                          :class="[
                            'tw-flex tw-h-4 tw-w-4 tw-flex-none tw-items-center tw-justify-center',
                            filter.selectedRegions[0]?.id === region.id
                              ? 'tw-bg-blue-600'
                              : 'tw-bg-gray-400',
                          ]"
                        >
                          <svg
                            v-if="filter.selectedRegions[0]?.id === region.id"
                            class="tw-h-4 tw-w-4 tw-text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                          <svg
                            v-if="filter.selectedRegions[0]?.id !== region.id"
                            class="tw-h-5 tw-w-5 tw-text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p
                          :class="[
                            'tw-text-lg tw-font-medium tw-m-0',
                            filter.selectedRegions[0]?.id === region.id
                              ? 'tw-text-gray-900'
                              : 'tw-text-gray-700',
                          ]"
                        >
                          {{ region.text }}
                        </p>
                      </div>
                    </li>
                  </template>
                </ul>
              </BlockStack>
              <BlockStack :gap="100">
                <h4>Neighborhood</h4>
                <div class="tw-grid tw-grid-cols-2">
                  <div
                    v-for="(neighborhood, index) in visibleNeighborhoods"
                    :key="index"
                  >
                    <label class="tw-truncate" :for="`neighborhood-${index}`">
                      <input
                        type="checkbox"
                        :id="`neighborhood-${index}`"
                        :value="neighborhood.value"
                        v-model="selectedNeighborhoods"
                        @change="updateFilterCount()"
                      />
                      {{ neighborhood.text }}
                    </label>
                  </div>
                  <template
                    v-if="
                      showMoreNeighborhoods && neighborhoodsOptions.length > 10
                    "
                  >
                    <a
                      @click="showAllNeighborhoods()"
                      class="tw-font-bold tw-text-blue-700 tw-underline hover:tw-text-blue-600"
                      >Show more</a
                    >
                  </template>
                </div>
              </BlockStack>
            </BlockStack>
          </div>
        </ModalBody>
        <ModalFooter>
          <div class="tw-flex tw-gap-2 tw-justify-end">
            <TheButton variant="tertiary" @click="clearFilterSelection()">
              <template #text>Clear selection</template>
            </TheButton>

            <TheButton
              :variant="buttonVariant"
              :disabled="isButtonDisabled"
              @click="handleButtonClick"
              :hasLeadingIcon="filterCountIsLoading"
            >
              <template #leading-icon>
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="tw-sr-only">loading...</span>
                </div>
              </template>
              <template #text>{{ buttonText }}</template>
            </TheButton>
          </div>
        </ModalFooter>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="!isViewingSelection">
    <div class="tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-shadow-md">
      <div
        class="tw-w-full tw-px-4 sm:tw-px-8 tw-flex tw-gap-y-2 tw-items-center tw-justify-center tw-flex-wrap-reverse tw-mx-auto"
      >
        <div class="tw-overflow-x-auto tw-overflow-y-hidden">
          <div class="tw-overflow-x-auto">
            <div class="tw-overflow-x-auto tw-overflow-y-hidden">
              <ul
                class="tw-list-none tw-m-0 tw-p-0 tw--mb-px tw-flex tw-space-x-10"
              >
                <li
                  as="template"
                  v-for="tab in tabs"
                  :key="tab.name"
                  @click="setCategoryValue(tab.value)"
                >
                  <a
                    :class="[
                      activeTab === tab.value
                        ? 'tw-border-blue-500 tw-border-solid tw-pb-4 tw-text-blue-600 tw-font-bold'
                        : 'tw-border-transparent tw-text-gray-900 hover:tw-border-blue-600 hover:tw-border-solid hover:tw-text-blue-600',
                      'tw-whitespace-nowrap tw-pb-4  tw-border-solid tw-border-x-0 tw-border-t-0 tw-text-sm tw-flex tw-flex-col tw-justify-center tw-items-center gap-2 tw-pt-3',
                    ]"
                  >
                    <template v-if="tab.value === 0"
                      ><b-icon-trophy class=""></b-icon-trophy
                    ></template>
                    <template v-if="tab.value === 'Casual Dining'"
                      ><b-icon-suit-heart></b-icon-suit-heart
                    ></template>
                    <template v-if="tab.value === 'Coffee & Cafes'"
                      ><b-icon-cup-hot></b-icon-cup-hot
                    ></template>
                    <template v-if="tab.value === 'Fine Dining'"
                      ><b-icon-gem></b-icon-gem
                    ></template>
                    <template v-if="tab.value === 'Ice Cream & Desserts'">
                      <b-icon-cake></b-icon-cake
                    ></template>
                    <template v-if="tab.value === 'Wine & Spirits'">
                      <b-icon-brightness-high></b-icon-brightness-high>
                    </template>

                    <span>{{ tab.name }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="tw-flex tw-items-center tw-justify-center tw-px-4 tw-pt-4 tw-gap-2 flex-grow-1 sm:tw-pl-10 sm:tw-pr-0 -tw-z-0"
        >
          <TheButton
            :active="this.filter.active"
            class="tw-relative"
            @click="toggleFilterModal"
            variant="tertiary"
            :hasLeadingIcon="true"
            ><template #text><b-icon-sliders></b-icon-sliders>Filters</template>
            <template #active>
              <template v-if="this.filter.active">
                <b-icon-check-circle-fill
                  class="tw-absolute -tw-top-1 -tw-right-1 tw-h-4 tw-w-4 tw-border-gray-300 tw-text-blue-500 tw-bg-white tw-rounded-full focus:tw-ring-blue-600 tw-z-10"
                ></b-icon-check-circle-fill>
              </template>
            </template>
          </TheButton>
          <div class="tw-max-w-lg tw-w-full tw-flex-grow">
            <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
              <div
                class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
              >
                <b-icon-search
                  class="tw-h-5 tw-w-5 tw-text-gray-400"
                  aria-hidden="true"
                ></b-icon-search>
              </div>
              <input
                @keyup.enter="handleSearchQuery()"
                v-model="searchQuery.value"
                type="text"
                name="giftCardsSearch"
                id="giftCardsSearch"
                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                :placeholder="`Search ${getSelectedRegion} region`"
              />
              <template v-if="searchQuery.active">
                <div
                  @click="resetFilters()"
                  class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-cursor-pointer"
                >
                  <span>reset</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ThePage>
      <template v-if="vendorsAreLoading">
        <div class="tw-w-full">
          <GiftCardsGridSkeleton :numberOfCards="20"></GiftCardsGridSkeleton>
        </div>
      </template>
      <template v-if="!vendorsAreLoading && count > 0">
        <div class="tw-w-full">
          <template v-if="!isToggled && !isGift && isCampaignBuilder">
            <div
              class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-pb-2"
            >
              <div>
                <p class="tw-text-lg tw-text-gray-700 tw-p-0 tw-m-0">
                  <span
                    class="tw-font-bold"
                    :class="{
                      'tw-text-red-500': selectedGiftCards.length === 0,
                      'tw-text-green-700': selectedGiftCards.length > 0,
                    }"
                    >Selected gift cards:</span
                  >
                  {{ selectedGiftCards.length }}
                </p>
                <template v-if="selectedGiftCards.length > 0">
                  <TheLink variant="primary" @click="handleSelectionView"
                    ><template #text>
                      {{
                        isViewingSelection
                          ? "Return to catalog"
                          : "View your selection"
                      }}
                    </template></TheLink
                  >
                </template>
              </div>
              <div class="tw-flex tw-items-center tw-gap-2 tw-pb-2">
                <TheButton
                  :active="this.filter.active"
                  @click="selectAllVisible"
                  variant="primary"
                  ><template #text>Select all visible</template>
                </TheButton>
                <TheButton
                  :active="this.filter.active"
                  @click="deselectAll"
                  variant="tertiary"
                  ><template #text>Clear all selected</template>
                </TheButton>
              </div>
            </div>
          </template>
          <ul
            role="list"
            class="tw-m-0 tw-p-0 tw-list-none tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-8 xs:tw-grid-cols-2 sm:tw-grid-cols-2 sm:tw-gap-x-6 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 xl:tw-gap-x-8"
          >
            <GiftCardsListItem
              v-for="vendor in vendors"
              :key="vendor.id"
              :selectedGiftCards="selectedGiftCards"
              :vendor="vendor"
              @toggleGiftCardSelection="toggleGiftCardSelection"
              :isViewingSelection="isViewingSelection"
              :isToggled="isToggled"
              :isCampaignBuilder="isCampaignBuilder"
              :isGift="isGift"
              :campaign="campaign"
            >
            </GiftCardsListItem>
          </ul>
          <template v-if="!isLastPage">
            <div
              class="tw-flex tw-flex-col tw-my-10 tw-max-w-xl tw-justify-center tw-items-center tw-mx-auto"
            >
              <p class="tw-font-bold tw-text-lg">
                Continue exploring gift cards
              </p>
              <TheButton
                :hasLeadingIcon="true"
                variant="primary"
                size="large"
                @click="nextPage()"
              >
                <template #text>Show more</template>
              </TheButton>
            </div>
          </template>
        </div>
      </template>
      <template v-if="!vendorsAreLoading && count === 0">
        <TheBox class="tw-mx-auto">
          <BlockStack
            :gap="100"
            class="tw-flex tw-text-center tw-items-center tw-justify-center"
          >
            <div>
              <img
                style="max-height: 150px; width: auto"
                src="../../assets/svg/illustrations-test/14_no_query_results.png"
                alt="Empty State Image"
              />
            </div>
            <h1
              class="tw-m-0 tw-text-lg tw-font-bold sm:tw-text-xl sm:tw-tracking-tight"
            >
              Try updating your search/filters or
              <a class="tw-font-bold" @click="resetFilters()">click here</a>
              to reset.
            </h1>
          </BlockStack>
        </TheBox>
      </template>
    </ThePage>
  </template>
  <template v-if="isViewingSelection">
    <ThePage>
      <div class="tw-w-full">
        <div
          class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-pb-2"
        >
          <div>
            <p class="tw-text-lg tw-text-gray-700 tw-p-0 tw-m-0">
              <span
                class="tw-font-bold"
                :class="{
                  'tw-text-red-500': selectedGiftCards.length === 0,
                  'tw-text-green-700': selectedGiftCards.length > 0,
                }"
                >Selected gift cards:</span
              >
              {{ selectedGiftCards.length }}
            </p>
            <TheLink variant="primary" @click="handleSelectionView"
              ><template #text>
                {{
                  isViewingSelection
                    ? "Return to catalog"
                    : "View your selection"
                }}
              </template></TheLink
            >
          </div>
          <template v-if="selectedGiftCards.length > 0">
            <div class="tw-flex tw-items-center tw-gap-2 tw-pb-2">
              <TheButton
                :active="this.filter.active"
                @click="deselectAll"
                variant="tertiary"
                ><template #text>Clear all selected</template>
              </TheButton>
            </div>
          </template>
        </div>
        <template v-if="selectedGiftCards.length > 0">
          <ul
            role="list"
            class="tw-w-full tw-m-0 tw-p-0 tw-list-none tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-3 sm:tw-gap-x-6 lg:tw-grid-cols-4 xl:tw-gap-x-8"
          >
            <GiftCardsListItem
              v-for="vendor in selectedGiftCards"
              :key="vendor.id"
              :selectedGiftCards="selectedGiftCards"
              :vendor="vendor"
              @toggleGiftCardSelection="toggleGiftCardSelection"
              :isViewingSelection="isViewingSelection"
              :isToggled="isToggled"
              :isCampaignBuilder="isCampaignBuilder"
              :isGift="isGift"
              :campaign="campaign"
            >
            </GiftCardsListItem>
          </ul>
        </template>
        <template v-else>
          <TheCard>
            <TheBox :noTopPadding="true">
              <EmptyState emptyState="customCardsSelection"></EmptyState>
            </TheBox>
          </TheCard>
        </template>
      </div>
    </ThePage>
  </template>
</template>

<script>
import GiftCardsListItem from "./GiftCardsListItem.vue";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheButton from "../../components-v2/ui/actions/TheButton";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalFooter from "../../components-v2/ui/layout/ModalFooter.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import GiftCardsGridSkeleton from "../../components-v2/ui/skeletons/GiftCardsGridSkeleton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import LandingPageContent from "../../components-v2/ui/modals/LandingPageContent.vue";
import userMixin from "../../mixins/userMixin";
import userMediaMixin from "../../mixins/userMediaMixin";

export default {
  inject: ["mixpanel"],
  name: "GiftCardsList",
  emits: [
    "updateSelectedGiftCards",
    "toggleSelectionView",
    "handleStepChange",
    "setSelectedRegion",
    "handleTriggerRegionSelection",
  ],
  components: {
    LandingPageContent,
    GiftCardsListItem,
    ThePage,
    TheButton,
    ModalBody,
    TheModal,
    ModalFooter,
    TheBox,
    BlockStack,
    GiftCardsGridSkeleton,
    TheLink,
    TheCard,
    EmptyState,
  },
  mixins: [userMixin, userMediaMixin],
  props: {
    categoriesFilter: {
      type: Array,
      required: false,
    },
    isToggled: {
      type: Boolean,
      required: true,
    },
    isRegionSelected: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      default: null,
    },
    custom_selection_ids: {
      type: [Array, Number],
      required: false,
    },
    isGift: {
      type: Boolean,
      default: false,
    },
    isCampaignBuilder: {
      type: Boolean,
      required: false,
    },
  },
  async created() {
    console.log("campaign: ", this.campaign);
    await this.refreshUser();
    await this.initializeCampaignData();
  },
  watch: {
    campaign: {
      handler(newVal, oldVal) {
        if (!newVal) return; // Check if campaign is truthy before proceeding

        // Process the campaign data
        this.processCampaignData(newVal);
      },
      immediate: false,
    },
    isRegionSelected(newValue) {
      if (newValue) {
        this.toggleRegionModal();
      }
    },
  },
  data() {
    return {
      requestBody: {},
      filter: {
        active: false,
        matchExists: false,
        count: 0,
        selectedRegions: [],
      },
      searchQuery: {
        active: false,
        value: "",
      },
      tabs: [
        {
          name: "Amazing gift cards",
          value: 0,
        },
        {
          name: "Casual Dining",
          value: "Casual Dining",
        },
        {
          name: "Coffee & Cafes",
          value: "Coffee & Cafes",
        },
        {
          name: "Fine Dining",
          value: "Fine Dining",
        },
        {
          name: "Ice Cream & Desserts",
          value: "Ice Cream & Desserts",
        },
        {
          name: "Wine & Spirits",
          value: "Wine & Spirits",
        },
      ],
      regions: [
        "Baltimore",
        "Birmingham",
        "Charlotte",
        "Columbia",
        "Kansas City",
        "Louisville",
        "St. Louis",
      ],
      selectedRegions: [],
      selectedGiftCards: [],
      neighborhoodsOptions: [],
      selectedCategories: [],
      selectedNeighborhoods: [],
      visibleNeighborhoods: [],
      categoryOptions: [],
      citiesOptions: [],
      vendors: [],
      originalCategories: [],
      currentCategories: [],
      selectedCategoryTab: { name: "Full collection", value: 0 },
      selectedCityValue: 0,
      selectedPriceValue: 0,
      priceValue: 0,
      count: 0,
      pageSize: 40,
      currentPage: 0,
      activeTab: 0,
      customSelectionIds: 0,
      giftCardAmount: 0,
      sortedValue: "name",

      route: "",
      urlOrigin: "",
      showLandingPagePreview: false,
      showRegionModal: false,
      isViewingSelection: false,
      showFilterModal: false,
      isLoading: true,
      showMoreCategories: true,
      showMoreNeighborhoods: true,
      campaignsAreLoading: false,
      vendorsAreLoading: true,
      filterCountIsLoading: false,
      linkCopied: false,
      canCopy: false,
      people: [
        { id: 1, name: "Leslie Alexander" },
        { id: 2, name: "Michael Foster" },
        { id: 3, name: "Dries Vincent" },
        { id: 4, name: "Lindsay Walton" },
        { id: 5, name: "Courtney Henry" },
        { id: 6, name: "Tom Cook" },
        { id: 7, name: "Whitney Francis" },
      ],
      selectedPerson: { id: 3, name: "Dries Vincent" },
      isOpen: false,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    Avatar() {
      const url = this?.User?.photo;
      if (url) {
        if (url.indexOf("/") === 0) {
          return process.env.VUE_APP_API_BASE_URL + url;
        } else {
          return url;
        }
      } else {
        return `https://api.dicebear.com/7.x/initials/svg?seed=${this?.User?.firstName}%20${this?.User?.lastName}&backgroundColor=3949ab`;
      }
    },
    getCampaignGiftingLeadName() {
      return this?.campaign?.from?.name;
    },
    getCampaignLandingPageTitle() {
      return this?.campaign?.metadata?.giftNote?.title;
    },
    getCampaignLandingPageMessage() {
      return this?.campaign?.metadata?.giftNote?.message;
    },
    getIsRegionSelected() {
      return this.isRegionSelected;
    },
    getSelectedRegion() {
      return this?.filter?.selectedRegions[0]?.value;
    },
    regionOptions() {
      // Sort the regions alphabetically
      const sortedRegions = this.regions.slice().sort();

      // Map over the sorted array to create objects with dynamic IDs
      return sortedRegions.map((region, index) => {
        return {
          id: index + 1,
          text: region,
          value: region,
        };
      });
    },
    buttonVariant() {
      if (this.filter.count) {
        return "secondary";
      } else if (
        (!this.filter.count && this.selectedNeighborhoods.length > 0) ||
        !this.count
      ) {
        return "tertiary";
      } else {
        return "secondary";
      }
    },
    isButtonDisabled() {
      return (
        !this.filter.count ||
        this.filterCountIsLoading ||
        (!this.count && !this.filter.count)
      );
    },
    buttonText() {
      if (this.filterCountIsLoading) {
        return "Loading options";
      }
      if (this.filter.count) {
        return `Show ${this.filter.count} gift cards`;
      }
      if (!this.filter.count || !this.count) {
        return "No exact matches";
      } else {
        return `Show ${this.count} gift cards`;
      }
    },
    isLastPage() {
      return this.vendors.length === this.count;
    },
    currentView() {
      return this.$route.hash;
    },
    getCollectionUrl() {
      return this.urlOrigin + this.$route.fullPath;
    },
    getActiveFilterCount() {
      const neighborhoodsParams = Array.isArray(this.$route.query.neighborhoods)
        ? this.$route.query.neighborhoods.length
        : this.$route.query.neighborhoods
        ? 1
        : 0;

      const priceParams = Array.isArray(this.$route.query.price)
        ? this.$route.query.price.length
        : this.$route.query.price
        ? 1
        : 0;

      const totalCount = neighborhoodsParams + priceParams;

      return totalCount;
    },
    getCampaignSelectionRequestBody() {
      return this.$store.getters["campaigns/getCampaignSelectionRequestBody"];
    },
    getRegionSelection() {
      return this.filter.selectedRegions[0].value;
    },
    memberHomeRegions() {
      const homeRegions =
        this?.userMixinData?.metadata?.campaignSettings?.homeRegions;
      return Array.isArray(homeRegions) ? [...homeRegions] : [];
    },
    memberId() {
      return this?.$store?.state?.auth?.user;
    },
  },
  methods: {
    async initializeCampaignData() {
      await this.$store.dispatch("auth/refreshUser");
      // if (this.campaign) {
      this.processCampaignData(this.campaign);
      // this.handleAdditionalCampaignData(this.campaign);
      // }
    },
    processCampaignData(campaign) {
      console.log("memberHomeRegions: ", this.memberHomeRegions);

      if (campaign && this.checkMetadataRegionExists(campaign)) {
        // alert()
        // Process regions based on campaign data
        const regionCondition =
          campaign.metadata.collection_query.requestBody.filter.$and.find(
            (condition) => condition.hasOwnProperty("metadata.region")
          );
        const regions = regionCondition?.["metadata.region"]?.$in || [];
        const regionsObjectsArr = this.regionOptions;

        const newRegions = regionsObjectsArr.filter((regionObj) =>
          regions.includes(regionObj.value)
        );

        console.log("regions: ", regions);

        this.selectedRegions = newRegions;
        this.filter.selectedRegions = newRegions;
        this.filter.active = true;
        this.setSelectedRegion();
      } else if (this.memberHomeRegions) {
        // alert()
        // Process regions based on member home regions
        const regions = this.memberHomeRegions;
        const regionsObjectsArr = this.regionOptions;
        const newRegions = regionsObjectsArr.filter((regionObj) =>
          regions.includes(regionObj.value)
        );

        console.log("regions: ", regions);

        this.selectedRegions = newRegions;
        this.filter.selectedRegions = newRegions;
        this.filter.active = true;
        this.setSelectedRegion();
        // this.handleFilteringVendors(true);
      } else {
        this.toggleRegionModal();
      }

      // Handle other campaign data like giftCardAmount, customSelectionIds, etc.
      this.handleAdditionalCampaignData(campaign);
    },
    handleAdditionalCampaignData(campaign) {
      this.giftCardAmount =
        campaign?.metadata?.collection_query?.gift_card_amount || null;
      this.customSelectionIds =
        campaign?.metadata?.collection_query?.custom_selection_ids || 0;

      // console.log("gift card amount: ", this.giftCardAmount);

      if (this.customSelectionIds?.length > 0 && !this.isGift) {
        this.handleCustomGiftSelection();
        this.handleLoadingData();
      } else if (
        this.customSelectionIds?.length > 0 &&
        this.customSelectionIds !== 1 &&
        this.isGift
      ) {
        // alert()
        this.handleLoadingGiftCollection();
        this.handleShowingCampaignGiftNote();
      } else if (this.customSelectionIds === 1 && this.isGift) {
        // alert()
        this.handleLoadingData();
        this.handleShowingCampaignGiftNote();
      } else {
        // alert()
        this.handleLoadingData();
      }
    },
    /**  */
    formatRegions(regions) {
      // Sort the regions alphabetically
      const sortedRegions = regions.slice().sort();

      // Map over the sorted array to create objects with dynamic IDs
      return sortedRegions.map((region, index) => {
        return {
          id: index + 1,
          text: region,
          value: region,
        };
      });
    },
    checkMetadataRegionExists(campaign) {
      // alert()
      return (
        campaign?.metadata?.collection_query?.requestBody?.filter?.$and?.some(
          (condition) => condition.hasOwnProperty("metadata.region")
        ) || false
      );
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    async selectRegion(region) {
      this.selectedRegions = [region];
      this.filter.selectedRegions = [region];
      this.updateFilterCount();

      // await this.loadFilteredVendors();
      // this.filter.active = true;

      // this.toggleRegionModal();
    },
    setFilterRegion(region) {
      this.filter.selectedRegions = [region];
      this.updateFilterCount();
    },
    handleButtonClick() {
      if (
        this.filter.count ||
        (!this.filter.count && !this.selectedNeighborhoods.length)
      ) {
        // this.handleFilterChange(true);
        this.setSelectedRegion();
        this.handleFilteringVendors(true);
      }
    },

    /** campaigns */
    async handleCustomGiftSelection() {
      this.loadDraftCampaignSelection();
      this.setNeighborhoodsList();
    },
    handleLoadingGiftCollection() {
      this.loadGiftCollection();
      this.setNeighborhoodsList();

      // alert("success loading campaign gift");
    },
    handleLoadingData() {
      this.loadPageWithVendors();
      this.setNeighborhoodsList();
      // this.handleShowingCampaignGiftNote();
      // this.trackFilter();
    },
    handleShowingCampaignGiftNote() {
      if (this?.campaign?.metadata?.landingPageSettings?.enabled) {
        this.toggleLandingPagePreview();
      }
      // console.log('show note')
    },
    toggleLandingPagePreview() {
      // console.log('show note')
      this.showLandingPagePreview = !this.showLandingPagePreview;
    },

    createRequestBody() {
      const filters = [
        this.createOnlineFilter(),
        this.createRegionFilter(),
        this.createCategoriesFilter(),
        this.createNeighborhoodsFilter(),
      ];
      console.log("Filters:", filters);

      return this.combineFilters(filters);
    },
    combineFilters(filters) {
      // console.log("Combining Filters:", filters);

      let statusFilter = filters[0]; // Assuming status filter is always the first filter
      let otherFilters = filters
        .slice(1)
        .filter((filter) => Object.keys(filter).length > 0);

      // console.log("Status Filter in combineFilters:", statusFilter);
      // console.log("Other Filters in combineFilters:", otherFilters);

      if (Object.keys(statusFilter).length === 0 && otherFilters.length === 0) {
        return {};
      }

      let combinedFilter = {
        $and: [],
      };

      if (statusFilter.$and && statusFilter.$and.length > 0) {
        combinedFilter.$and.push(...statusFilter.$and);
      } else if (Object.keys(statusFilter).length > 0) {
        combinedFilter.$and.push(statusFilter);
      }

      if (otherFilters.length > 0) {
        combinedFilter.$and.push(...otherFilters);
      }

      if (combinedFilter.$and.length === 0) {
        return {};
      }

      // console.log("Final Combined Filter:", combinedFilter);
      return combinedFilter;
    },
    createOnlineFilter() {
      let filter = {};
      //  "metadata.isOnline": true
      filter = {
        "metadata.isOnline": true,
      };
      // console.log("Region Filter:", filter);
      return filter;
    },
    createRegionFilter() {
      let filter = {};

      if (
        this.filter.selectedRegions.length === 0 ||
        this.selectedRegions.length === 0
      ) {
        return filter;
      }

      filter = {
        "metadata.region": { $in: [this.filter.selectedRegions[0].value] },
      };
      // console.log("Region Filter:", filter);
      return filter;
    },
    createCategoriesFilter() {
      let filter = {};

      if (this.selectedCategories.length === 0) {
        return filter;
      }

      filter = {
        categories: { $in: this.selectedCategories },
      };

      // console.log("Categories Filter:", filter);
      return filter;
    },
    createNeighborhoodsFilter() {
      let filter = {};

      if (this.selectedNeighborhoods.length === 0) {
        return filter;
      }

      filter = {
        "metadata.neighborhoods": { $in: this.selectedNeighborhoods },
      };

      // console.log("Neighborhoods Filter:", filter);
      return filter;
    },

    /** gift cards */
    async loadPageWithVendors() {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      this.currentPage = 1;

      let filterParam = {};
      if (this?.campaign?.metadata?.collection_query?.requestBody) {
        // alert()
        if (this?.campaign?.metadata?.collection_query?.requestBody?.tabName) {
          if (
            this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
            "Full collection"
          ) {
            this.activeTab = 0;
            this.selectedCategoryTab.name =
              this?.campaign?.metadata?.collection_query?.requestBody?.tabName;
          } else {
            this.activeTab =
              this?.campaign?.metadata?.collection_query?.requestBody?.tabName;
          }
          this.activeTab;
          this.selectedCategoryTab.name =
            this?.campaign?.metadata?.collection_query?.requestBody?.tabName;
        }

        filterParam =
          this?.campaign?.metadata?.collection_query?.requestBody?.filter || {};
        let requestBody = {
          tabName: this.selectedCategoryTab.name,
          filter: filterParam,
        };

        console.log("filterParam: ", filterParam);

        // this.$store.commit(
        //   "campaigns/SET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY",
        //   requestBody
        // );

        if (!this.isGift) {
          this.$store.commit(
            "campaigns/SET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY",
            requestBody
          );
        }
      } else {
        filterParam = this.createRequestBody(); // Use createRequestBody here

        if (this.selectedCategoryTab.value !== 0) {
          const categories = this.selectedCategoryTab.value;
          filterParam.$or = [
            { categories: { $in: categories } },
            { "metadata.tags": { $in: categories } },
          ];
        }

        if (this.giftCardAmount) {
          // console.log("gift card amount: ", this.giftCardAmount);

          filterParam.$and = [
            ...(filterParam.$and || []),
            { "metadata.gift_card_minimum": { $lte: this.giftCardAmount } },
          ];
        }

        if (
          this?.campaign?.metadata?.collection_query?.custom_selection_ids &&
          this?.campaign?.metadata?.collection_query?.custom_selection_ids !== 1
        ) {
          filterParam.$and = [
            ...(filterParam.$and || []),
            {
              _id: {
                $in: this?.campaign?.metadata?.collection_query
                  ?.custom_selection_ids,
              },
            },
          ];
        }
      }

      const selection = "media description metadata name categories address";
      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      try {
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        this.vendorsAreLoading = false;
        console.log("Vendors did not load", error);
      }
    },

    async loadFilteredVendors(page) {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      this.currentPage = page === undefined ? this.currentPage : page;
      const previousScrollY = window.scrollY; // Store current scroll position
      const newLimit = this.currentPage * this.pageSize;

      let filterParam = this.createRequestBody()
        ? this.createRequestBody()
        : {};

      if (this.selectedCategoryTab.value !== 0) {
        const categories = this.selectedCategoryTab.value;
        filterParam.$or = [
          { categories: { $in: categories } },
          { "metadata.tags": { $in: categories } },
        ];
      }

      if (this.giftCardAmount) {
        // console.log("gift card amount: ", this.giftCardAmount);

        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.gift_card_minimum": { $lte: this.giftCardAmount } },
        ];
      }

      let requestBody = {
        tabName: this.selectedCategoryTab.name,
        filter: filterParam,
      };

      console.log("requestBody: ", requestBody);
      console.log("filterParam: ", filterParam);

      if (!this.isGift) {
        this.$store.commit(
          "campaigns/SET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY",
          requestBody
        );
      }

      // alert()

      const selection = "media description metadata name categories address";
      const queryParameters = {
        skip: 0,
        limit: newLimit,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      try {
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
        this.$nextTick(() => {
          window.scrollTo(0, previousScrollY);
        });
      } catch (error) {
        this.vendorsAreLoading = false;
        console.log("Vendors did not load", error);
      }
    },

    async loadDraftCampaignSelection() {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;

      const customSelection =
        this?.campaign?.metadata?.collection_query?.custom_selection_ids &&
        this?.campaign?.metadata?.collection_query?.custom_selection_ids !== 1
          ? this?.campaign?.metadata?.collection_query?.custom_selection_ids
          : false;

      let filterParam = {};
      // let filterParam = this.createRequestBody(); // Use createRequestBody here

      if (customSelection) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          { _id: { $in: customSelection } },
        ];
      }

      const selection = "media description metadata name categories address";
      const queryParameters = {
        skip: 0,
        limit: 40,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      try {
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        const customSelectionRecords = response.data.result.records;

        this.selectedGiftCards = customSelectionRecords || [];
        if (this.isGift) {
          this.vendors = customSelectionRecords;
        } else {
          const selectedVendorIds = customSelectionRecords.map(
            (vendor) => vendor._id
          );
          this.$emit("updateSelectedGiftCards", selectedVendorIds);
        }
      } catch (error) {
        this.vendorsAreLoading = false;
        console.log("Vendors did not load", error);
      }
    },

    async loadGiftCollection() {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;

      if (this?.campaign?.metadata?.collection_query?.requestBody) {
        // alert()
        if (this?.campaign?.metadata?.collection_query?.requestBody?.tabName) {
          if (
            this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
            "Full collection"
          ) {
            this.activeTab = 0;
            this.selectedCategoryTab.name =
              this?.campaign?.metadata?.collection_query?.requestBody?.tabName;
          } else {
            this.activeTab =
              this?.campaign?.metadata?.collection_query?.requestBody?.tabName;
          }
          this.activeTab;
          this.selectedCategoryTab.name =
            this?.campaign?.metadata?.collection_query?.requestBody?.tabName;
        }
      }

      const customSelection =
        this?.campaign?.metadata?.collection_query?.custom_selection_ids &&
        this?.campaign?.metadata?.collection_query?.custom_selection_ids !== 1
          ? this?.campaign?.metadata?.collection_query?.custom_selection_ids
          : false;

      let filterParam = this.createRequestBody(); // Use createRequestBody here

      if (customSelection) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          { _id: { $in: customSelection } },
        ];
      }

      const selection = "media description metadata name categories address";
      const queryParameters = {
        limit: 40,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      try {
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        const customSelectionRecords = response.data.result.records;
        this.vendors = customSelectionRecords;
        this.count = response.data.result.count;
      } catch (error) {
        this.vendorsAreLoading = false;
        console.log("Vendors did not load", error);
      }
    },
    async nextPage() {
      await this.loadFilteredVendors(this.currentPage + 1);
    },
    async getAllVendorNeighborhoods() {
      /**@type {import('@boldexchange/sdk/defs').Client} */
      const api = await this.getBoldClient();
      let filterParam = {};

      if (this.filter.selectedRegions.length > 0) {
        filterParam = {
          "metadata.region": { $in: [this.filter.selectedRegions[0].value] },
        };
      }

      // if (this.selectedRegions.length > 0) {
      //   filterParam = {
      //     "metadata.region": { $in: [this.selectedRegions[0].value] },
      //   };
      // }

      const queryParameters = {
        distinct: "metadata.neighborhoods",
      };

      try {
        const neighborhoodsRes = await api.listVendors(queryParameters, {
          filter: filterParam,
        });
        const neighborhoodsArr = neighborhoodsRes.data.result.records;
        return neighborhoodsArr;
      } catch (error) {
        this.vendorsAreLoading = false;
        console.log("tags did not load", error);
      }
    },
    async setNeighborhoodsList() {
      try {
        const neighborhoods = await this.getAllVendorNeighborhoods();
        const options = neighborhoods.map((neighborhood) => ({
          text: neighborhood,
          value: neighborhood,
        }));

        this.neighborhoodsOptions = options;
        this.visibleNeighborhoods = options.slice(0, 10); // Adjust the number as needed
      } catch (error) {
        // Handle errors appropriately
        console.error("Error transforming neighborhoods:", error);
        // You might want to maintain the existing options or set to an empty array
        this.neighborhoodsOptions = []; // Or maintain the existing options
      }
    },
    async getVendorsBySearchQuery(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      if (page === undefined) {
        this.currentPage = 1;
      } else {
        this.currentPage = page;
      }

      let filterParam = {
        $or: [
          { categories: { $in: this.categoriesFilter } },
          { "metadata.tags": { $in: this.categoriesFilter } },
        ],
        $and: [
          {
            "metadata.isOnline": true,
          },
          {
            "metadata.region": { $in: [this.filter.selectedRegions[0].value] },
          },
        ],
      };

      if (this.searchQuery.active) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          {
            $or: [
              { name: { $regex: this.searchQuery.value } },
              { categories: { $regex: this.searchQuery.value } },
              { "address.city": { $regex: this.searchQuery.value } },
              { "address.postalCode": { $regex: this.searchQuery.value } },
              { "metadata.tags": { $regex: this.searchQuery.value } },
              {
                "metadata.neighborhoods": {
                  $regex: this.searchQuery.value,
                },
              },
              {
                "metadata.website_url": { $regex: this.searchQuery.value },
              },
            ],
          },
        ];
      }

      let selection = "media description metadata name categories address ";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
    async getAllCities() {
      try {
        const api = await this.getBoldClient();
        const citiesRes = await api.listAddresses({
          distinct: "city",
        });
        return citiesRes.data.result.records;
      } catch (error) {
        // Handle errors appropriately
        console.error("Error fetching product categories:", error);
        return []; // Return an empty array or handle error condition
      }
    },
    updateFilterCount() {
      this.setNeighborhoodsList();
      this.loadFilterCount();
    },
    async loadFilterCount() {
      const api = await this.getBoldClient();
      this.filterCountIsLoading = true;

      let filterParam = this.createRequestBody();

      if (this.selectedCategoryTab.value !== 0) {
        // alert()
        const categories = this.selectedCategoryTab.value;
        filterParam.$or = [
          { categories: { $in: categories } },
          { "metadata.tags": { $in: categories } },
        ];
      }

      // if (
      //   this.selectedCategoryTab.name &&
      //   this.selectedCategoryTab.value === 0
      // ) {
      //   // alert()

      //   const categories = this.getCampaignSelectionRequestBody?.filter
      //     ?.$or || [
      //     { categories: { $in: this.categoriesFilter } },
      //     { "metadata.tags": { $in: this.categoriesFilter } },
      //   ];
      //   filterParam.$or = categories;
      // }

      // if (this.filter.selectedRegions.length !== 0) {
      //   filterParam.$and = [
      //     ...(filterParam.$and || []),
      //     {
      //       "metadata.region": { $in: [this.filter.selectedRegions[0].value] },
      //     },
      //   ];
      // }

      if (
        this.selectedNeighborhoods &&
        this.selectedNeighborhoods.length !== 0
      ) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.neighborhoods": { $in: this.selectedNeighborhoods } },
        ];
      }

      if (this.selectedPriceValue !== 0) {
        // console.log("selectedPriceValue: ", this.selectedPriceValue);

        const price = this.selectedPriceValue;
        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.gift_card_minimum": { $gte: price } },
        ];
      }

      if (this.giftCardAmount) {
        // console.log("gift card amount: ", this.giftCardAmount);

        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.gift_card_minimum": { $lte: this.giftCardAmount } },
        ];
      }

      const selection = "-name -description -categories -address -metadata";
      const queryParameters = {
        limit: 0,
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.searchCount = response.data.result.count;
        const count = response.data.result.count;
        this.filter.count = count;
        this.filterCountIsLoading = false;
      } catch (error) {
        this.filterCountIsLoading = false;
        console.log("vendors did not load", error);
      }
    },

    handleSelectionView() {
      this.isViewingSelection = !this.isViewingSelection;
      this.$emit("toggleSelectionView", this.isViewingSelection);
    },

    /** modals */
    toggleFilterModal() {
      if (this.selectedNeighborhoods.length > 0) {
        this.selectedNeighborhoods = [];
      }
      this.clearFilterSelection();
      // this.loadFilterCount();
      this.resetVisibleNeighborhoods();
      this.showFilterModal = !this.showFilterModal;
    },
    toggleRegionModal() {
      this.showRegionModal = !this.showRegionModal;
    },
    closeRegionModal() {
      this.showRegionModal = false;
      this.$emit("handleTriggerRegionSelection");
    },
    routeUserHome() {
      if (this.isBoldXchangeAdmin || this.isGiftingLead) {
        this.$router.push("/dashboard");
      } else {
        this.$router.push("/gifts");
      }
    },
    /** custom selection */
    toggleGiftCardSelection(vendor) {
      const existingVendorIndex = this.selectedGiftCards.findIndex(
        (v) => v.id === vendor.id
      );

      if (existingVendorIndex !== -1) {
        // If the vendor is already selected, deselect it
        this.selectedGiftCards.splice(existingVendorIndex, 1);
      } else {
        // If the vendor is not selected, select it
        this.selectedGiftCards.push(vendor);
      }

      const selectedVendorIds = this.selectedGiftCards.map(
        (vendor) => vendor._id
      );
      this.$emit("updateSelectedGiftCards", selectedVendorIds);
    },

    selectAllVisible() {
      const newSelection = new Set(
        this.selectedGiftCards.map((vendor) => vendor._id)
      );
      this.vendors.forEach((vendor) => {
        if (!newSelection.has(vendor._id)) {
          this.selectedGiftCards.push(vendor);
          newSelection.add(vendor._id);
        }
      });
      // Vue 3 should react to these changes efficiently.
      this.$emit("updateSelectedGiftCards", Array.from(newSelection));
    },
    deselectAll() {
      this.selectedGiftCards = [];
      // Optional: Emit an event if you need to notify parent components
      this.$emit("updateSelectedGiftCards", this.selectedGiftCards);
    },

    /** handle search query */
    handleSearchQuery() {
      this.searchQuery.active = true;
      this.clearFilters();
      this.getVendorsBySearchQuery();
      this.setSelectedRegion();
      // this.trackFilter();
    },

    async upsertUser(regions) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      const memberToUpdate = {
        "metadata.homeRegions": regions,
        "metadata.campaign_settings.homeRegions": regions,
      };

      try {
        await api.upsertUser(null, {
          filter: { _id: this.memberId },
          patch: memberToUpdate,
        });
      } catch (error) {
        console.log("user was NOT edited", error);
      }
    },

    async handleFilteringVendors(value) {
      await this.$store.dispatch("auth/refreshUser");

      if (value) {
        this.filter.active = value;
        this.showFilterModal = false;
      }
      this.selectedRegions = this.filter.selectedRegions;

      if (
        !this.memberHomeRegions &&
        (this.isBoldXchangeAdmin || this.isGiftingLead)
      ) {
        await this.upsertUser([this.filter.selectedRegions[0].value]);
      }

      this.showRegionModal = false;
      this.clearSearchQuery();
      this.loadFilteredVendors();
      this.setSelectedRegion();
      // this.trackFilter();
    },
    clearSearchQuery() {
      this.searchQuery.value = "";
      this.searchQuery.active = false;
    },
    clearFilterSelection() {
      if (this.selectedNeighborhoods.length > 0) {
        this.selectedNeighborhoods = [];
      }
      this.filter.selectedRegions = [this.selectedRegions[0]];
      this.setNeighborhoodsList();
      this.loadFilterCount();
    },
    resetFilters() {
      this.activeTab = 0;
      this.selectedNeighborhoods = [];
      this.selectedCategoryTab.value = 0;
      this.selectedPriceValue = 0;
      this.priceValue = 0;
      this.currentPage = 0;
      this.filter.selectedRegions = this.selectedRegions;
      this.clearSearchQuery();
      this.loadFilteredVendors();
      this.loadFilterCount();
    },
    clearFilters() {
      this.selectedNeighborhoods = [];
      this.selectedCategoryTab.value = 0;
      this.filter.count = 0;
    },
    setCategoryValue(categoryValue) {
      if (categoryValue !== 0) {
        if (categoryValue === "Casual Dining") {
          this.selectedCategoryTab.name = categoryValue;
          this.selectedCategoryTab.value = ["Casual Dining", "Fast Casual"];
        }
        if (categoryValue === "Coffee & Cafes") {
          this.selectedCategoryTab.name = categoryValue;
          this.selectedCategoryTab.value = ["Coffee Shop", "Coffee", "Cafe"];
        }
        if (categoryValue === "Fine Dining") {
          this.selectedCategoryTab.name = categoryValue;
          this.selectedCategoryTab.value = [categoryValue];
        }
        if (categoryValue === "Ice Cream & Desserts") {
          this.selectedCategoryTab.name = categoryValue;
          this.selectedCategoryTab.value = ["Ice Cream", "Desserts", "Bakery"];
        }
        if (categoryValue === "Wine & Spirits") {
          this.selectedCategoryTab.name = categoryValue;
          this.selectedCategoryTab.value = [categoryValue];
        }
        this.activeTab = categoryValue;
      } else {
        this.activeTab = categoryValue;
        this.selectedCategoryTab = {
          name: "Full collection",
          value: categoryValue,
        };
      }
      this.clearSearchQuery();
      this.handleFilteringVendors();
    },
    toggleSelectedValue(value) {
      if (this.priceValue === value) {
        // Toggle off if the same value is selected
        this.priceValue = 0;
      } else {
        // Toggle on the selected value
        this.priceValue = value;
      }
      this.loadFilterCount();
    },
    showAllNeighborhoods() {
      this.visibleNeighborhoods = [...this.neighborhoodsOptions];
      this.showMoreNeighborhoods = false;
    },
    resetVisibleNeighborhoods() {
      this.visibleNeighborhoods = this.neighborhoodsOptions.slice(0, 10); // Adjust the number as needed
      this.showMoreNeighborhoods = true;
    },

    /** mixpanel */
    trackFilter() {
      this.mixpanel.track("Filters Applied", {
        price: this.selectedPriceValue,
        category: this.selectedCategoryTab.name,
        neighborhoods: this.selectedNeighborhoods,
        search: this.searchQuery.value,
      });
    },

    handleStepChange(step) {
      this.$emit("handleStepChange", step);
    },

    setSelectedRegion() {
      this.$emit("setSelectedRegion", this?.selectedRegions[0]?.value);
    },

    /** navigation */
    createNewCampaign() {
      this.$router.push({ path: "/campaign/new" });
    },
  },
};
</script>

<style scoped>
th,
a {
  cursor: pointer;
}

.dropdown-toggle::after {
  border-top: none;
}

.nav-link {
  padding-left: 0;
  padding-right: 0;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
}

.banner {
  z-index: 99;
}
</style>
