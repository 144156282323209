<template>
  <!-- Static sidebar for desktop -->
  <div
    class="tw-hidden lg:tw-fixed tw-inset-y-0 lg:tw-flex lg:tw-w-52 lg:tw-flex-col tw-z-50"
  >
    <div
      class="tw-flex tw-flex-grow tw-flex-col tw-overflow-y-auto tw-bg-blue-900 tw-pb-4 tw-pt-5"
    >
      <div class="tw-flex tw-flex-shrink-0 tw-items-center tw-px-4">
        <img
          class="tw-h-auto tw-w-full"
          src="../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_White_LG.png"
          alt="Bold Xchange logo"
        />
      </div>
      <nav
        class="tw-mt-5 tw-flex tw-flex-1 tw-flex-col tw-divide-y tw-divide-gray-800 tw-overflow-y-auto"
        aria-label="Sidebar"
      >
        <ul
          role="list"
          class="tw-flex tw-flex-1 tw-flex-col tw-gap-y-7 tw-list-none tw-p-4 tw-m-0"
        >
          <li>
            <ul
              role="list"
              class="tw--mx-2 tw-space-y-1 tw-list-none tw-p-0 tw-m-0"
            >
              <template v-if="isBoldXchangeAdmin">
                <li v-for="item in adminNavRoutes" :key="item.name">
                  <template v-if="!item.children">
                    <router-link
                      :to="item.to"
                      :class="[
                        item.to === currentRoutePath
                          ? 'tw-bg-gray-50 tw-text-gray-900'
                          : 'hover:tw-bg-gray-50 hover:tw-text-gray-900 tw-text-white',
                        'tw-group tw-capitalize tw-flex tw-rounded-md tw-px-2 tw-py-1 tw-text-sm tw-leading-6 tw-font-semibold tw-items-center tw-gap-3',
                      ]"
                    >
                      <component
                        :is="getIconComponent(item.name)"
                        :class="[
                          item.to === currentRoutePath
                            ? 'tw-text-gray-900'
                            : 'group-hover:tw-text-gray-900 tw-text-white',
                          'tw-h-4 tw-w-4 tw-flex-shrink-0',
                        ]"
                        aria-hidden="true"
                      ></component>
                      {{ item.name }}
                    </router-link>
                  </template>
                  <template v-else>
                    <div
                      @click="toggle(item)"
                      :class="[
                        'tw-group tw-capitalize tw-flex tw-rounded-md tw-px-2 tw-py-1 tw-text-sm tw-leading-6 tw-font-semibold tw-items-center tw-gap-3 tw-cursor-pointer',
                        item.to === currentRoutePath
                          ? 'tw-bg-gray-50 tw-text-gray-900'
                          : 'hover:tw-bg-gray-50 hover:tw-text-gray-900 tw-text-white',
                      ]"
                    >
                      <component
                        :is="getIconComponent(item.name)"
                        :class="[
                          item.to === currentRoutePath
                            ? 'tw-text-gray-900'
                            : 'group-hover:tw-text-gray-900 tw-text-white',
                          'tw-h-4 tw-w-4 tw-flex-shrink-0',
                        ]"
                        aria-hidden="true"
                      ></component>
                      {{ item.name }}
                      <b-icon-chevron-down
                        :class="[
                          item.isOpen ? 'tw-rotate-180' : '',
                          'tw-transition-transform tw-duration-200 tw-ml-auto',
                        ]"
                      ></b-icon-chevron-down>
                    </div>
                    <ul
                      v-if="item.isOpen"
                      class="tw-mt-1 tw-space-y-1 tw-px-4 tw-list-none"
                    >
                      <li
                        v-for="subItem in item.children"
                        :key="subItem.name"
                        class="tw-relative tw-flex tw-gap-2"
                      >
                        <div
                          class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-yellow-500"
                        ></div>
                        <router-link
                          :to="subItem.to"
                          :class="[
                            subItem.to === currentRoutePath
                              ? 'tw-bg-gray-50 tw-text-gray-800'
                              : 'hover:tw-bg-gray-50 hover:tw-text-yellow-700 tw-text-white',
                            'tw-block tw-rounded-tl-none tw-rounded-bl-none tw-rounded-md tw-py-1 tw-px-4 tw-text-sm tw-leading-6 tw-w-full',
                          ]"
                        >
                          {{ subItem.name }}
                        </router-link>
                      </li>
                    </ul>
                  </template>
                </li>
              </template>
              <template v-if="!isBoldXchangeAdmin">
                <li v-for="item in giftingLeadNavRoutes" :key="item.name">
                  <template v-if="!item.children">
                    <router-link
                      :to="item.to"
                      :class="[
                        item.to === currentRoutePath
                          ? 'tw-bg-gray-50 tw-text-gray-900'
                          : 'hover:tw-bg-gray-50 hover:tw-text-gray-900 tw-text-white',
                        'tw-group tw-capitalize tw-flex tw-rounded-md tw-px-2 tw-py-1 tw-text-sm tw-leading-6 tw-font-semibold tw-items-center tw-gap-3',
                      ]"
                    >
                      <component
                        :is="getIconComponent(item.name)"
                        :class="[
                          item.to === currentRoutePath
                            ? 'tw-text-gray-900'
                            : 'group-hover:tw-text-gray-900 tw-text-white',
                          'tw-h-4 tw-w-4 tw-flex-shrink-0',
                        ]"
                        aria-hidden="true"
                      ></component>
                      {{ item.name }}
                    </router-link>
                  </template>
                  <template v-else>
                    <div
                      @click="toggle(item)"
                      :class="[
                        'tw-group tw-capitalize tw-flex tw-rounded-md tw-px-2 tw-py-1 tw-text-sm tw-leading-6 tw-font-semibold tw-items-center tw-gap-3 tw-cursor-pointer',
                        item.to === currentRoutePath
                          ? 'tw-bg-gray-50 tw-text-gray-900'
                          : 'hover:tw-bg-gray-50 hover:tw-text-gray-900 tw-text-white',
                      ]"
                    >
                      <component
                        :is="getIconComponent(item.name)"
                        :class="[
                          item.to === currentRoutePath
                            ? 'tw-text-gray-900'
                            : 'group-hover:tw-text-gray-900 tw-text-white',
                          'tw-h-4 tw-w-4 tw-flex-shrink-0',
                        ]"
                        aria-hidden="true"
                      ></component>
                      {{ item.name }}
                      <b-icon-chevron-down
                        :class="[
                          item.isOpen ? 'tw-rotate-180' : '',
                          'tw-transition-transform tw-duration-200 tw-ml-auto',
                        ]"
                      ></b-icon-chevron-down>
                    </div>
                    <ul
                      v-if="item.isOpen"
                      class="tw-mt-1 tw-space-y-1 tw-px-4 tw-list-none"
                    >
                      <li
                        v-for="subItem in item.children"
                        :key="subItem.name"
                        class="tw-relative tw-flex tw-gap-2"
                      >
                        <div
                          class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-yellow-500"
                        ></div>
                        <router-link
                          :to="subItem.to"
                          :class="[
                            subItem.to === currentRoutePath
                              ? 'tw-bg-gray-50 tw-text-gray-800'
                              : 'hover:tw-bg-gray-50 hover:tw-text-yellow-700 tw-text-white',
                            'tw-block tw-rounded-tl-none tw-rounded-bl-none tw-rounded-md tw-py-1 tw-px-4 tw-text-sm tw-leading-6 tw-w-full',
                          ]"
                        >
                          {{ subItem.name }}
                        </router-link>
                      </li>
                    </ul>
                  </template>
                </li>
              </template>
            </ul>
          </li>

          <!-- integrations -->
          <li>
            <div
              class="tw-text-xs tw-font-bold tw-leading-6 tw-text-yellow-200"
            >
              Other
            </div>
            <ul
              role="list"
              class="tw--mx-2 tw-space-y-1 tw-list-none tw-p-0 tw-m-0"
            >
              <li v-for="item in integrationsNavRoutes" :key="item.name">
                <router-link
                  :to="item.to"
                  :class="[
                    item.to === currentRoutePath
                      ? 'tw-bg-gray-50 tw-text-gray-900'
                      : 'hover:tw-bg-gray-50 hover:tw-text-gray-900 tw-text-white',
                    'tw-group tw-capitalize tw-flex tw-rounded-md tw-px-2 tw-py-1 tw-text-sm tw-leading-6 tw-font-semibold tw-items-center tw-gap-3',
                  ]"
                >
                  <component
                    :is="getIconComponent(item.name)"
                    :class="[
                      item.to === currentRoutePath
                        ? 'tw-text-gray-900'
                        : 'group-hover:tw-text-gray-900 tw-text-white',
                      'tw-h-4 tw-w-4 tw-flex-shrink-0',
                    ]"
                    aria-hidden="true"
                  ></component>
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheSidebarV2",
  props: {
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
  },
  data() {
    return {
      adminNavRoutes: [
        { name: "dashboard", to: "/dashboard", current: false },
        { name: "campaigns", to: "/campaigns", current: false },
        // { name: "recipients", to: "/recipients", current: false },
        {
          name: "recipients",
          isOpen: false,
          children: [
            { name: "Groups", to: "/groups", current: false },
            { name: "All profiles", to: "/recipients", current: false },
          ],
        },
        { name: "invoices", to: "/invoices", current: false },
        { name: "budgets", to: "/budgets", current: false },
        { name: "members", to: "/members", current: false },
        { name: "gift cards", to: "/home", current: false },
        { name: "vendors", to: "/vendors", current: false },
      ],
      giftingLeadNavRoutes: [
        { name: "dashboard", to: "/dashboard", current: false },
        { name: "campaigns", to: "/campaigns", current: false },
        // { name: "recipients", to: "/recipients", current: false },
        {
          name: "recipients",
          isOpen: false,
          children: [
          { name: "Groups", to: "/groups", current: false },
          { name: "All profiles", to: "/recipients", current: false },
          ],
        },
        { name: "budgets", to: "/budgets", current: false },
        { name: "gift cards", to: "/home", current: false },
      ],
      integrationsNavRoutes: [
        { name: "templates", to: "/templates", current: false },
        { name: "integrations", to: "/integrations", current: false },
      ],
    };
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
  },
  methods: {
    getIconComponent(name) {
      switch (name) {
        case "dashboard":
          return "b-icon-house";
        case "campaigns":
          return "b-icon-stars";
        case "budgets":
          return "b-icon-coin";
        case "invoices":
          return "b-icon-receipt";
        case "recipients":
          return "b-icon-people";
        case "members":
          return "b-icon-check-square";
        case "gift cards":
          return "b-icon-gift";
        case "vendors":
          return "b-icon-buildings";
        case "templates":
          return "b-icon-layers";
        case "integrations":
          return "b-icon-grid-3x3-gap";
        default:
          return "";
      }
    },
    toggle(item) {
      item.isOpen = !item.isOpen;
    },
  },
};
</script>
