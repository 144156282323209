<template>
  <CategoriesListModal
    v-if="isCategoryListModalVisible"
    @cancel="showCategoryListModal"
    @setNewCategories="handleNewCategories"
  ></CategoriesListModal>
  <CreateCategoryModal
    v-if="isNewCategoryModalVisible"
    @cancel="showCreateCategoryModal"
    @setNewCategory="setExistingCategory"
  ></CreateCategoryModal>
  <TagsListModal
    v-if="isTagsListModalVisible"
    @cancel="showTagsListModal"
    @setNewTag="handleNewTags"
  ></TagsListModal>
  <CreateTagsModal
    v-if="isNewTagModalVisible"
    @cancel="showCreateTagsModal"
    @setNewTag="setExistingTag"
  ></CreateTagsModal>
  <NeighborhoodsListModal
    v-if="isNeighborhoodsListModalVisible"
    @cancel="showNeighborhoodsListModal"
    @setNewNeighborhood="handleNewNeighborhoods"
  ></NeighborhoodsListModal>
  <CreateNeighborhoodsModal
    v-if="isNewNeighborhoodModalVisible"
    @cancel="showCreateNeighborhoodModal"
    @setNewNeighborhood="setExistingNeighborhood"
  ></CreateNeighborhoodsModal>
  <VendorOnlineStorePlatformListModal
    v-if="isVendorOnlineStorePlatformListModalVisible"
    @cancel="showVendorOnlineStoreListModal"
    @setNewVendorOnlineStorePlatform="handleNewOnlineStorePlatform"
  ></VendorOnlineStorePlatformListModal>
  <CreateVendorOnlineStorePlatformModal
    v-if="isNewVendorOnlineStorePlatformModalVisible"
    @cancel="showCreateOnlineStorePlatformModal"
    @setNewVendorOnlineStorePlatform="setExistingOnlineStorePlatform"
  ></CreateVendorOnlineStorePlatformModal>
  <AddressListModal
    v-if="isAddressListModalVisible"
    @cancel="toggleAddressListModal"
    @setNewAddress="setNewAddress"
  ></AddressListModal>
  <CreateAddressModal
    v-if="isCreateAddressModalVisible"
    @cancel="toggleCreateAddressModal"
    @setNewAddress="setNewAddress"
  ></CreateAddressModal>

  <SuccessfulActionModal
    v-if="updateMessage.successful.show"
    :vendorAdded="updateMessage.successful.show"
  >
    <template #button-1>
      <button class="btn btn-primary" @click.stop="viewNewVendor()">
        View new vendor
      </button>
    </template>
    <template #button-2>
      <button class="btn btn-secondary" @click.stop="closeSuccessModal()">
        Close
      </button>
    </template>
  </SuccessfulActionModal>

  <BlockStack :gap="400" class="tw-w-full">
    <div class="mb-3 mb-lg-0">
      <!-- update messages -->
      <div :class="updateMessage.failed.class" v-if="updateMessage.failed.show">
        <p class="mb-0 fw-bold fs-4 text-white">
          {{ updateMessage.failed.text }}
        </p>
      </div>
    </div>

    <div class="tw-flex tw-justify-center">
      <ul class="step step-md step-icon-xs step-centered w-100">
        <!-- #1 general -->
        <li class="step-item">
          <div class="step-content-wrapper">
            <template v-if="step !== 1">
              <a @click="step = 1" class="step-icon step-icon-soft-primary"
                >1</a
              >
            </template>
            <template v-else>
              <span class="step-icon step-icon-primary">1</span>
            </template>

            <div class="step-content">
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 1,
                }"
              >
                General Information
              </span>
            </div>
          </div>
        </li>
        <!-- #2 address -->
        <li class="step-item">
          <div class="step-content-wrapper">
            <template v-if="step !== 2">
              <span
                class="step-icon step-icon-soft-secondary"
                v-if="!checkIfFormIsComplete"
                >2</span
              >
              <a
                class="step-icon step-icon-soft-primary"
                v-if="checkIfFormIsComplete"
                @click="step = 2"
                >2</a
              >
            </template>
            <template v-else>
              <span class="step-icon step-icon-primary">2</span>
            </template>
            <div class="step-content d-flex flex-column">
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 2,
                }"
                >Address
              </span>
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 2,
                }"
                >(optional)
              </span>
            </div>
          </div>
        </li>
        <!-- #3 contact -->
        <li class="step-item">
          <div class="step-content-wrapper">
            <template v-if="step !== 3">
              <span
                class="step-icon step-icon-soft-secondary"
                v-if="!checkIfFormIsComplete"
                >3</span
              >
              <a
                class="step-icon step-icon-soft-primary"
                v-if="checkIfFormIsComplete"
                @click="step = 3"
                >3</a
              >
            </template>
            <template v-else>
              <span class="step-icon step-icon-primary">3</span>
            </template>
            <div class="step-content d-flex flex-column">
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 3,
                }"
                >Contact
              </span>
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 3,
                }"
                >(optional)
              </span>
            </div>
          </div>
        </li>
        <!-- #4 social media -->
        <li class="step-item">
          <div class="step-content-wrapper">
            <template v-if="step !== 4">
              <span
                class="step-icon step-icon-soft-secondary"
                v-if="!checkIfFormIsComplete"
                >4</span
              >
              <a
                class="step-icon step-icon-soft-primary"
                v-if="checkIfFormIsComplete"
                @click="step = 4"
                >4</a
              >
            </template>
            <template v-else>
              <span class="step-icon step-icon-primary">4</span>
            </template>
            <div class="step-content d-flex flex-column">
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 4,
                }"
                >Social Media
              </span>
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 4,
                }"
                >(optional)
              </span>
            </div>
          </div>
        </li>
        <!-- #5 save new member -->
        <li class="step-item">
          <div class="step-content-wrapper">
            <template v-if="step !== 5">
              <span
                class="step-icon step-icon-soft-secondary"
                v-if="!checkIfFormIsComplete"
                >5</span
              >
              <a
                class="step-icon step-icon-soft-primary"
                v-if="checkIfFormIsComplete"
                @click="step = 5"
                >5</a
              >
            </template>
            <template v-else>
              <span class="step-icon step-icon-primary">5</span>
            </template>
            <div class="step-content d-flex flex-column">
              <span
                class="step-title"
                :class="{
                  'text-primary': step === 5,
                }"
                >Review
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <BlockStack :gap="600" class="tw-w-full tw-max-w-5xl">
      <!-- Step 1: General information -->
      <template v-if="step === 1">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">General information</h4>
          </div>
          <div
            class="d-flex justify-content-center align-items-center text-center bg-soft-danger p-3"
            v-if="!checkIfFormIsComplete"
          >
            <p class="text-danger mb-0 fw-bold">
              Please complete<span
                class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-white tw-py-1 tw-px-2 tw-rounded"
                >required</span
              >
              fields.
            </p>
          </div>
          <TheBox :withPadding="true">
            <BlockStack :gap="400" class="">
              <!-- isOnline -->
              <BlockStack :gap="200" class="tw-max-w-3xl">
                <div class="tw-flex tw-items-center tw-gap-2">
                  <label
                    for="vendorStatusSelection"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Status</label
                  >
                  <TheTooltip
                    :toolTip="true"
                    tooltipContent="Only ONLINE vendors are visible on the customer-facing gift card collection."
                  >
                    <template #icon>
                      <div>
                        <b-icon-exclamation-triangle-fill
                          class="tw-z-0 tw-text-orange-500"
                        ></b-icon-exclamation-triangle-fill>
                      </div>
                    </template>
                  </TheTooltip>
                </div>
                <select
                  v-model="selectedVendor.metadata.isOnline"
                  id="vendorStatusSelection"
                  name="vendorStatusSelection"
                  class="tw-w-1/4 tw-block tw-bg-white tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                >
                  <option value="" class="" disabled>Select status</option>
                  <option
                    v-for="option in vendorStatusOptions"
                    :value="option.value"
                    :key="option.index"
                    class="tw-capitalize"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </BlockStack>
              <!-- name -->
              <BlockStack :gap="200" class="tw-max-w-lg">
                <label
                  for="vendorName"
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >Name<span
                    v-if="!getVendorName"
                    class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                    >required</span
                  ></label
                >
                <input
                  v-model="selectedVendor.name"
                  type="text"
                  name="vendorName"
                  id="vendorName"
                  :class="{
                    'tw-ring-red-300': !getVendorName,
                    'tw-ring-green-500': getVendorName,
                  }"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                  placeholder="Bold Xchange"
                />
              </BlockStack>
              <!-- short description -->
              <BlockStack :gap="100" class="">
                <label
                  for="vendorShortDescription"
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >Short description<span
                    v-if="!selectedVendor.metadata.shortDescription"
                    class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                    >required</span
                  ></label
                >
                <textarea
                  rows="1"
                  v-model="selectedVendor.metadata.shortDescription"
                  name="vendorShortDescription"
                  id="vendorShortDescription"
                  placeholder="Vendor short bio"
                  :class="{
                    'tw-ring-red-300':
                      !selectedVendor.metadata.shortDescription,
                    'tw-ring-green-500':
                      selectedVendor.metadata.shortDescription,
                  }"
                  class="tw-block tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                ></textarea>
              </BlockStack>
              <!-- Long Bio -->
              <BlockStack :gap="100" class="">
                <label
                  for="vendorDescription"
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >Long bio<span
                    v-if="!vendorDescription"
                    class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                    >required</span
                  ></label
                >
                <textarea
                  rows="5"
                  v-model="selectedVendor.description"
                  name="vendorDescription"
                  id="vendorDescription"
                  placeholder="Vendor long bio"
                  :class="{
                    'tw-ring-red-300': !vendorDescription,
                    'tw-ring-green-500': vendorDescription,
                  }"
                  class="tw-block tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                ></textarea>
              </BlockStack>
              <!-- gift card page and minimum amount -->
              <div class="tw-flex gap-2 tw-flex-wrap tw-w-full">
                <BlockStack :gap="200" class="">
                  <label
                    for="gift_card_minimum"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Gift card minimum<span
                      v-if="
                        !isGiftCardMinimumValid || !isGiftCardValueTypeValid
                      "
                      class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                      >required</span
                    ></label
                  >
                  <input
                    v-model="selectedVendor.metadata.gift_card_minimum"
                    type="number"
                    name="gift_card_minimum"
                    id="gift_card_minimum"
                    :class="{
                      'tw-ring-red-300':
                        !isGiftCardMinimumValid || !isGiftCardValueTypeValid,
                      'tw-ring-green-500':
                        isGiftCardMinimumValid && isGiftCardValueTypeValid,
                    }"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="$$$"
                  />
                </BlockStack>
                <BlockStack :gap="200" class="">
                  <label
                    for="gift_card_page"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Gift card page<span
                      v-if="!selectedVendor.metadata.gift_card_page"
                      class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                      >required</span
                    ></label
                  >
                  <input
                    v-model="selectedVendor.metadata.gift_card_page"
                    type="text"
                    name="gift_card_page"
                    id="gift_card_page"
                    :class="{
                      'tw-ring-green-500':
                        selectedVendor.metadata.gift_card_page,
                      'tw-ring-red-300':
                        !selectedVendor.metadata.gift_card_page,
                    }"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="www.example.com"
                  />
                </BlockStack>
              </div>
              <!-- neighborhoods + region -->
              <BlockStack :gap="200" class="tw-w-1/2">
                <label
                  for="vendorRegion"
                  class="tw-text-lg tw-leading-6 tw-text-gray-900"
                  >Region<span
                    v-if="!selectedVendor.metadata.region[0]"
                    class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                    >required</span
                  ></label
                >
                <select
                  v-model="selectedVendor.metadata.region"
                  id="vendorRegion"
                  name="vendorRegion"
                  class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-3 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg"
                >
                  <option value="" class="" disabled>Select region</option>
                  <option
                    v-for="option in vendorRegionOptions"
                    :value="[option.value]"
                    :key="option.index"
                    class=""
                  >
                    {{ option.text }}
                  </option>
                </select>
              </BlockStack>
              <!-- categories + platforms -->
              <BlockStack :gap="200" class="">
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="categories"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Categories<span
                      v-if="!vendorCategories || vendorCategories.length === 0"
                      class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                      >required</span
                    >
                  </label>
                  <span v-if="doesCategoryExist" class="text-danger medium ms-2"
                    >Category already exists</span
                  >

                  <span class="tw-isolate tw-inline-flex tw-rounded-md">
                    <button
                      type="button"
                      @click.prevent="showCategoryListModal()"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      <span>Select Category(s)</span>
                      <span><b-icon-chevron-down></b-icon-chevron-down></span>
                    </button>
                    <button
                      type="button"
                      @click.prevent="showCreateCategoryModal()"
                      class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                    >
                      create
                    </button>
                  </span>
                </BlockStack>
                <!-- category pills -->
                <div class="tw-flex tw-flex-wrap tw-justify-start">
                  <template
                    v-if="vendorCategories && vendorCategories.length > 0"
                  >
                    <div
                      v-for="(category, key) in vendorCategories"
                      :key="'item-' + key"
                    >
                      <!-- badge -->
                      <span
                        role="button"
                        class="badge bg-soft-dark rounded-pill me-1"
                        @click.stop="removeCategorySelected(category)"
                      >
                        <b-icon-x-circle class="text-danger"></b-icon-x-circle>
                        <span class="text-body ms-1">{{ category }}</span>
                      </span>
                    </div>
                  </template>
                </div>
              </BlockStack>
              <!-- neighborhoods -->
              <BlockStack :gap="200" class="tw-w-1/2">
                <BlockStack :gap="200" class="">
                  <label
                    for="neighborhoods"
                    class="tw-text-lg tw-leading-6 tw-text-gray-900"
                    >Neighborhoods</label
                  >
                  <span
                    v-if="doesNeighborhoodExist"
                    class="text-danger medium ms-2"
                    >Neighborhood already exists</span
                  >
                  <span class="tw-isolate tw-inline-flex tw-rounded-md">
                    <button
                      type="button"
                      @click.prevent="showNeighborhoodsListModal()"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      <span>Select neighborhood(s)</span>
                      <span><b-icon-chevron-down></b-icon-chevron-down></span>
                    </button>
                    <button
                      type="button"
                      @click.prevent="showCreateNeighborhoodModal()"
                      class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                    >
                      create
                    </button>
                  </span>
                </BlockStack>
                <!-- pills -->
                <div class="tw-flex tw-flex-wrap tw-justify-start">
                  <template
                    v-if="vendorNeighborhoods && vendorNeighborhoods.length > 0"
                  >
                    <div
                      v-for="(neighborhood, key) in vendorNeighborhoods"
                      :key="'item-' + key"
                    >
                      <!-- badge -->
                      <span
                        role="button"
                        class="badge bg-soft-dark rounded-pill me-1"
                        @click.stop="removeNeighborhoodSelected(neighborhood)"
                      >
                        <b-icon-x-circle class="text-danger"></b-icon-x-circle>
                        <span class="text-body ms-1">{{ neighborhood }}</span>
                      </span>
                    </div>
                  </template>
                </div>
              </BlockStack>
              <!-- tags -->
              <BlockStack :gap="200" class="">
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="tags"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Tags</label
                  >
                  <span v-if="doesTagExist" class="text-danger medium ms-2"
                    >Tag already exists</span
                  >
                  <span class="tw-isolate tw-inline-flex tw-rounded-md">
                    <button
                      type="button"
                      @click.prevent="showTagsListModal()"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      <span>Select tag(s)</span>
                      <span><b-icon-chevron-down></b-icon-chevron-down></span>
                    </button>
                    <button
                      type="button"
                      @click.prevent="showCreateTagsModal()"
                      class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                    >
                      create
                    </button>
                  </span>
                </BlockStack>
                <!-- tag pills -->
                <div
                  class="d-flex flex-row flex-wrap justify-content-left mt-1"
                >
                  <template v-if="vendorTags && vendorTags.length > 0">
                    <div v-for="(tag, key) in vendorTags" :key="'item-' + key">
                      <!-- badge -->
                      <span
                        role="button"
                        class="badge bg-soft-dark rounded-pill me-1"
                        @click.stop="removeTagsSelected(tag)"
                      >
                        <b-icon-x-circle class="text-danger"></b-icon-x-circle>
                        <span class="text-body ms-1">{{ tag }}</span>
                      </span>
                    </div>
                  </template>
                </div>
              </BlockStack>
              <!-- gift card platform -->
              <BlockStack :gap="200" class="">
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="online_platforms"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Gift card platfoarm (Square, Toast, etc.)</label
                  >
                  <span
                    v-if="doesOnlineStorePlatformExist"
                    class="text-danger medium ms-2"
                    >Gift card platform already exists</span
                  >
                  <span class="tw-isolate tw-inline-flex tw-rounded-md">
                    <button
                      type="button"
                      @click.prevent="showVendorOnlineStoreListModal()"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      <span>Select tag(s)</span>
                      <span><b-icon-chevron-down></b-icon-chevron-down></span>
                    </button>
                    <button
                      type="button"
                      @click.prevent="showCreateOnlineStorePlatformModal()"
                      class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                    >
                      create
                    </button>
                  </span>
                </BlockStack>
                <!-- tag pills -->
                <div
                  class="d-flex flex-row flex-wrap justify-content-left mt-1"
                >
                  <template
                    v-if="
                      vendorOnlineStorePlatform &&
                      vendorOnlineStorePlatform.length > 0
                    "
                  >
                    <div
                      v-for="(platform, key) in vendorOnlineStorePlatform"
                      :key="'item-' + key"
                    >
                      <!-- badge -->
                      <span
                        role="button"
                        class="badge bg-soft-dark rounded-pill me-1"
                        @click.stop="
                          removeOnlineStorePlatformSelected(platform)
                        "
                      >
                        <b-icon-x-circle class="text-danger"></b-icon-x-circle>
                        <span class="text-body ms-1">{{ platform }}</span>
                      </span>
                    </div>
                  </template>
                </div>
              </BlockStack>
            </BlockStack>
          </TheBox>
        </div>
      </template>
      <!-- Step 2: Address -->
      <template v-if="checkIfFormIsComplete && step === 2">
        <div class="card mb-3 mb-lg-5">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">Address (optional)</h4>
          </div>
          <div class="card-body d-flex flex-column gap-3">
            <div class="row">
              <div class="col col-md-8 text-start">
                <!-- Categories -->
                <div class="mb-2">
                  <label for="clientName" class="form-label fw-bold"
                    >Select/create address</label
                  ><span></span>
                  <div class="input-group">
                    <button
                      class="form-select d-flex"
                      @click.prevent="toggleAddressListModal()"
                    >
                      <span>Select address</span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning fw-bold"
                      @click.prevent="toggleCreateAddressModal()"
                    >
                      create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="vendorAddressId">
            <div class="card-body d-flex flex-column gap-3">
              <div class="row">
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="addressLine1"
                    >Address line 1</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    :disabled="!addressIsBeingEdited"
                    name="addressLine1"
                    id="addressLine1"
                    required
                    placeholder="Street address, P.O box"
                    aria-label="Street address, P.O box"
                    v-model="selectedVendor.address.lines[0]"
                  />
                </div>
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="addressLine2"
                    >Address line 2</label
                  >
                  <input
                    type="url"
                    class="form-control form-control-lg"
                    :disabled="!addressIsBeingEdited"
                    name="addressLine2"
                    id="addressLine2"
                    required
                    placeholder="Apt, suite, unit, etc."
                    aria-label="Apt, suite, unit, etc."
                    v-model="selectedVendor.address.lines[1]"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3 text-start">
                  <label class="form-label fw-bold" for="city">City</label>
                  <span
                    v-if="!selectedVendor.address.city"
                    class="badge bg-soft-info rounded-pill ms-2"
                    ><span class="text-danger">required</span>
                  </span>
                  <input
                    type="url"
                    class="form-control form-control-lg"
                    :disabled="!addressIsBeingEdited"
                    name="city"
                    id="city"
                    required
                    placeholder="St. Louis"
                    aria-label="St. Louis"
                    v-model="selectedVendor.address.city"
                  />
                </div>
                <div class="col-12 col-lg-3 text-start">
                  <label class="form-label fw-bold" for="state">State</label>
                  <span
                    v-if="!selectedVendor.address.state"
                    class="badge bg-soft-info rounded-pill ms-2"
                    ><span class="text-danger">required</span>
                  </span>
                  <input
                    type="url"
                    class="form-control form-control-lg"
                    :disabled="!addressIsBeingEdited"
                    name="state"
                    id="state"
                    required
                    placeholder="Missouri"
                    aria-label="Missouri"
                    v-model="selectedVendor.address.state"
                  />
                </div>
                <div class="col-12 col-lg-3 text-start">
                  <label class="form-label fw-bold" for="postalCode"
                    >Postal Code</label
                  >
                  <input
                    type="url"
                    class="form-control form-control-lg"
                    :disabled="!addressIsBeingEdited"
                    name="postalCode"
                    id="postalCode"
                    required
                    placeholder="63139"
                    aria-label="63139"
                    v-model="selectedVendor.address.postalCode"
                  />
                </div>
                <div class="col-12 col-lg-3 text-start">
                  <div class="tom-select-custom">
                    <label class="form-label fw-bold" for="countrySelect"
                      >Country</label
                    >
                    <input
                      :readonly="true"
                      type="url"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6"
                      name="country"
                      id="country"
                      required
                      placeholder="country"
                      v-model="selectedVendor.address.country"
                    />
                  </div>
                </div>
              </div>
              <template v-if="!this.addressIsBeingEdited">
                <div class="d-flex justify-content-end gap-2">
                  <button
                    class="btn btn-outline-primary fw-bold"
                    @click.prevent="editAddress()"
                  >
                    <b-icon-pencil class="me-2"></b-icon-pencil>edit
                  </button>
                </div>
              </template>
              <template v-if="this.addressIsBeingEdited">
                <div class="d-flex justify-content-end gap-2">
                  <button
                    class="btn btn-lg btn-soft-dark"
                    @click.prevent="editAddress()"
                  >
                    cancel
                  </button>
                  <button
                    class="btn btn-lg btn-primary"
                    type="button"
                    disabled
                    v-if="addressIsSaving"
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Saving changes...
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="updateAddress()"
                  >
                    Save changes
                  </button>
                </div>
              </template>

              <!-- postal code/country -->
            </div>
          </template>
        </div>
      </template>
      <!-- Step 3: Contact -->
      <template v-if="checkIfFormIsComplete && step === 3">
        <div class="card mb-3 mb-lg-5">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">Contact (optional)</h4>
          </div>
          <div class="card-body d-flex flex-column gap-3">
            <div class="row">
              <div class="col-lg-6 text-start">
                <div class="mb-4">
                  <label for="contactName" class="form-label fw-bold"
                    >Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="contactName"
                    id="contactName"
                    placeholder="Joe Kay"
                    aria-label="Joe Kay"
                    v-model="selectedVendor.contact.name"
                  />
                </div>
              </div>
              <div class="col-lg-6 text-start">
                <div class="mb-4">
                  <label for="contactTitle" class="form-label fw-bold"
                    >Title</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="contactTitle"
                    id="contactTitle"
                    placeholder="Chief of Awesomeness"
                    aria-label="Chief of Awesomeness"
                    v-model="selectedVendor.contact.title"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body d-flex flex-column gap-3">
            <div class="row">
              <div class="col-lg-6 text-start">
                <div class="mb-4">
                  <label for="contactEmail" class="form-label fw-bold"
                    >Email</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="contactEmail"
                    id="contactEmail"
                    placeholder="contactemail@company.com"
                    aria-label="contactemail@company.com"
                    v-model="selectedVendor.contact.email"
                  />
                </div>
              </div>
              <div class="col-lg-6 text-start">
                <div class="mb-4">
                  <label for="contactPhone" class="form-label fw-bold"
                    >Phone</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="contactPhone"
                    id="contactPhone"
                    placeholder="111-111-1111"
                    aria-label="111-111-1111"
                    v-model="selectedVendor.contact.phone"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- Step 4: Social Media -->
      <template v-if="checkIfFormIsComplete && step === 4">
        <div class="card mb-3 mb-lg-5">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">
              Social Media (optional)
            </h4>
          </div>

          <TheBox :withPadding="true">
            <BlockStack :gap="600" class="tw-w-full">
              <div class="tw-flex gap-2 tw-w-full">
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="companyWebsite"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    ><b-icon-globe class="tw-mr-1"></b-icon-globe>Website</label
                  >
                  <input
                    type="text"
                    name="companyWebsite"
                    id="companyWebsite"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="Website URL"
                    aria-label="Website URL"
                    v-model="selectedVendor.metadata.website_url"
                  />
                </BlockStack>
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="linkedinProfile"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    ><b-icon-linkedin class="tw-mr-1"></b-icon-linkedin
                    >LinkedIn</label
                  >
                  <input
                    type="text"
                    name="linkedinProfile"
                    id="linkedinProfile"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="LinkedIn URL"
                    aria-label="LinkedIn URL"
                    v-model="selectedVendor.metadata.linkedin_url"
                  />
                </BlockStack>
              </div>
              <div class="tw-flex gap-2 tw-w-full">
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="twitterProfile"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    ><b-icon-twitter class="tw-mr-1"></b-icon-twitter
                    >Twitter</label
                  >
                  <input
                    type="text"
                    name="twitterProfile"
                    id="twitterProfile"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="Twitter URL"
                    aria-label="Twitter URL"
                    v-model="selectedVendor.metadata.twitter_url"
                  />
                </BlockStack>
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="tiktokProfile"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    ><b-icon-tiktok class="tw-mr-1"></b-icon-tiktok
                    >TikTok</label
                  >
                  <input
                    type="text"
                    name="tiktokProfile"
                    id="tiktokProfile"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="TikTok URL"
                    aria-label="TikTok URL"
                    v-model="selectedVendor.metadata.tiktok_url"
                  />
                </BlockStack>
              </div>
              <div class="tw-flex gap-2 tw-w-full">
                <BlockStack :gap="200" class="tw-w-1/2">
                  <label
                    for="instagramProfile"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    ><b-icon-instagram class="tw-mr-1"></b-icon-instagram
                    >Instagram</label
                  >
                  <input
                    type="text"
                    name="instagramProfile"
                    id="instagramProfile"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="Instagram URL"
                    aria-label="Instagram URL"
                    v-model="selectedVendor.metadata.instagram_url"
                  />
                </BlockStack>
              </div>
            </BlockStack>
            <!--  -->
          </TheBox>
        </div>
      </template>
      <!-- Step 5: Review -->
      <template v-if="checkIfFormIsComplete && step === 5">
        <!-- general information -->
        <div class="card mb-3 mb-lg-5">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">General information</h4>
          </div>
          <div class="card-body d-flex flex-column gap-3">
            <div class="row">
              <div class="col-8 text-start">
                <label for="vendorName" class="form-label fw-bold"
                  >Vendor Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="vendorName"
                  id="vendorName"
                  placeholder="Bold Xchange"
                  aria-label="Bold Xchange"
                  v-model="selectedVendor.name"
                  disabled
                />
              </div>
              <div class="col-4 text-start">
                <label for="gift_card_minimum" class="form-label fw-bold"
                  >Gift card minimum</label
                >
                <input
                  type="number"
                  class="form-control"
                  name="gift_card_minimum"
                  id="gift_card_minimum"
                  v-model="selectedVendor.metadata.gift_card_minimum"
                  disabled
                />
              </div>
            </div>
            <!-- Short Description -->
            <div class="row">
              <div class="col text-start">
                <label for="shortDescription" class="form-label fw-bold"
                  >Short description</label
                >
                <textarea
                  type="text"
                  class="form-control"
                  name="shortDescription"
                  id="shortDescription"
                  rows="1"
                  v-model="selectedVendor.metadata.shortDescription"
                  disabled
                ></textarea>
              </div>
            </div>
            <!-- Long Bio -->
            <div class="row">
              <div class="col text-start">
                <label for="vendorDescription" class="form-label fw-bold"
                  >Bio</label
                >
                <textarea
                  type="text"
                  class="form-control"
                  name="vendorDescription"
                  id="vendorDescription"
                  rows="5"
                  placeholder="Brand partner bio"
                  aria-label="Brand partner bio"
                  v-model="selectedVendor.description"
                  disabled
                ></textarea>
              </div>
            </div>
            <!-- categories + platforms -->
            <div class="row gy-3">
              <!-- categories -->
              <div class="col-12 text-start">
                <label for="categories" class="form-label fw-bold"
                  >Categories</label
                >

                <!-- category pills -->
                <template
                  v-if="vendorCategories && vendorCategories.length > 0"
                >
                  <div class="d-flex flex-wrap gap-1">
                    <span
                      class="badge bg-bold-xchange-blue"
                      v-for="(category, key) in vendorCategories"
                      :key="'item-' + key"
                    >
                      <span
                        class="fw-normal text-white d-flex align-items-center"
                        >{{ category }}</span
                      >
                    </span>
                  </div>
                </template>
              </div>
              <!-- tags -->
              <div class="col-12 text-start">
                <label for="tags" class="form-label fw-bold">Tags</label>

                <!-- tags pills -->
                <template v-if="vendorTags && vendorTags.length > 0">
                  <div class="d-flex flex-wrap gap-1">
                    <span
                      class="badge bg-bold-xchange-blue"
                      v-for="(tag, key) in vendorTags"
                      :key="'item-' + key"
                    >
                      <span
                        class="fw-normal text-white d-flex align-items-center"
                        >{{ tag }}</span
                      >
                    </span>
                  </div>
                </template>
                <template v-else>
                  <p class="mb-0">No tags selected.</p>
                </template>
              </div>
              <!-- gift card platforms -->
              <div class="col-12 text-start">
                <label for="onlinePlatforms" class="form-label fw-bold"
                  >Gift card platforms</label
                >

                <!-- gift card platforms pills -->
                <template
                  v-if="
                    vendorOnlineStorePlatform &&
                    vendorOnlineStorePlatform.length > 0
                  "
                >
                  <div class="d-flex flex-wrap gap-1">
                    <span
                      class="badge bg-bold-xchange-blue"
                      v-for="(platform, key) in vendorOnlineStorePlatform"
                      :key="'item-' + key"
                    >
                      <span
                        class="fw-normal text-white d-flex align-items-center"
                        >{{ platform }}</span
                      >
                    </span>
                  </div>
                </template>
                <template v-else>
                  <p class="mb-0">No gift card platforms selected.</p>
                </template>
              </div>
              <!-- neighborhoods -->
              <div class="col-12 text-start">
                <label for="onlinePlatforms" class="form-label fw-bold"
                  >Neighborhoods</label
                >

                <!-- neighborhoods pills -->
                <template
                  v-if="vendorNeighborhoods && vendorNeighborhoods.length > 0"
                >
                  <div class="d-flex flex-wrap gap-1">
                    <span
                      class="badge bg-bold-xchange-blue"
                      v-for="(neighborhood, key) in vendorNeighborhoods"
                      :key="'item-' + key"
                    >
                      <span
                        class="fw-normal text-white d-flex align-items-center"
                        >{{ neighborhood }}</span
                      >
                    </span>
                  </div>
                </template>
                <template v-else>
                  <p class="mb-0">No neighborhoods selected.</p>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- address -->
        <div class="card mb-3 mb-lg-5">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">Address (optional)</h4>
          </div>
          <template v-if="vendorAddressId">
            <div class="card-body d-flex flex-column gap-3">
              <div class="row">
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="addressLine1"
                    >Address line 1</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="addressLine1"
                    id="addressLine1"
                    v-model="selectedVendor.address.lines[0]"
                    disabled
                  />
                </div>
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="addressLine2"
                    >Address line 2</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="addressLine2"
                    id="addressLine2"
                    v-model="selectedVendor.address.lines[1]"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3 text-start">
                  <label class="form-label fw-bold" for="city">City</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="city"
                    id="city"
                    placeholder="St. Louis"
                    aria-label="St. Louis"
                    v-model="selectedVendor.address.city"
                    disabled
                  />
                </div>
                <div class="col-12 col-lg-3 text-start">
                  <label class="form-label fw-bold" for="state">State</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="state"
                    id="state"
                    placeholder="Missouri"
                    aria-label="Missouri"
                    v-model="selectedVendor.address.state"
                    disabled
                  />
                </div>
                <div class="col-12 col-lg-3 text-start">
                  <label class="form-label fw-bold" for="postalCode"
                    >Postal Code</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="postalCode"
                    id="postalCode"
                    v-model="selectedVendor.address.postalCode"
                    disabled
                  />
                </div>
                <div class="col-12 col-lg-3 text-start">
                  <div class="tom-select-custom">
                    <label class="form-label fw-bold" for="countrySelect"
                      >Country</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      name="postalCode"
                      id="postalCode"
                      v-model="selectedVendor.address.country"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <!-- postal code/country -->
            </div>
          </template>
          <template v-else>
            <div class="card-body">
              <p class="mb-0">No address selected.</p>
            </div>
          </template>
        </div>
        <!-- contact -->
        <div class="card mb-3 mb-lg-5">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">Contact (optional)</h4>
          </div>
          <template v-if="contactInfoCompleted">
            <div class="card-body d-flex flex-column gap-3">
              <div class="row">
                <div class="col-lg-6 text-start">
                  <div class="mb-4">
                    <label for="contactName" class="form-label fw-bold"
                      >Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="contactName"
                      id="contactName"
                      v-model="selectedVendor.contact.name"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start">
                  <div class="mb-4">
                    <label for="contactTitle" class="form-label fw-bold"
                      >Title</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="contactTitle"
                      id="contactTitle"
                      v-model="selectedVendor.contact.title"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body d-flex flex-column gap-3">
              <div class="row">
                <div class="col-lg-6 text-start">
                  <div class="mb-4">
                    <label for="contactEmail" class="form-label fw-bold"
                      >Email</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="contactEmail"
                      id="contactEmail"
                      v-model="selectedVendor.contact.email"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start">
                  <div class="mb-4">
                    <label for="contactPhone" class="form-label fw-bold"
                      >Phone</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="contactPhone"
                      id="contactPhone"
                      v-model="selectedVendor.contact.phone"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="card-body">
              <p class="mb-0">No contact information completed.</p>
            </div>
          </template>
        </div>
        <!-- social media -->
        <div class="card mb-3 mb-lg-5">
          <div class="card-header bg-dark">
            <h4 class="card-header-title text-white">
              Social Media (optional)
            </h4>
          </div>
          <template v-if="socialMediaInfoCompleted">
            <div class="card-body d-flex flex-column gap-3">
              <div class="row">
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="companyWebsite"
                    >Website
                  </label>
                  <div class="d-flex align-items-center gap-1">
                    <input
                      type="url"
                      class="form-control form-control-lg"
                      name="companyWebsite"
                      id="companyWebsite"
                      title="Go to the company website and copy the URL from your browser's address bar."
                      v-model="selectedVendor.metadata.website_url"
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="gift_card_page"
                    >Gift Card Page
                  </label>
                  <div class="d-flex align-items-center gap-1">
                    <input
                      type="url"
                      class="form-control form-control-lg"
                      name="gift_card_page"
                      id="gift_card_page"
                      title="URL of company gift card purchase"
                      v-model="selectedVendor.metadata.gift_card_page"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="twitterProfile"
                    >Twitter
                  </label>
                  <div class="d-flex align-items-center gap-1">
                    <input
                      type="url"
                      class="form-control form-control-lg"
                      name="twitterProfile"
                      id="twitterProfile"
                      title="Go to the company Twitter profile and copy the URL from your browser's address bar."
                      v-model="selectedVendor.metadata.twitter_url"
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="instagramProfile"
                    >Instagram
                  </label>
                  <div class="d-flex align-items-center gap-1">
                    <input
                      type="url"
                      class="form-control form-control-lg"
                      name="instagramProfile"
                      id="instagramProfile"
                      title="Go to the company Instagram profile and copy the URL from your browser's address bar."
                      v-model="selectedVendor.metadata.instagram_url"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="linkedinProfile"
                    >LinkedIn
                  </label>
                  <div class="d-flex align-items-center gap-1">
                    <input
                      type="url"
                      class="form-control form-control-lg"
                      name="linkedinProfile"
                      id="linkedinProfile"
                      title="Go to the company LinkedIn profile and copy the URL from your browser's address bar."
                      v-model="selectedVendor.metadata.linkedin_url"
                    />
                  </div>
                </div>
                <div class="col-lg-6 text-start">
                  <label class="form-label fw-bold" for="tiktokProfile"
                    >TikTok
                  </label>
                  <div class="d-flex align-items-center gap-1">
                    <input
                      type="url"
                      class="form-control form-control-lg"
                      name="tiktokProfile"
                      id="tiktokProfile"
                      title="Go to the company TikTok profile and copy the URL from your browser's address bar."
                      v-model="selectedVendor.metadata.tiktok_url"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="card-body">
              <p class="mb-0">No social media information completed.</p>
            </div>
          </template>
        </div>
      </template>
      <!-- Navigation -->
      <div
        class="d-flex justify-content-end border-top-0"
        :class="{ 'justify-content-between': step > 1 }"
      >
        <template v-if="checkIfFormIsComplete">
          <template v-if="step > 1">
            <button class="btn btn-ghost-secondary" @click="moveToPreviousStep">
              <b-icon-chevron-left class="me-2"></b-icon-chevron-left>Previous
              Step
            </button>
          </template>
          <template v-if="step >= 1 && step < 5">
            <button class="btn btn-primary" @click="moveToNextStep">
              Next<b-icon-chevron-right class="ms-2"></b-icon-chevron-right>
            </button>
          </template>
          <template v-if="step === 5">
            <div class="d-flex gap-2">
              <button class="btn btn-light" @click="cancelNewVendor()">
                Cancel
              </button>
              <button
                class="btn btn-primary"
                @click="handleCreatingVendor()"
                :disabled="!checkIfFormIsComplete"
              >
                Save new vendor
              </button>
            </div>
          </template>
        </template>
      </div>
    </BlockStack>
  </BlockStack>
</template>

<script>
import SuccessfulActionModal from "../../components/ui/modals/success/SuccessfulActionModal.vue";
import CategoriesListModal from "../../components/ui/modals/lists/CategoriesListModal.vue";
import CreateCategoryModal from "../../components/ui/modals/create/CreateCategoryModal.vue";
import CreateAddressModal from "../../components/ui/modals/create/CreateAddressModal.vue";
import AddressListModal from "../../components/ui/modals/lists/AddressListModal.vue";
import VendorOnlineStorePlatformListModal from "../../components/ui/modals/lists/VendorOnlineStorePlatformListModal.vue";
import CreateVendorOnlineStorePlatformModal from "../../components/ui/modals/create/CreateVendorOnlineStorePlatformModal.vue";
import TagsListModal from "../../components/ui/modals/lists/TagsListModal.vue";
import CreateTagsModal from "../../components/ui/modals/create/CreateTagsModal.vue";
import NeighborhoodsListModal from "../../components/ui/modals/lists/NeighborhoodsListModal.vue";
import CreateNeighborhoodsModal from "../../components/ui/modals/create/CreateNeighborhoodsModal.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";

export default {
  name: "AddVendorForm",
  components: {
    TheTooltip,
    TheBox,
    TheCard,
    BlockStack,
    SuccessfulActionModal,
    CategoriesListModal,
    CreateCategoryModal,
    CreateAddressModal,
    AddressListModal,
    VendorOnlineStorePlatformListModal,
    CreateVendorOnlineStorePlatformModal,
    TagsListModal,
    CreateTagsModal,
    NeighborhoodsListModal,
    CreateNeighborhoodsModal,
  },
  data() {
    return {
      selectedVendor: {
        name: "",
        description: "",
        categories: [],
        contact: {},
        address: {}, // Set default empty object for address
        metadata: {
          shortDescription: "",
          website_url: "",
          twitter_url: "",
          linkedin_url: "",
          tiktok_url: "",
          instagram_url: "",
          gift_card_page: "",
          gift_card_minimum: 0,
          tags: [],
          onlineStorePlatforms: [],
          neighborhoods: [],
          isOnline: false,
          region: "",
        },
        newVendorLogoFiles: [],
        newGiftCardsFiles: [],
        newBrandingFiles: [],
      },
      updateMessage: {
        successful: {
          // text: "Proposal(s) successfully updated!",
          class: "card-header bg-success",
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to add your new vendor. Please try again.",
          class: "p-3 bg-danger text-white",
          show: false,
        },
      },
      vendorStatusOptions: [
        { text: "Offline", value: false },
        { text: "Online", value: true },
      ],
      vendorRegionOptions: [
        { text: "Baltimore", value: "Baltimore" },
        { text: "Birmingham", value: "Birmingham" },
        { text: "Charlotte", value: "Charlotte" },
        { text: "Columbia", value: "Columbia" },

        { text: "Ft. Lauderdale", value: "Ft. Lauderdale" },
        { text: "Kansas City", value: "Kansas City" },
        { text: "Louisville", value: "Louisville" },
        { text: "St. Louis", value: "St. Louis" },
      ],
      newVendorId: null,
      step: 1,
      debounceTimer: null,
      isLoading: false,
      isSuccessful: false,
      isConfirmDeleteModalVisible: false,
      isCategoryListModalVisible: false,
      isNewCategoryModalVisible: false,
      isAddressListModalVisible: false,
      isCreateAddressModalVisible: false,
      isVendorOnlineStorePlatformListModalVisible: false,
      isNewVendorOnlineStorePlatformModalVisible: false,
      isNewTagModalVisible: false,
      isTagsListModalVisible: false,
      isNewNeighborhoodModalVisible: false,
      isNeighborhoodsListModalVisible: false,
      doesCategoryExist: false,
      doesTagExist: false,
      doesOnlineStorePlatformExist: false,
      doesNeighborhoodExist: false,
      addressIsBeingEdited: false,
      addressIsSaving: false,
      newGiftCardsFiles: [],
      newVendorLogoFiles: [],
      newBrandingFiles: [],
    };
  },
  watch: {},
  computed: {
    checkIfFormIsComplete() {
      if (
        !this.getVendorName ||
        !this.vendorCategories ||
        !this.vendorDescription ||
        !this.getRegion
      ) {
        return false;
      }
      return true;
    },
    contactInfoCompleted() {
      return (
        this.selectedVendor.contact.name ||
        this.selectedVendor.contact.title ||
        this.selectedVendor.contact.email ||
        this.selectedVendor.contact.phone
      );
    },
    socialMediaInfoCompleted() {
      return (
        this.selectedVendor.metadata.instagram_url ||
        this.selectedVendor.metadata.linkedin_url ||
        this.selectedVendor.metadata.twitter_url ||
        this.selectedVendor.metadata.tiktok_url ||
        this.selectedVendor.metadata.gift_card_page ||
        this.selectedVendor.metadata.website_url
      );
    },
    vendorAddressId() {
      return this?.selectedVendor?.address?._id;
    },
    getVendorName() {
      return this.selectedVendor.name;
    },
    vendorDescription() {
      return this?.selectedVendor?.description;
    },
    vendorCategories() {
      return this?.selectedVendor?.categories?.length === 0
        ? false
        : this?.selectedVendor?.categories;
    },
    getTypeformUrl() {
      return this.selectedVendor.metadata.typeform_url;
    },
    getRegion() {
      return this.selectedVendor.metadata.region;
    },
    vendorTags() {
      return this?.selectedVendor?.metadata?.tags?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.tags;
    },
    vendorOnlineStorePlatform() {
      return this?.selectedVendor?.metadata?.onlineStorePlatforms?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.onlineStorePlatforms;
    },
    vendorNeighborhoods() {
      return this?.selectedVendor?.metadata?.neighborhoods?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.neighborhoods;
    },
    isGiftCardMinimumValid() {
      const minValue = 1;
      return (
        parseFloat(this.selectedVendor.metadata.gift_card_minimum) >= minValue
      );
    },
    isGiftCardValueTypeValid() {
      return typeof this.selectedVendor.metadata.gift_card_minimum == "number";
    },
  },
  methods: {
    /** handle creating vendor */
    async createVendor(vendors) {
      try {
        const api = await this.getBoldClient();
        const response = await api.createVendors(null, vendors);
        // console.log("vendor response", response);
        // const vendorIds = response.data.result.records.map(
        //   (vendor) => vendor._id
        // );
        // console.log("vendorIds", vendorIds);

        return response;
      } catch (error) {
        // Handle error
        console.log("Error creating vendor:", error);
      }
    },
    async handleCreatingVendor() {
      this.isSaving = true;
      try {
        const vendorsToAdd = [];

        if (this.vendorAddressId) {
          this.selectedVendor.address = this.selectedVendor.address._id;
        } else {
          delete this.selectedVendor.address;
        }

        vendorsToAdd.push(this.selectedVendor);
        // console.log("vendorsToAdd", vendorsToAdd);

        const response = await this.createVendor(vendorsToAdd);
        console.log("Vendors created successfully");
        this.newVendorId = response.data.result.records[0]._id;

        this.handleSuccess();
      } catch (error) {
        console.error("Error creating vendors and addresses:", error);
        this.handleError(error);
      }
    },

    /** categories actions */
    showCreateCategoryModal() {
      this.isNewCategoryModalVisible = !this.isNewCategoryModalVisible;
    },
    showCategoryListModal() {
      this.isCategoryListModalVisible = !this.isCategoryListModalVisible;
    },
    handleNewCategories(newCategories) {
      for (const category of newCategories) {
        this.setExistingCategory(category);
      }
      this.isCategoryListModalVisible = false;
    },
    setExistingCategory(category) {
      const existingCategory = this.selectedVendor.categories.find(
        (cat) => cat === category
      );

      if (!existingCategory) {
        this.selectedVendor.categories.push(category);
        this.doesCategoryExist = false;
      } else {
        this.doesCategoryExist = true;
        setTimeout(() => {
          this.doesCategoryExist = false;
        }, 2000);
      }
      this.isNewCategoryModalVisible = false;
    },
    removeCategorySelected(category) {
      let array = this.selectedVendor.categories;
      let index = array.indexOf(category);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },

    /** tags actions */
    showCreateTagsModal() {
      this.isNewTagModalVisible = !this.isNewTagModalVisible;
    },
    showTagsListModal() {
      this.isTagsListModalVisible = !this.isTagsListModalVisible;
    },
    handleNewTags(newTags) {
      // Handle the array of selected categories
      for (const tag of newTags) {
        this.setExistingTag(tag);
      }
      this.isNewTagModalVisible = false;
    },
    setExistingTag(tag) {
      const existingStore = this.selectedVendor.metadata.tags.find(
        (s) => s === tag
      );

      if (!existingStore) {
        this.selectedVendor.metadata.tags.push(tag);
        this.doesTagExist = false;
      } else {
        this.doesTagExist = true;
        setTimeout(() => {
          this.doesTagExist = false;
        }, 2000);
      }
      this.isNewTagModalVisible = false;
      this.isTagsListModalVisible = false;
    },
    removeTagsSelected(tag) {
      let array = this.selectedVendor.metadata.tags;
      let index = array.indexOf(tag);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },

    /** online store actions */
    showCreateOnlineStorePlatformModal() {
      this.isNewVendorOnlineStorePlatformModalVisible =
        !this.isNewVendorOnlineStorePlatformModalVisible;
    },
    showVendorOnlineStoreListModal() {
      this.isVendorOnlineStorePlatformListModalVisible =
        !this.isVendorOnlineStorePlatformListModalVisible;
    },
    handleNewOnlineStorePlatform(newStores) {
      // Handle the array of selected categories
      for (const store of newStores) {
        this.setExistingOnlineStorePlatform(store);
      }
      this.isVendorOnlineStorePlatformListModalVisible = false;
    },
    setExistingOnlineStorePlatform(store) {
      const existingStore =
        this.selectedVendor.metadata.onlineStorePlatforms.find(
          (s) => s === store
        );

      if (!existingStore) {
        this.selectedVendor.metadata.onlineStorePlatforms.push(store);
        this.doesOnlineStorePlatformExist = false;
      } else {
        this.doesOnlineStorePlatformExist = true;
        setTimeout(() => {
          this.doesOnlineStorePlatformExist = false;
        }, 2000);
      }

      this.isVendorOnlineStorePlatformListModalVisible = false;
      this.isNewVendorOnlineStorePlatformModalVisible = false;
    },
    removeOnlineStorePlatformSelected(store) {
      let array = this.selectedVendor.metadata.onlineStorePlatforms;
      let index = array.indexOf(store);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },

    /** neighborhoods actions */
    showCreateNeighborhoodModal() {
      this.isNewNeighborhoodModalVisible = !this.isNewNeighborhoodModalVisible;
    },
    showNeighborhoodsListModal() {
      this.isNeighborhoodsListModalVisible =
        !this.isNeighborhoodsListModalVisible;
    },
    handleNewNeighborhoods(newNeighborhoods) {
      // Handle the array of selected categories
      for (const neighborhood of newNeighborhoods) {
        this.setExistingNeighborhood(neighborhood);
      }
      this.isNeighborhoodsListModalVisible = false;
    },
    setExistingNeighborhood(neighborhood) {
      const existingNeighborhood =
        this.selectedVendor.metadata.neighborhoods.find(
          (n) => n === neighborhood
        );

      if (!existingNeighborhood) {
        this.selectedVendor.metadata.neighborhoods.push(neighborhood);
        this.doesNeighborhoodExist = false;
      } else {
        this.doesNeighborhoodExist = true;
        setTimeout(() => {
          this.doesNeighborhoodExist = false;
        }, 2000);
      }

      this.isNeighborhoodsListModalVisible = false;
      this.isNewNeighborhoodModalVisible = false;
    },
    removeNeighborhoodSelected(neighborhood) {
      let array = this.selectedVendor.metadata.neighborhoods;
      let index = array.indexOf(neighborhood);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },

    //** addresses */
    async updateAddress() {
      this.addressIsSaving = true;
      this.addressIsBeingEdited = false;

      try {
        // Get the Bold client
        const api = await this.getBoldClient();

        // Prepare address lines
        const addressLines = [];
        if (this?.selectedVendor?.address?.lines[0]) {
          addressLines.push(this.selectedVendor.address.lines[0]);
        }
        if (this?.selectedVendor?.address?.lines[1]) {
          addressLines.push(this.selectedVendor.address.lines[1]);
        }

        // Create an address object
        let newAddress = {
          city: this.selectedVendor.address.city,
          state: this.selectedVendor.address.state,
          postalCode: this.selectedVendor.address.postalCode,
          country: this.selectedVendor.address.country,
        };

        // Conditionally add the 'lines' property
        if (addressLines.length > 0) {
          newAddress.lines = addressLines;
        }

        // Create the address using the API
        const response = await api.upsertAddress(null, {
          filter: {
            _id: this.selectedVendor.address._id,
          },
          patch: newAddress,
        });

        // Handle a successful response
        // console.log("address updated", response);
        this.addressIsSaving = false;

        const address = response.data.result;
        this.setNewAddress(address);
      } catch (error) {
        console.error("Error updating address", error);

        // Handle the error as needed
        console.log("address was NOT created");
      }
    },
    async resetAddress() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      api
        .listAddresses(
          {
            limit: 1,
          },
          { filter: { _id: this.selectedVendor.address._id } }
        )
        .then((response) => {
          // console.log("address reset query", response.data.result.records[0]);
          this.selectedVendor.address = response.data.result.records[0];
        })
        .catch((error) => {
          console.log("address reset query failed", error);
        });
    },
    async editAddress() {
      await this.resetAddress();
      this.addressIsBeingEdited = !this.addressIsBeingEdited;
    },
    toggleCreateAddressModal() {
      this.isCreateAddressModalVisible = !this.isCreateAddressModalVisible;
    },
    toggleAddressListModal() {
      this.isAddressListModalVisible = !this.isAddressListModalVisible;
    },
    setNewAddress(address) {
      this.selectedVendor.address = address;
      this.isCreateAddressModalVisible = false;
      this.isAddressListModalVisible = false;
    },

    /** navigation */
    moveToNextStep() {
      this.step += 1;
    },
    moveToPreviousStep() {
      this.step -= 1;
    },
    cancelSelect() {
      this.isClientLeadListVisible = false;
    },
    viewNewVendor() {
      this.updateMessage.successful.show = false;
      this.$router.push(`/vendor/${this.newVendorId}`);
    },
    closeSuccessModal() {
      this.updateMessage.successful.show = false;
      this.$router.push(`/vendors/`);
    },
    cancelNewVendor() {
      window.location.reload();
    },

    /** error handling */
    handleSuccess() {
      // console.log("new vendor added! ", data);
      this.updateMessage.successful.show = true;
    },
    handleError(error) {
      console.error("Error adding new vendor: ", error);
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
      setTimeout(() => {
        this.updateFailed = false;
      }, 5000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
