<template>
  <!-- <CompactHero>
    <template #heading> Add vendor </template>
  </CompactHero>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <AddVendorForm></AddVendorForm>
      </div>
    </div>
  </div> -->

  <ThePage class="tw-max-w-5xl">
    <AddVendorForm></AddVendorForm>

    </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";

import AddVendorForm from "../../components-v2/vendors/AddVendorForm.vue";
import CompactHero from "../../components/ui/hero-banners/CompactHero.vue";

export default {
  name: "AddVendor",
  components: {
    ThePage,
    CompactHero,
    AddVendorForm,
  },
  data() {
    return {
      vendors: [],
      selectedOption: "",
    };
  },
  created() {
    this.loadVendors();
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  methods: {
    async loadVendors() {
      const api = await this.getBoldClient();

      let filterParam = {};

      // let selection = "id";

      // console.log("filterParam", filterParam);

      const queryParameters = {
        limit: 1000,
        sort: "name",
        // expand: ""
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          // selection: selection,
        });

        // this.vendorsAreLoading = false;
        // this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
  },
};
</script>

<style scoped></style>
