<template>
  <template v-if="showBulkEditModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        :title="`Bulk editing vendors ${vendorsToEdit.action}`"
        @closeModal="closeModal"
        :open="showBulkEditModal"
      >
        <ModalBody>
          <BulkEditData
            :vendors="vendorsToEdit"
            @closeModal="closeModal"
            @bulkUpdateVendorStatus="bulkUpdateVendorStatus"
            @bulkUpdateVendorRegion="bulkUpdateVendorRegion"
          ></BulkEditData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        @closeModal="closeModal"
        :open="showDeleteModal"
        :showModalHeader="true"
        :showModalBody="true"
        :showModalFooter="false"
        :closeOnClickOutside="false"
        title="Delete selected vendors?"
      >
        <ModalBody>
          <DeleteData
            :vendors="vendorsToEdit.ids"
            @closeModal="closeModal"
            @confirmDelete="deleteVendors()"
          ></DeleteData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showBulkImportModal">
    <BulkImportModal
      v-if="showBulkImportModal"
      @cancel="toggleBulkImportModal"
    ></BulkImportModal>
  </template>
  <TheCard customWidthClass="tw-w-72">
    <CardBody>
      <div class="tw-hidden xl:tw-block lg:tw-flex-col tw-p-4 tw-pr-2">
        <div
          class="tw-flex tw-flex-col tw-overflow-y-auto tw-border tw-border-black"
        >
          <nav
            class="tw-flex tw-flex-1 tw-flex-col tw-divide-y tw-divide-cyan-800 tw-overflow-y-auto tw-border tw-border-black"
            aria-label="Sidebar"
          >
            <ul
              class="tw-flex tw-flex-1 tw-flex-col tw-gap-y-3 tw-list-none tw-p-0 tw-m-0"
            >
              <!-- statuses -->
              <BlockStack :gap="200">
                <div class="tw-flex tw-justify-between tw-items-center">
                  <p class="tw-m-0 tw-font-bold">Status</p>
                  <template v-if="selectedVendorStatus !== ''">
                    <span
                      class="tw-m-0 tw-text-xs tw-cursor-pointer tw-text-red-500"
                      @click="clearField('selectedVendorStatus')"
                      >clear</span
                    >
                  </template>
                </div>
                <BlockStack :gap="200">
                  <label
                    v-for="(status, index) in statusOptions"
                    :key="index"
                    class="tw-truncate tw-capitalize"
                    :for="`status-${index}`"
                  >
                    <input
                      type="radio"
                      :id="`status-${index}`"
                      :name="`status`"
                      :value="status.value"
                      :checked="selectedVendorStatus === status.value"
                      @change="
                        handleFilteringVendors(
                          'selectedVendorStatus',
                          status.value
                        )
                      "
                    />
                    {{ status.text }}
                  </label>
                </BlockStack>
              </BlockStack>
              <!-- regions -->
              <BlockStack :gap="200">
                <div class="tw-flex tw-justify-between tw-items-center">
                  <p class="tw-m-0 tw-font-bold">Region</p>
                  <template v-if="selectedRegions.length > 0">
                    <span
                      class="tw-m-0 tw-text-xs tw-cursor-pointer tw-text-red-500"
                      @click="clearField('selectedRegions')"
                      >clear</span
                    >
                  </template>
                </div>
                <BlockStack :gap="200">
                  <label
                    v-for="(region, index) in regionOptions"
                    :key="index"
                    class="tw-truncate tw-capitalize"
                    :for="`region-${index}`"
                  >
                    <input
                      type="radio"
                      :id="`region-${index}`"
                      :name="`region`"
                      :value="region.value"
                      v-model="selectedRegions[0]"
                      :checked="selectedRegions[0] === region.value"
                      @change="handleFilteringVendors(region.value)"
                    />
                    {{ region.text }}
                  </label>
                </BlockStack>
              </BlockStack>
              <!-- categories -->
              <BlockStack :gap="200">
                <BlockStack :gap="100">
                  <p class="tw-m-0 tw-font-bold">View by categories</p>
                  <template v-if="selectedCategories.length > 0">
                    <div class="tw-flex tw-justify-between tw-items-center">
                      <span
                        class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                      >
                        [{{ selectedCategories.length }} selected]
                      </span>
                      <span
                        class="tw-m-0 tw-text-xs tw-cursor-pointer tw-text-red-500"
                        @click="clearField('selectedCategories')"
                        >clear</span
                      >
                    </div>
                  </template>
                </BlockStack>
                <div class="tw-max-h-32 tw-overflow-auto">
                  <BlockStack :gap="200">
                    <label
                      class="tw-truncate"
                      v-for="(category, index) in categoryOptions"
                      :key="index"
                      :for="`category-${index}`"
                    >
                      <input
                        type="checkbox"
                        :id="`category-${index}`"
                        :value="category.value"
                        v-model="selectedCategories"
                        @change="handleFilteringVendors()"
                      />
                      {{ category.text }}
                    </label>
                  </BlockStack>
                </div>
              </BlockStack>
              <BlockStack :gap="200">
                <BlockStack :gap="100">
                  <p class="tw-m-0 tw-font-bold">View by neighborhood</p>
                  <template v-if="selectedNeighborhoods.length > 0">
                    <div class="tw-flex tw-justify-between tw-items-center">
                      <span
                        class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                      >
                        [{{ selectedNeighborhoods.length }} selected]
                      </span>
                      <span
                        class="tw-m-0 tw-text-xs tw-cursor-pointer tw-text-red-500"
                        @click="clearField('selectedNeighborhoods')"
                        >clear</span
                      >
                    </div>
                  </template>
                </BlockStack>
                <div class="tw-max-h-32 tw-overflow-auto">
                  <BlockStack :gap="200">
                    <label
                      class="tw-truncate"
                      v-for="(neighborhood, index) in neighborhoodsOptions"
                      :key="index"
                      :for="`neighborhood-${index}`"
                    >
                      <input
                        type="checkbox"
                        :id="`neighborhood-${index}`"
                        :value="neighborhood.value"
                        v-model="selectedNeighborhoods"
                        @change="handleFilteringVendors()"
                      />
                      {{ neighborhood.text }}
                    </label>
                  </BlockStack>
                </div>
              </BlockStack>
            </ul>
          </nav>
        </div>
      </div>
    </CardBody>
  </TheCard>

  <template v-if="checkingIfUserHasVendors">
    <div
      class="tw-flex tw-justify-center tw-items-center tw-w-full tw-max-w-5xl tw-mx-auto"
    >
      <PageSection :fullWidth="true">
        <PulseAnimation>
          <DataTableSkeleton></DataTableSkeleton>
        </PulseAnimation>
      </PageSection>
    </div>
  </template>
  <template v-else>
    <TheCard class="tw-w-full">
      <template v-if="userHasVendors">
        <CardHeader
          :showTitle="dashboardView"
          title="Campaigns"
          :showActions="false"
          :useSearchBar="true"
        >
          <template #card-search-bar>
            <div class="tw-max-w-lg tw-w-full tw-flex-grow">
              <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
                <div
                  class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                >
                  <b-icon-search
                    class="tw-h-5 tw-w-5 tw-text-gray-400"
                    aria-hidden="true"
                  ></b-icon-search>
                </div>
                <input
                  @keyup.enter="handleSearchQuery()"
                  v-model="searchQuery.value"
                  type="text"
                  name="giftCardsSearch"
                  id="giftCardsSearch"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                  placeholder="Search all vendors"
                />
                <template v-if="searchQuery.active">
                  <div
                    @click="resetFilters()"
                    class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-cursor-pointer"
                  >
                    <span>reset</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template #card-header-actions>
            <TheButton
              :active="this.filter.active"
              class="tw-relative"
              @click="toggleFilterModal"
              variant="tertiary"
              :hasLeadingIcon="true"
              ><template #text
                ><b-icon-sliders></b-icon-sliders>Filters</template
              >
              <template #active>
                <template
                  v-if="this.filter.active && selectedNeighborhoods.length > 0"
                >
                  <b-icon-check-circle-fill
                    class="tw-absolute -tw-top-1 -tw-right-1 tw-h-4 tw-w-4 tw-border-gray-300 tw-text-blue-400 tw-bg-white tw-rounded-full focus:tw-ring-blue-600 tw-z-10"
                  ></b-icon-check-circle-fill>
                </template>
              </template>
            </TheButton>
          </template>
        </CardHeader>
      </template>
      <CardBody>
        <template v-if="vendorsAreLoading">
          <PulseAnimation>
            <DataTableSkeleton></DataTableSkeleton>
          </PulseAnimation>
        </template>
        <template v-else>
          <!-- if userHasVendors show campaigns list -->
          <template v-if="userHasVendors && count">
            <div
              class="tw-overflow-y-auto tw-min-h-[500px] tw-max-h-[500px] tw-w-full"
            >
              <div
                class="tw-flex tw-justify-between gap-4 tw-h-12 tw-px-3 tw-bg-stone-100 tw-sticky tw-top-0 tw-z-40 tw-items-center tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b tw-border-stone-300"
              >
                <div>
                  <input
                    id="selectAllVendors"
                    name="selectAllVendors"
                    type="checkbox"
                    class="tw-left-4 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-me-2"
                    :checked="selectedVendors.length === vendors.length"
                    @change="selectAllVendors($event.target.checked)"
                  /><label class="tw-text-gray-900" for="selectAllVendors"
                    >Select all visible
                    <template v-if="selectedVendors.length > 0"
                      ><span
                        class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                      >
                        ({{ selectedVendors.length }} selected)
                      </span></template
                    ></label
                  >
                </div>
                <template v-if="selectedVendors.length > 0">
                  <div class="tw-flex tw-gap-4 tw-items-center">
                    <DropdownMenu
                      variant="secondary"
                      :disabled="selectedVendors.length === 0"
                      :items="bulkDropdownItems()"
                      :buttonDropdown="true"
                      :buttonDropdownList="true"
                      :hasTrailingIcon="true"
                      buttonText="Bulk actions"
                    >
                    </DropdownMenu>
                  </div>
                </template>
              </div>
              <table class="tw-min-w-full tw-divide-stone-300">
                <thead
                  class="tw-sticky tw-z-12 tw-border-b tw-border-stone-300"
                >
                  <tr>
                    <th
                      scope="col"
                      class="tw-min-w-[8px] tw-w-8 tw-py-3 tw-px-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Vendor
                    </th>
                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Region
                    </th>
                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Neighborhood
                    </th>
                    <th
                      scope="col"
                      class="tw-min-w-[8px] tw-w-8 tw-py-3 tw-px-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      City
                    </th>

                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-min-w-[8px] tw-w-8 tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      State
                    </th>

                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-min-w-32 tw-w-32 tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Postal code
                    </th>

                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Category
                    </th>

                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      class="tw-px-3 tw-py-3 tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      <span class="tw-sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="tw-divide-y tw-divide-gray-200 tw-bg-white tw-min-h-[500px]"
                >
                  <tr
                    v-for="vendor in vendors"
                    :key="vendor.id"
                    class="tw-overflow-visible"
                    :class="[
                      selectedVendors.includes(vendor.id) && 'bg-gray-50',
                    ]"
                  >
                    <!-- name -->
                    <td class="tw-relative tw-py-4 tw-px-3 tw-min-w-52">
                      <div
                        v-if="isVendorSelected(vendor.id)"
                        class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-blue-600"
                      ></div>
                      <div class="tw-flex tw-items-center gap-2">
                        <input
                          type="checkbox"
                          class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-cursor-pointer"
                          :checked="isVendorSelected(vendor.id)"
                          @change="toggleVendorSelection(vendor)"
                        />
                        <TheLink
                          variant="primary"
                          :url="{
                            name: 'VendorDetails',
                            params: { vendorId: vendor._id },
                          }"
                          :isRouterLink="true"
                        >
                          <template #text>
                            <div class="tw-flex tw-flex-col">
                              <template v-if="vendorName(vendor)">
                                <p class="tw-p-0 tw-m-0 tw-text-sm">
                                  {{ vendorName(vendor) || "---" }}
                                </p>
                              </template>
                              <template v-else>N/A</template>
                            </div>
                          </template>
                        </TheLink>
                      </div>
                    </td>
                    <!-- region -->
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <template v-if="vendorRegion(vendor)">
                        <p class="tw-p-0 tw-m-0 tw-text-sm">
                          {{ vendorRegion(vendor) }}
                        </p>
                      </template>
                      <template v-else>
                        {{ "---" }}
                      </template>
                    </td>
                    <!-- neighborhood -->
                    <td
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <template v-if="vendorNeighborhoods(vendor)">
                        <template
                          v-if="vendorNeighborhoods(vendor).length === 1"
                        >
                          <p class="tw-p-0 tw-m-0 tw-text-sm">
                            {{ vendorNeighborhoods(vendor)[0] }}
                          </p>
                        </template>
                        <template v-if="vendorNeighborhoods(vendor).length > 1">
                          <div class="tw-flex tw-items-center tw-gap-2">
                            <span
                              class="tw-rounded-md tw-bg-gray-100 tw-px-1.5 tw-py-1 tw-cursor-pointer"
                            >
                              <TheTooltip
                                :toolTip="true"
                                :tooltipContent="vendorNeighborhoods(vendor)"
                              >
                                <template #icon>
                                  <div
                                    class="tw-flex tw-items-center tw-gap-2 tw-text-gray-800"
                                  >
                                    <span>Multiple </span>
                                    <b-icon-eye class="tw-z-0"></b-icon-eye>
                                  </div>
                                </template>
                              </TheTooltip>
                            </span>
                          </div>
                        </template>
                      </template>
                    </td>
                    <!-- city -->
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <p class="tw-p-0 tw-m-0 tw-text-sm">
                        {{ vendorCity(vendor) || "---" }}
                      </p>
                    </td>
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <p class="tw-p-0 tw-m-0 tw-text-sm">
                        {{ vendorState(vendor) || "---" }}
                      </p>
                    </td>
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <p class="tw-p-0 tw-m-0 tw-text-sm">
                        {{ vendorPostalCode(vendor) || "---" }}
                      </p>
                    </td>
                    <td
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <template v-if="vendorCategories(vendor)">
                        <template v-if="vendorCategories(vendor).length === 1">
                          <p class="tw-p-0 tw-m-0 tw-text-sm">
                            {{ vendorCategories(vendor)[0] }}
                          </p>
                        </template>
                        <template v-if="vendorCategories(vendor).length > 1">
                          <div class="tw-flex tw-items-center tw-gap-2">
                            <span
                              class="tw-rounded-md tw-bg-gray-100 tw-px-1.5 tw-py-1 tw-cursor-pointer"
                            >
                              <TheTooltip
                                :toolTip="true"
                                :tooltipContent="vendorCategories(vendor)"
                              >
                                <template #icon>
                                  <div
                                    class="tw-flex tw-items-center tw-gap-2 tw-text-gray-800"
                                  >
                                    <span>Multiple </span>
                                    <b-icon-eye class="tw-z-0"></b-icon-eye>
                                  </div>
                                </template>
                              </TheTooltip>
                            </span>
                          </div>
                        </template>
                      </template>
                    </td>
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <DropdownMenu
                        :variant="vendorStatus(vendor) ? 'success' : 'critical'"
                        :disabled="selectedVendors.length > 0"
                        :items="statusActionItems(vendor)"
                        :buttonDropdown="true"
                        :buttonDropdownList="true"
                        :hasTrailingIcon="true"
                        size="small"
                        :buttonText="
                          vendorStatus(vendor) ? 'Online' : 'Offline'
                        "
                      >
                      </DropdownMenu>
                      <!-- <span
                        :class="{
                          'tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-green-800':
                            vendorStatus(vendor),
                          'tw-inline-flex tw-items-center tw-rounded-md tw-bg-red-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-red-600':
                            !vendorStatus(vendor),
                        }"
                      >
                        {{ vendorStatus(vendor) ? "Online" : "Offline" }}
                      </span> -->
                    </td>

                    <!-- actions -->
                    <td
                      class="tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-3"
                    >
                      <div class="tw-flex tw-justify-end">
                        <IconDropdown
                          :id="vendor._id"
                          :items="dropdownActionItems(vendor)"
                          :disabled="false"
                          variant="tertiary"
                        >
                          <template #icon>
                            <b-icon-three-dots-vertical />
                          </template>
                        </IconDropdown>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <!-- if userHasVendors AND query count is 0 show campaigns message -->
          <template v-if="userHasVendors && !count">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
            >
              <img
                style="max-height: 150px; width: auto"
                src="../../assets/svg/illustrations-test/14_no_query_results.png"
                alt="Image Description"
              />
              <h1 class="tw-text-2xl tw-m-0">No exact matches</h1>
              <p class="tw-m-0 tw-text-lg">
                Try updating your search/filters or
                <a
                  class="tw-font-bold tw-cursor-pointer"
                  @click="resetFilters()"
                  >click here</a
                >
                to reset.
              </p>
            </div>
          </template>
          <!-- if !userHasVendors show message -->
          <template v-if="!userHasVendors">
            <div
              class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
            >
              <EmptyState emptyState="campaigns"></EmptyState>
            </div>
          </template>
        </template>

        <!-- </div> -->
      </CardBody>
      <template v-if="userHasVendors">
        <CardFooter>
          <!-- Pagination -->
          <nav
            class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200"
            aria-label="Pagination"
          >
            <div class="tw-hidden sm:tw-block">
              <p class="tw-text-sm tw-text-gray-700 tw-m-0">
                <span class="tw-font-bold">{{ count }}</span> records
              </p>
            </div>
            <div
              class="tw-flex tw-flex-1 tw-justify-between tw-gap-x-3 sm:tw-justify-end"
            >
              <TheButton
                class="disabled:tw-bg-slate-200 disabled:tw-cursor-auto"
                :disabled="this.currentPage === 1"
                @click="previousPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-left></b-icon-chevron-left
                ></template>
              </TheButton>
              <TheButton
                class="disabled:tw-bg-slate-200"
                :disabled="this.isLastPage"
                @click="nextPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-right></b-icon-chevron-right
                ></template>
              </TheButton>
            </div>
          </nav>
        </CardFooter>
      </template>
    </TheCard>
  </template>
</template>

<script>
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import CardFooter from "../../components-v2/ui/layout/CardFooter.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import DataTableSkeleton from "../../components-v2/ui/skeletons/DataTableSkeleton.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import DropdownMenu from "../../components-v2/ui/forms/DropdownMenu.vue";
import IconDropdown from "../../components-v2/ui/elements/dropdown/IconDropdown.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import DeleteData from "../ui/modals/DeleteData.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import ModalFooter from "../../components-v2/ui/layout/ModalFooter.vue";
import BulkEditData from "../ui/modals/BulkEditData.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import BulkImportModal from "../../components/ui/modals/BulkImportModal.vue";
import { finishLinkingSalesforce } from "@/services/integrationService";

export default {
  name: "VendorsIndexTable",
  components: {
    IconDropdown,
    TheBox,
    BulkImportModal,
    TheTooltip,
    BulkEditData,
    ModalFooter,
    DataTableSkeleton,
    PulseAnimation,
    BlockStack,
    ModalBody,
    DeleteData,
    TheModal,
    DropdownMenu,
    TheLink,
    EmptyState,
    CardBody,
    TheCard,
    CardHeader,
    TheButton,
    CardFooter,
    PageSection,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    sharedCampaignData: {
      type: Array,
      default: () => [],
    },
    totalCampaignsCount: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  created() {
    this.$store.dispatch("brands/deactivateQuickEdit");

    const { query } = this.$route;
    // console.log("Query parameters:", query);

    // Set component data properties
    this.selectedCategories =
      Array.isArray(query.categories) && query.categories.length
        ? query.categories
        : query.categories
        ? [query.categories]
        : [];
    this.selectedNeighborhoods =
      Array.isArray(query.neighborhoods) && query.neighborhoods.length
        ? query.neighborhoods
        : query.neighborhoods
        ? [query.neighborhoods]
        : [];

    this.selectedCityValue = query.city || 0;

    // Call functions
    // this.loadFilterChanges();
    this.handleLoadingData();
    // this.setCategoriesList();
    // this.setCitiesList();
    // this.setNeighborhoodsList();
  },
  data() {
    return {
      updateMessage: {
        successful: {
          text: "Vendor successfully updated!",
          class: "bg-success p-3",
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to update your vendor. Please try again.",
          class: "bg-danger p-3",
          show: false,
        },
      },
      vendorsToEdit: { ids: null, action: null },
      searchQuery: {
        active: false,
        value: "",
      },
      filter: {
        active: false,
        matchExists: false,
        count: 0,
      },
      statusOptions: [
        { text: "online", value: true },
        { text: "offline", value: false },
      ],
      regionOptions: [
        { text: "Baltimore", value: "Baltimore" },
        { text: "Birmingham", value: "Birmingham" },
        { text: "Charlotte", value: "Charlotte" },
        { text: "Columbia", value: "Columbia" },

        { text: "Ft. Lauderdale", value: "Ft. Lauderdale" },
        { text: "Kansas City", value: "Kansas City" },
        { text: "Louisville", value: "Louisville" },
        { text: "St. Louis", value: "St. Louis" },
      ],
      dateOptions: [
        { value: "All time" },
        { value: "This week" },
        { value: "Past 7 days" },
        { value: "Past 30 days" },
        { value: "Past 90 days" },
      ],
      vendorsToDelete: [],
      selectedVendors: [],
      vendors: [],
      neighborhoodsOptions: [],
      selectedNeighborhoods: [],
      visibleNeighborhoods: [],
      categoryOptions: [],
      visibleCategories: [],
      selectedCategories: [],
      selectedRegions: [],
      selectedStatuses: [],
      showMoreNeighborhoods: true,
      selectAll: false,
      vendorsAreLoading: true,
      showBulkEditModal: false,
      selectedVendorStatus: "",
      isSearchFocused: false,
      searchIsActive: false,
      checkingIfUserHasVendors: false,
      userHasVendors: true,
      showFilterModal: false,
      showDeleteModal: false,
      isViewingSelection: false,
      filterCountIsLoading: false,
      showBulkImportModal: false,
      selectedCityValue: 0,
      searchCount: 0,
      count: 0,
      pageSize: 100,
      currentPage: 0,
      sortedValue: "name",
    };
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.vendors.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    vendorName() {
      return (vendor) => {
        return vendor?.name;
      };
    },
    vendorRegion() {
      return (vendor) => {
        if (vendor?.metadata?.region?.length >= 1) {
          return vendor?.metadata?.region[0];
        }
        return false;
      };
    },
    vendorNeighborhoods() {
      return (vendor) => {
        if (vendor?.metadata?.neighborhoods?.length === 1) {
          return vendor?.metadata?.neighborhoods;
        }
        const neighborhoods = vendor?.metadata?.neighborhoods?.join(", ");
        return neighborhoods;
      };
    },
    limitMetTooltipContent() {
      return "Unless the budget limit is updated, any redemption attempts for outstanding gift card offers will be declined until the budget resets.";
    },
    vendorCity() {
      return (vendor) => {
        return vendor?.address?.city;
      };
    },
    vendorState() {
      return (vendor) => {
        return vendor?.address?.state;
      };
    },
    vendorPostalCode() {
      return (vendor) => {
        return vendor?.address?.postalCode;
      };
    },
    vendorCategories() {
      return (vendor) => {
        if (vendor?.categories?.length === 1) {
          return vendor?.categories;
        }
        const categories = vendor?.categories?.join(", ");
        return categories;
      };
    },
    vendorStatus() {
      return (vendor) => {
        if (!vendor?.metadata?.isOnline) {
          return false;
        }
        return vendor?.metadata?.isOnline;
        // return vendor?.metadata?.isOnline;
      };
    },
    idsToEdit() {
      return this.vendorsToEdit.ids.map((vendor) => vendor._id);
    },
    idsToDelete() {
      return this.vendorsToEdit.ids.map((vendor) => vendor._id);
    },
    buttonText() {
      if (this.filterCountIsLoading) {
        return "Loading options";
      }
      if (this.filter.count) {
        return `Show ${this.filter.count} vendors`;
      }
      if (
        (!this.filter.count && this.selectedNeighborhoods.length > 0) ||
        !this.count
      ) {
        return "No exact matches";
      } else {
        return `Show ${this.count} vendors`;
      }
    },
    isButtonDisabled() {
      return (
        (!this.filter.count && this.selectedNeighborhoods.length > 0) ||
        this.filterCountIsLoading ||
        (!this.count && !this.filter.count)
      );
    },
    buttonVariant() {
      if (this.filter.count) {
        return "secondary";
      } else if (
        (!this.filter.count && this.selectedNeighborhoods.length > 0) ||
        !this.count
      ) {
        return "tertiary";
      } else {
        return "secondary";
      }
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** load page with vendors */
    handleLoadingData() {
      this.loadPageWithVendors();
      this.setNeighborhoodsList();
      this.setCategoriesList();
    },
    async loadPageWithVendors() {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      this.checkingIfUserHasVendors = true;

      this.currentPage = 1;
      let filterParam = {};

      const selection = "media description metadata name categories address";
      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      try {
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.searchCount = response.data.result.count;
        const count = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
        this.userHasVendors = count;
      } catch (error) {
        console.log("Vendors did not load", error);
      } finally {
        this.vendorsAreLoading = false;
        this.checkingIfUserHasVendors = false;
      }
    },

    /** handle filter vendors */
    handleFilteringVendors(field = null, value = null) {
      if (field) {
        this[field] = value;
      }
      if (value) {
        this.filter.active = value;
        this.showFilterModal = false;
      }
      this.currentPage = 1;
      this.clearSearchQuery();
      this.filterVendors();
    },
    preventLabelClick(event) {
      event.preventDefault();
    },
    handleCheckboxChange() {
      this.loadFilterCount();
    },
    async filterVendors(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      this.currentPage = page === undefined ? this.currentPage : page;
      this.vendorsAreLoading = true;
      this.showFilterModal = false;
      this.selectedVendors = [];

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      const selection = "media description metadata name categories address";

      const filter = this.createRequestBody();

      const requestBody = {
        filter: filter,
        selection: selection,
      };
      console.log("requestBody: ", requestBody);

      try {
        const response = await api.listVendors(queryParameters, requestBody);

        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        console.error("Failed to load campaigns:", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.vendorsAreLoading = false;
      }
    },
    async loadFilterCount() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const queryParameters = {
        limit: 1,

        expand: "metadata media address",
      };

      const selection =
        "-name -description -categories -address- -metadata -contact";
      const filter = this.createRequestBody();

      const requestBody = {
        filter: filter,
        selection: selection,
      };
      console.log("requestBody: ", requestBody);

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, requestBody);

        this.searchCount = response.data.result.count;
        const count = response.data.result.count;
        this.filter.count = count;
        this.filterCountIsLoading = false;
      } catch (error) {
        console.log("vendors did not load", error);
      } finally {
        this.vendorsAreLoading = false;
      }
    },
    handleButtonClick() {
      if (
        this.filter.count ||
        (!this.filter.count && !this.selectedNeighborhoods.length)
      ) {
        this.handleFilteringVendors(true);
      }
    },

    /** load categories & neighborhoods */
    async setNeighborhoodsList() {
      try {
        const neighborhoods = await this.getAllVendorNeighborhoods();
        const options = neighborhoods.map((neighborhood) => ({
          text: neighborhood,
          value: neighborhood,
        }));

        this.neighborhoodsOptions = options;
        this.visibleNeighborhoods = options.slice(0, 11); // Adjust the number as needed
      } catch (error) {
        // Handle errors appropriately
        console.error("Error transforming neighborhoods:", error);
        // You might want to maintain the existing options or set to an empty array
        this.neighborhoodsOptions = []; // Or maintain the existing options
      }
    },
    async getAllVendorNeighborhoods() {
      /**@type {import('@boldexchange/sdk/defs').Client} */
      const api = await this.getBoldClient();
      let filterParam = {};

      const queryParameters = {
        distinct: "metadata.neighborhoods",
      };

      try {
        const neighborhoodsRes = await api.listVendors(queryParameters, {
          filter: filterParam,
        });
        const neighborhoodsArr = neighborhoodsRes.data.result.records;
        return neighborhoodsArr;
      } catch (error) {
        this.vendorsAreLoading = false;
        console.log("tags did not load", error);
      }
    },
    async setCategoriesList() {
      try {
        const categories = await this.getAllVendorCategories();
        const categoryOptions = categories.map((category) => ({
          text: category,
          value: category,
        }));

        // Adding the default option at the beginning of the array
        // categoryOptions.unshift({ text: "All Categories", value: 0 });

        this.categoryOptions = categoryOptions;
        this.visibleCategories = categoryOptions.slice(0); // Adjust the number as needed
      } catch (error) {
        // Handle errors appropriately
        console.error("Error transforming vendors:", error);
        // You might want to maintain the existing options or set to an empty array
        this.categoryOptions = []; // Or maintain the existing options
      }
    },
    async getAllVendorCategories() {
      try {
        const api = await this.getBoldClient();
        const categoriesRes = await api.listVendors({
          distinct: "categories",
        });
        return categoriesRes.data.result.records;
      } catch (error) {
        // Handle errors appropriately
        console.error("Error fetching product categories:", error);
        return []; // Return an empty array or handle error condition
      }
    },
    showAllNeighborhoods() {
      this.visibleNeighborhoods = [...this.neighborhoodsOptions];
      this.showMoreNeighborhoods = false;
    },
    resetVisibleNeighborhoods() {
      this.visibleNeighborhoods = this.neighborhoodsOptions.slice(0, 10); // Adjust the number as needed
      this.showMoreNeighborhoods = true;
    },

    /** handle editing vendors */
    async bulkUpdateVendorStatus(isOnline) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      try {
        const patchObject = {
          "metadata.isOnline": isOnline,
        };
        const response = await api.bulkUpdateVendors(null, {
          filter: { _id: { $in: this.idsToEdit } },
          patch: patchObject,
        });
        this.handleSuccess(response, "bulkUpdateVendors");
      } catch (error) {
        console.log("vendors was NOT edited", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your edit request. Please try again."
        );
      } finally {
        this.showBulkEditModal = false;
      }
    },
    async bulkUpdateVendorRegion(region) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      try {
        const patchObject = {
          "metadata.region": [region],
        };
        const response = await api.bulkUpdateVendors(null, {
          filter: { _id: { $in: this.idsToEdit } },
          patch: patchObject,
        });
        this.handleSuccess(response, "bulkUpdateVendors");
      } catch (error) {
        console.log("vendors was NOT edited", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your edit request. Please try again."
        );
      } finally {
        this.showBulkEditModal = false;
      }
    },
    async deleteVendors() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      try {
        const response = await api.deleteVendors(null, {
          filter: { _id: { $in: this.idsToDelete } },
        });
        this.handleSuccess(response, "deleteVendors");
      } catch (error) {
        console.log("vendors were NOT deleted", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your delete request. Please try again."
        );
      } finally {
        this.showBulkEditModal = false;
      }
    },
    async archiveVendors() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      try {
        const patchObject = {
          "metadata.isArchived": true,
        };
        const response = await api.bulkUpdateVendors(null, {
          filter: { _id: { $in: this.idsToEdit } },
          patch: patchObject,
        });
        this.handleSuccess(response, "bulkUpdateVendors");
      } catch (error) {
        console.log("vendors was NOT edited", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your edit request. Please try again."
        );
      } finally {
        this.showBulkEditModal = false;
      }
    },
    async updateVendorStatus(vendor, status) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      try {
        const patchObject = {
          "metadata.isOnline": status,
        };
        const response = await api.upsertVendor(null, {
          filter: { _id: vendor._id },
          patch: patchObject,
        });
        this.handleSuccess(response, "upsertVendor");
      } catch (error) {
        console.log("vendor status was NOT edited", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your edit request. Please try again."
        );
      } finally {
        this.showBulkEditModal = false;
      }
    },
    handleBulkEdit(vendors, action) {
      console.log("vendors: ", vendors);
      this.vendorsToEdit.ids = vendors;
      this.vendorsToEdit.action = action;

      // this.vendorIdsToEdit = vendors;
      this.showBulkEditModal = !this.showBulkEditModal;
    },
    handleDeletingVendors(vendors) {
      console.log("vendors: ", vendors);
      this.vendorsToEdit.ids = vendors;
      // this.vendorsToEdit.action = action;

      // this.vendorIdsToEdit = vendors;
      this.showDeleteModal = !this.showDeleteModal;
    },
    closeModal() {
      this.showDeleteModal = false;
      // this.showRecipientDetailsModal = false;
      this.showBulkEditModal = false;
    },

    /** dropdown actions */
    dropdownActionItems(vendor) {
      return [
        {
          label: "Edit",
          isRouterLink: true,
          to: `/vendor/edit/${vendor.id}`,
        },
        // {
        //   label: "More details",
        //   isRouterLink: true,
        //   to: `/vendor/${vendor.id}`,
        // },

        // {
        //   label: "Delete",
        //   isDeleteAction: true, // This item is a delete action
        //   action: () => this.handleArchivingVendors([vendor]),
        // },
      ];
    },
    statusActionItems(vendor) {
      if (vendor?.metadata?.isOnline) {
        return [
          {
            label: "Turn OFFLINE",
            isDeleteAction: false,
            action: () => this.updateVendorStatus(vendor, false),
          },
        ];
      }
      return [
        {
          label: "Turn ONLINE",
          isDeleteAction: false,
          action: () => this.updateVendorStatus(vendor, true),
        },
      ];
    },

    /** handle pagination of records */
    async nextPage() {
      this.filterVendors(this.currentPage + 1);
    },
    async previousPage() {
      this.filterVendors(this.currentPage - 1);
    },

    /** Query filters */
    createStatusFilter() {
      let filter = {};
      if (this.selectedVendorStatus === "") {
        return filter;
      }

      if (this.selectedVendorStatus === true) {
        filter = {
          "metadata.isOnline": this.selectedVendorStatus,
        };
      } else {
        filter = {
          $or: [
            { "metadata.isOnline": { $exists: false } },
            { "metadata.isOnline": this.selectedVendorStatus },
          ],
        };
      }
      // console.log("Status Filter:", filter);
      return filter;
    },
    createRegionFilter() {
      let filter = {};

      if (this.selectedRegions.length === 0) {
        return filter;
      }

      filter = {
        "metadata.region": { $in: this.selectedRegions },
      };
      // console.log("Region Filter:", filter);
      return filter;
    },
    createCategoriesFilter() {
      let filter = {};

      if (this.selectedCategories.length === 0) {
        return filter;
      }

      filter = {
        categories: { $in: this.selectedCategories },
      };

      // console.log("Categories Filter:", filter);
      return filter;
    },
    createNeighborhoodsFilter() {
      let filter = {};

      if (this.selectedNeighborhoods.length === 0) {
        return filter;
      }

      filter = {
        "metadata.neighborhoods": { $in: this.selectedNeighborhoods },
      };

      // console.log("Neighborhoods Filter:", filter);
      return filter;
    },
    combineFilters(filters) {
      console.log("Combining Filters:", filters);

      let statusFilter = filters[0]; // Assuming status filter is always the first filter
      let otherFilters = filters
        .slice(1)
        .filter((filter) => Object.keys(filter).length > 0);

      console.log("Status Filter in combineFilters:", statusFilter);
      console.log("Other Filters in combineFilters:", otherFilters);

      if (Object.keys(statusFilter).length === 0 && otherFilters.length === 0) {
        return {};
      }

      let combinedFilter = {
        $and: [],
      };

      if (statusFilter.$and && statusFilter.$and.length > 0) {
        combinedFilter.$and.push(...statusFilter.$and);
      } else if (Object.keys(statusFilter).length > 0) {
        combinedFilter.$and.push(statusFilter);
      }

      if (otherFilters.length > 0) {
        combinedFilter.$and.push(...otherFilters);
      }

      if (combinedFilter.$and.length === 0) {
        return {};
      }

      console.log("Final Combined Filter:", combinedFilter);
      return combinedFilter;
    },
    createRequestBody() {
      const filters = [
        this.createStatusFilter(),
        this.createRegionFilter(),
        this.createCategoriesFilter(),
        this.createNeighborhoodsFilter(),
      ];

      console.log("Filters:", filters);
      return this.combineFilters(filters);
    },

    /** handle retrieving records by search query */
    async getVendorsBySearchQuery(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      this.currentPage = page === undefined ? 1 : page;

      let filterParam = {};

      if (this.searchQuery.active) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          {
            $or: [
              { name: { $regex: this.searchQuery.value } },
              { categories: { $regex: this.searchQuery.value } },
              { "address.city": { $regex: this.searchQuery.value } },
              { "address.postalCode": { $regex: this.searchQuery.value } },
              { "metadata.tags": { $regex: this.searchQuery.value } },
              { "metadata.region": { $regex: this.searchQuery.value } },

              {
                "metadata.neighborhoods": {
                  $regex: this.searchQuery.value,
                },
              },
              {
                "metadata.website_url": { $regex: this.searchQuery.value },
              },
            ],
          },
        ];
      }

      let selection = "media description metadata name categories address ";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "metadata media address",
      };

      try {
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });
        this.vendors = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        console.error("Campaigns did not load", error);
        this.handleErrorFromAPI(error); // Ensure you have a method to handle API errors
      } finally {
        this.vendorsAreLoading = false;
      }
    },
    handleSearchQuery() {
      this.searchQuery.active = true;
      this.clearFilters();
      this.getVendorsBySearchQuery();
    },
    clearFilters() {
      this.currentPage = 1;
      this.selectedNeighborhoods = [];
      this.selectedCategories = [];
      this.selectedRegions = [];
      this.selectedVendorStatus = "";
      this.filter.active = false;
      this.filter.count = 0;
    },
    createSearchQuery() {
      if (this.searchQuery.value === "" && this.activeStage === "") return {};

      let searchConditions = [];

      searchConditions = [
        { name: { $regex: this.searchQuery.value } },
        { categories: { $regex: this.searchQuery.value } },
        { "address.city": { $regex: this.searchQuery.value } },
        { "address.postalCode": { $regex: this.searchQuery.value } },
        { "metadata.tags": { $regex: this.searchQuery.value } },
        {
          "metadata.neighborhoods": {
            $regex: this.searchQuery.value,
          },
        },
        {
          "metadata.website_url": { $regex: this.searchQuery.value },
        },
      ];

      return { $or: searchConditions };
    },
    createRequestBodyWithSearchQuery() {
      // Start with recipient filter or other base filters
      let filters = {};
      // Add search query filters if applicable
      const searchQueryFilters = this.createSearchQuery();
      if (Object.keys(searchQueryFilters).length > 0) {
        filters = { ...searchQueryFilters };
      }

      // Add any other filters here similarly
      return filters;
    },

    /** modals */
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
      this.loadFilterCount();
      this.resetVisibleNeighborhoods();
    },
    handleArchivingVendors(vendors) {
      this.vendorsToEdit.ids = vendors;
      this.showDeleteModal = !this.showDeleteModal;
    },
    toggleBulkImportModal() {
      this.showBulkImportModal = !this.showBulkImportModal;
      this.$router.push({ path: "/vendors" });
    },

    /** utility handlers */
    clearSearchQuery() {
      this.searchQuery.value = "";
      this.searchQuery.active = false;
    },
    resetFilters() {
      this.currentPage = 1;
      this.selectedNeighborhoods = [];
      this.selectedCategories = [];
      this.selectedRegions = [];
      this.selectedVendorStatus = "";
      this.filter.active = false;
      this.filter.count = 0;
      this.clearSearchQuery();
      this.filterVendors();
    },
    clearField(field) {
      if (this[field] !== undefined) {
        this[field] = Array.isArray(this[field]) ? [] : "";
        this.selectedVendors = [];
        this.clearSearchQuery();
        this.currentPage = 1;
        this.filterVendors();
      }
    },

    /** handle recipient selection */
    selectAllVendors(isChecked) {
      this.selectedVendors = isChecked ? this.vendors.slice() : [];
    },
    isVendorSelected(vendorId) {
      return this.selectedVendors.some((r) => r.id === vendorId);
    },
    toggleVendorSelection(vendor) {
      const index = this.selectedVendors.findIndex((v) => v.id === vendor.id);
      if (index > -1) {
        this.selectedVendors.splice(index, 1);
      } else {
        this.selectedVendors.push(vendor);
      }
    },
    bulkDropdownItems() {
      return [
        {
          label: "Update region",
          isDeleteAction: false,
          action: () => this.handleBulkEdit(this.selectedVendors, "region"),
        },
        {
          label: "Update status",
          isDeleteAction: false,
          action: () => this.handleBulkEdit(this.selectedVendors, "status"),
        },
        // {
        //   label: "Archive",
        //   isDeleteAction: finishLinkingSalesforce, // This item is a delete action
        //   action: () => this.handleArchivingVendors(this.selectedVendors),
        // },
        {
          label: "Delete",
          isDeleteAction: true, // This item is a delete action
          action: () => this.handleDeletingVendors(this.selectedVendors),
        },
      ];
    },

    /** handle success and errors */
    handleSuccess(response, api) {
      let message = "";
      let res = response;

      if (api === "deleteVendors") {
        message = "Selected vendors were successfully deleted.";
      } else if (api === "bulkUpdateVendors") {
        message = "Selected vendors were successfully edited.";
      } else {
        message = "Vendor successfully edited.";
      }

      if (message) {
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: message,
          duration: 4000,
          id: Date.now(),
        });
      }
      this.selectedVendors = [];
      this.showBulkEditModal = false;
      this.showDeleteModal = false;
      this.filterVendors();
    },

    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.handleLoadingData();
      console.error(errorMessage);
    },

    /** mixpanel */
    // trackFilter() {
    //   this.mixpanel.track("Campaign List Filters Applied", {
    //     dateRange: this.selectedDateRange,
    //     search: this.searchQuery,
    //   });
    // },
  },
};
</script>
<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
