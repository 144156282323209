<template>
  <template v-if="showLandingPagePreview">
    <Teleport to="#modal">
      <TheModal
        @closeModal="toggleLandingPagePreview"
        :open="showLandingPagePreview"
        :closeOnClickOutside="true"
      >
        <LandingPageContent
          :campaignView="true"
          :getCampaignLandingPageData="getCampaignLandingPageData"
          :getCampaignLandingPageTitle="getCampaignLandingPageTitle"
          :getCampaignLandingPageMessage="getCampaignLandingPageMessage"
          @closeModal="toggleLandingPagePreview"
        ></LandingPageContent>
      </TheModal>
    </Teleport>
  </template>
  <ThePage class="tw-w-full">
    <BlockStack :gap="1000" class="tw-w-full tw-mb-24">
      <template v-if="!isTemplateBuilder">
        <PageSection>
          <div
            class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
          >
            <div>
              <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
                Recipients
              </h2>
              <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
                These are the individuals who will receive your eGift.
              </p>
            </div>
            <div class="md:tw-col-span-2">
              <CampaignRecipientsSelection
                :campaignRecipients="this?.getCampaignRecipients"
                :readOnlyView="true"
                :isReviewingDetails="true"
              >
              </CampaignRecipientsSelection>
            </div>
          </div>
        </PageSection>
      </template>
      <template v-if="isTemplateBuilder && isBoldXchangeAdmin">
        <PageSection>
          <div
            class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
          >
            <div>
              <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
                Settings
              </h2>
              <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
                Make your new template private or make it public.
              </p>
            </div>
            <div class="md:tw-col-span-2">
              <TheCard>
                <TheBox :withPadding="true">
                  <div>
                    <p class="tw-text-lg tw-leading-8 tw-text-gray-700">
                      Do you want add this template to the Bold Xchange
                      templates collection?
                    </p>
                    <BlockStack :gap="300">
                      <div
                        class="tw-flex tw-items-center tw-gap-4 tw-p-4 tw-bg-green-50"
                        :class="{
                          ' tw-bg-green-50':
                            campaign?.metadata?.campaign_template?.isPrivate,
                          'tw-border-solid tw-border-green-600 tw-border-l-4 tw-border-r-0 tw-border-y-0 tw-bg-green-50':
                            !campaign?.metadata?.campaign_template?.isPrivate,
                        }"
                      >
                        <input
                          type="radio"
                          id="isPrivateFalse"
                          name="isPrivateStatus"
                          :checked="!getCampaignTemplateStatus"
                          @change="handleSettingTemplateStatus(false)"
                        />
                        <label
                          for="isPrivateFalse"
                          class="tw-text-sm tw-cursor-pointer tw-text-gray-800"
                        >
                          Yes, add this template to the Bold Xchange templates
                          collection and allow all users to view and clone this
                          template.
                        </label>
                      </div>
                      <div
                        class="tw-flex tw-items-center tw-gap-4 tw-p-4"
                        :class="{
                          ' tw-bg-red-50':
                            !campaign?.metadata?.campaign_template?.isPrivate,
                          'tw-border-solid tw-border-red-600 tw-border-l-4 tw-border-r-0 tw-border-y-0 tw-bg-red-50':
                            campaign?.metadata?.campaign_template?.isPrivate,
                        }"
                      >
                        <input
                          type="radio"
                          id="isPrivateTrue"
                          name="isPrivateStatus"
                          :checked="getCampaignTemplateStatus"
                          @change="handleSettingTemplateStatus(true)"
                        />
                        <label
                          for="isPrivateTrue"
                          class="tw-text-sm tw-cursor-pointer tw-text-gray-800"
                        >
                          No, DO NOT add this template to the Bold Xchange
                          templates collection.
                        </label>
                      </div>
                    </BlockStack>
                  </div>
                </TheBox>
              </TheCard>
            </div>
          </div>
        </PageSection>
      </template>
      <PageSection>
        <div
          class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              Parameters
            </h2>
            <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
              A breakdown of the campaign's gift card amount, gift card
              selection, send mode, and other optional gift parameters.
            </p>
          </div>

          <div class="md:tw-col-span-2 tw-w-full tw-flex tw-flex-col tw-gap-3">
            <TheCard class="tw-overflow-visible">
              <TheBox :withPadding="true">
                <BlockStack :gap="800">
                  <!-- <template v-if="this?.campaign?.metadata"> -->
                  <BlockStack :gap="300">
                    <BlockStack :gap="400">
                      <!-- <template v-if="this?.campaign?.metadata"> -->
                      <div
                        class="sm:tw-flex tw-items-start tw-border-solid tw-border-2 tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
                      >
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Campaign name
                        </p>
                        <BlockStack :gap="100" class="tw-grow">
                          <template v-if="!getCampaignName">
                            <div class="tw-items-center tw-gap-1 tw-w-full">
                              <p
                                class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                              >
                                Required details are missing. Please fix
                                <a
                                  class="tw-font-bold tw-cursor-pointer"
                                  @click="handleStepChange(2)"
                                  >here</a
                                >.
                              </p>
                            </div>
                          </template>
                          <template v-else>
                            <div class="tw-flex tw-items-top tw-gap-2">
                              <p
                                class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
                              >
                                {{ this.getCampaignName }}
                              </p>
                            </div>
                          </template>
                        </BlockStack>
                      </div>
                      <div
                        class="sm:tw-flex tw-items-top tw-border-solid tw-border-2 tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
                      >
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Card amount
                        </p>
                        <BlockStack :gap="100" class="tw-grow">
                          <template v-if="!getCampaignGiftCardAmount">
                            <div class="tw-items-center tw-gap-1 tw-w-full">
                              <p
                                class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                              >
                                Required details are missing. Please fix
                                <a
                                  class="tw-font-bold tw-cursor-pointer"
                                  @click="handleStepChange(2)"
                                  >here</a
                                >.
                              </p>
                            </div>
                          </template>
                          <template v-else>
                            <div class="tw-flex tw-items-top tw-gap-2">
                              <p
                                class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
                              >
                                ${{ this.getCampaignGiftCardAmount }}.00
                              </p>
                              <TheTooltip
                                :toolTip="true"
                                :tooltipContent="giftCardAmountTooltipContent"
                              >
                                <template #icon>
                                  <div
                                    class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                  >
                                    <b-icon-exclamation
                                      class="tw-text-black tw-z-0"
                                    ></b-icon-exclamation>
                                  </div>
                                </template>
                              </TheTooltip>
                            </div>
                          </template>
                        </BlockStack>
                      </div>
                      <div
                        class="sm:tw-flex tw-items-start tw-border-solid tw-border-2 tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
                      >
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Gift card selection
                        </p>
                        <BlockStack :gap="100" class="tw-grow">
                          <template v-if="!customSelection">
                            <div class="tw-items-center tw-gap-1 tw-w-full">
                              <p
                                class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                              >
                                Required details are missing. Please fix
                                <a
                                  class="tw-font-bold tw-cursor-pointer"
                                  @click="handleStepChange(3)"
                                  >here</a
                                >.
                              </p>
                            </div>
                          </template>
                          <template v-else>
                            <div class="tw-flex tw-items-center tw-gap-2">
                              <p
                                class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
                              >
                                {{
                                  this.isCustomGiftCardSelection
                                    ? "Custom selection"
                                    : "Recipients choice"
                                }}
                              </p>
                              <TheTooltip
                                :toolTip="true"
                                :tooltipContent="
                                  giftCardSelectionTooltipContent
                                "
                              >
                                <template #icon>
                                  <div
                                    class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                  >
                                    <b-icon-exclamation
                                      class="tw-text-black tw-z-0"
                                    ></b-icon-exclamation>
                                  </div>
                                </template>
                              </TheTooltip>
                            </div>
                          </template>
                        </BlockStack>
                      </div>
                      <div
                        class="sm:tw-flex tw-items-top tw-border-solid tw-border-2 tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
                      >
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Send mode
                        </p>
                        <BlockStack :gap="100">
                          <template
                            v-if="
                              (this.getCampaignType === 'email' &&
                                (!this.getCampaignEmailSubject ||
                                  !this.getCampaignEmailText)) ||
                              !this.getCampaignType
                            "
                          >
                            <div class="tw-items-center tw-gap-1 tw-w-full">
                              <p
                                class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                              >
                                Required details are missing. Please fix
                                <a
                                  class="tw-font-bold tw-cursor-pointer"
                                  @click="handleStepChange(2)"
                                  >here</a
                                >.
                              </p>
                            </div>
                          </template>
                          <template v-else>
                            <div class="tw-flex tw-items-center tw-gap-1">
                              <p
                                class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0 tw-capitalize"
                              >
                                {{ this.getCampaignType }}
                              </p>
                              <template v-if="this.getCampaignType === 'email'">
                                <TheLink
                                  variant="primary"
                                  @click="toggleEmailPreview"
                                >
                                  <template #text>
                                    {{
                                      showEmailPreview ? "(hide)" : "(preview)"
                                    }}</template
                                  >
                                </TheLink>
                              </template>
                              <TheTooltip
                                :toolTip="true"
                                :tooltipContent="sendModeTooltipContent"
                              >
                                <template #icon>
                                  <div
                                    class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                  >
                                    <b-icon-exclamation
                                      class="tw-text-black tw-z-0"
                                    ></b-icon-exclamation>
                                  </div>
                                </template>
                              </TheTooltip>
                            </div>

                            <template v-if="showEmailPreview">
                              <div
                                class="tw-rounded-lg tw-overflow-hidden tw-shadow"
                              >
                                <table
                                  id="main_table"
                                  style="
                                    border-collapse: collapse;
                                    text-align: center;
                                    background-color: #fff;
                                    width: 100%;
                                    border: 1px solid #efeeea;
                                  "
                                >
                                  <tr>
                                    <td
                                      id="email_from_container"
                                      style="
                                        padding: 24px;
                                        padding-bottom: 0px;
                                        text-align: left;
                                        background-color: #fff;
                                      "
                                    >
                                      <p style="margin: 0; color: rgb(0, 0, 0)">
                                        {{ getCampaignGiftingLeadName }} sent
                                        you an
                                        <span style="font-weight: bold"
                                          >STL eGift card</span
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_text_container"
                                      style="
                                        padding: 24px;
                                        text-align: left;
                                        background-color: #fff;
                                        min-height: 200px;
                                      "
                                    >
                                      <template v-if="getCampaignEmailText">
                                        <div
                                          style="min-height: 150px"
                                          v-html="getCampaignEmailHtmlDisplay"
                                        ></div>
                                      </template>
                                      <template v-else>
                                        <div
                                          class="tw-border-l-4 tw-border-orange-400 tw-bg-orange-50 tw-p-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-items-center tw-gap-4"
                                        >
                                          <img
                                            style="
                                              max-height: 100px;
                                              width: auto;
                                            "
                                            src="../../../assets/svg/illustrations-test/75_pencil.png"
                                            alt="Image Description"
                                          />
                                          <p
                                            class="tw-text-lg tw-m-0 tw-text-start"
                                          >
                                            Your message will show up here.
                                          </p>
                                        </div>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_accept_container"
                                      style="
                                        padding: 24px;
                                        background-color: #f6f6f4;
                                      "
                                    >
                                      <table
                                        id="nested_table_1"
                                        style="
                                          width: 100%;
                                          margin: 0 auto;
                                          border-collapse: collapse;
                                        "
                                      >
                                        <tr>
                                          <td
                                            id="nested_table_1_td_1"
                                            style="
                                              text-align: left;
                                              padding-right: 5px;
                                              vertical-align: middle;
                                              height: 100%;
                                              max-width: 70%;
                                            "
                                          >
                                            <p
                                              style="
                                                margin: 0;
                                                color: rgb(0, 0, 0);
                                                font-size: 16px;
                                              "
                                            >
                                              Powered by
                                              <span style="font-weight: bold"
                                                >Bold Xchange</span
                                              >
                                            </p>
                                            <p
                                              style="
                                                margin: 5px 0 0;
                                                color: rgb(0, 0, 0);
                                              "
                                            >
                                              Enjoy an eGift card marketplace
                                              featuring many of St. Louis’ best
                                              small businesses.
                                            </p>
                                          </td>
                                          <td
                                            id="nested_table_1_td_2"
                                            style="
                                              text-align: center;
                                              padding: 0;
                                              height: 100%;
                                              width: 30%;
                                            "
                                          >
                                            <span
                                              style="
                                                text-align: center;
                                                background-color: #004aad;
                                                border-radius: 6px;
                                                color: #ffcc48;
                                                font-weight: bold;
                                                font-style: normal;
                                                padding: 16px 24px;
                                                font-size: 16px;
                                                text-decoration: none;
                                                display: inline-block;
                                                width: auto;
                                              "
                                              >Accept</span
                                            >
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_contact_container"
                                      style="
                                        padding: 24px;
                                        background-color: #fff;
                                      "
                                    >
                                      <table
                                        id="nested_table_2"
                                        style="
                                          width: 100%;
                                          margin: 0 auto;
                                          border-collapse: collapse;
                                        "
                                      >
                                        <tr>
                                          <td>
                                            <a
                                              href="https://boldxchange.com"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style="color: rgb(0, 0, 0)"
                                            >
                                              <img
                                                alt="Gifting Powered by Bold Xchange"
                                                title="Bold Xchange"
                                                src="https://storage.googleapis.com/boldxchange-public/Bold_Xchange_PrimaryLogo_Black_Email.png"
                                                style="
                                                  width: 175px;
                                                  height: auto;
                                                "
                                              />
                                            </a>
                                            <p
                                              style="
                                                margin: 10px 0 0;
                                                color: rgb(0, 0, 0);
                                              "
                                            >
                                              Bold Xchange P.O Box 771576 St.
                                              Louis, MO 63177
                                            </p>
                                            <p
                                              style="
                                                margin: 0;
                                                margin-bottom: 0;
                                                color: rgb(0, 0, 0);
                                              "
                                            >
                                              <a
                                                style="
                                                  text-decoration: underline;
                                                  color: rgb(7, 7, 7);
                                                "
                                                href="mailto:gifts@boldxchange.com"
                                                >gifts@boldxchange.com</a
                                              >
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_copyright_container"
                                      style="
                                        padding: 24px;
                                        text-align: center;
                                        background-color: #fff;
                                      "
                                    >
                                      <p
                                        style="
                                          text-align: center;
                                          background-color: #fff;
                                          margin: 0;
                                          color: rgb(7, 7, 7);
                                        "
                                      >
                                        <em>
                                          Copyright (C) 2024 Bold Xchange,
                                          Inc.</em
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </template>
                          </template>
                        </BlockStack>
                      </div>
                      <div
                        class="sm:tw-flex tw-items-top tw-border-solid tw-border-2 tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
                      >
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Projected campaign cost
                        </p>
                        <BlockStack :gap="100" class="tw-grow">
                          <template v-if="!getCampaignGiftCardAmount">
                            <div class="tw-items-center tw-gap-1 tw-w-full">
                              <p
                                class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                              >
                                Required details are missing to make
                                calculation.
                              </p>
                            </div>
                          </template>
                          <template v-else>
                            <div class="tw-flex tw-items-top tw-gap-2">
                              <p
                                class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
                              >
                                ${{ this.getCampaignTotalCost }}.00
                              </p>
                              <TheTooltip
                                :toolTip="true"
                                :tooltipContent="
                                  projectedCampaignCostTooltipContent
                                "
                              >
                                <template #icon>
                                  <div
                                    class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                  >
                                    <b-icon-exclamation
                                      class="tw-text-black tw-z-0"
                                    ></b-icon-exclamation>
                                  </div>
                                </template>
                              </TheTooltip>
                            </div>
                          </template>
                        </BlockStack>
                      </div>
                      <div
                        class="sm:tw-flex tw-items-start tw-border-solid tw-border-2 tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
                      >
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Budget selection
                        </p>
                        <template
                          v-if="
                            !getCampaignSelectedBudgetLimitIsMet &&
                            getCampaignBudgetId
                          "
                        >
                          <BlockStack :gap="200">
                            <p
                              class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                            >
                              You'll be able to monitor how much you've spent
                              against your selected budget below:
                            </p>
                            <TheBudget
                              :reviewCampaignView="true"
                              :budgetSessionId="budgetSessionId"
                              @resetSessionId="resetSessionId"
                              @handlePaymentMethodSetup="
                                handlePaymentMethodSetup
                              "
                            ></TheBudget>
                          </BlockStack>
                        </template>
                        <template v-else>
                          <p
                            class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                          >
                            None
                          </p>
                        </template>
                      </div>
                      <div
                        class="sm:tw-flex tw-items-start tw-border-solid tw-border-2 tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
                      >
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Gift note
                        </p>
                        <template v-if="getCampaignLandingPageStatus">
                          <BlockStack :gap="200">
                            <p
                              class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                            >
                              Recipients will be see your personalized gift note
                              during their eGift card redemption process.
                            </p>

                            <BlockStack :gap="300" class="tw-grow">
                              <BlockStack :gap="100" class="tw-grow">
                                <label
                                  for="giftNoteTitle"
                                  class="tw-text-sm tw-leading-6 tw-text-gray-900"
                                  >Title</label
                                >
                                <template v-if="!getCampaignLandingPageTitle">
                                  <div
                                    class="tw-items-center tw-gap-1 tw-w-full"
                                  >
                                    <p
                                      class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                                    >
                                      Required details are missing. Please fix
                                      <a
                                        class="tw-font-bold tw-cursor-pointer"
                                        @click="handleStepChange(2)"
                                        >here</a
                                      >.
                                    </p>
                                  </div>
                                </template>
                                <input
                                  :disabled="true"
                                  v-model="getCampaignLandingPageTitle"
                                  type="text"
                                  name="getCampaignLandingPageTitle"
                                  id="getCampaignLandingPageTitle"
                                  class="tw-block read-only:tw-bg-gray-100 tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                                  readOnly
                                />
                              </BlockStack>
                              <BlockStack :gap="100" class="tw-grow">
                                <label
                                  for="giftNoteMessage"
                                  class="tw-text-sm tw-leading-6 tw-text-gray-900"
                                  >Message</label
                                >
                                <template v-if="!getCampaignLandingPageMessage">
                                  <div
                                    class="tw-items-center tw-gap-1 tw-w-full"
                                  >
                                    <p
                                      class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                                    >
                                      Required details are missing. Please fix
                                      <a
                                        class="tw-font-bold tw-cursor-pointer"
                                        @click="handleStepChange(2)"
                                        >here</a
                                      >.
                                    </p>
                                  </div>
                                </template>

                                <div
                                  class="tw-min-h-40 tw-bg-gray-100 tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                                  v-html="
                                    this.decodeHtml(
                                      this.getCampaignLandingPageMessage
                                    )
                                  "
                                ></div>
                              </BlockStack>
                              <div>
                                <TheButton
                                  variant="secondary"
                                  size="regular"
                                  @click="toggleLandingPagePreview()"
                                >
                                  <template #text> Preview gift note </template>
                                </TheButton>
                              </div>
                            </BlockStack>
                          </BlockStack>
                        </template>
                        <template v-else>
                          <p
                            class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                          >
                            None
                          </p>
                        </template>
                      </div>
                      <div class="sm:tw-flex tw-items-start">
                        <p
                          class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                        >
                          Meeting requirement
                        </p>
                        <template v-if="getCampaignBookingEnabled">
                          <BlockStack :gap="200">
                            <p
                              class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                            >
                              Recipients will be required to accept a calendar
                              invite in order to redeem their gift.
                            </p>

                            <BlockStack :gap="300" class="tw-grow">
                              <BlockStack :gap="100" class="tw-grow">
                                <label
                                  for="calendarSelection"
                                  class="tw-text-sm tw-leading-6 tw-text-gray-900"
                                  >Calendar selection</label
                                >
                                <input
                                  v-model="getCampaignBookingProvider"
                                  type="text"
                                  name="calendarSelection"
                                  id="calendarSelection"
                                  class="tw-block tw-capitalize read-only:tw-bg-gray-100 tw-w-fit tw-rounded-md tw-border-0 tw-px-4 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                                  readOnly
                                />
                              </BlockStack>
                              <BlockStack :gap="100" class="tw-grow">
                                <label
                                  for="bookingSummary"
                                  class="tw-text-sm tw-leading-6 tw-text-gray-900"
                                  >Meeting title</label
                                >
                                <template v-if="!getCampaignBookingSummary">
                                  <div
                                    class="tw-items-center tw-gap-1 tw-w-full"
                                  >
                                    <p
                                      class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                                    >
                                      Required details are missing. Please fix
                                      <a
                                        class="tw-font-bold tw-cursor-pointer"
                                        @click="handleStepChange(2)"
                                        >here</a
                                      >.
                                    </p>
                                  </div>
                                </template>
                                <input
                                  v-model="getCampaignBookingSummary"
                                  type="text"
                                  name="bookingSummary"
                                  id="bookingSummary"
                                  class="tw-block read-only:tw-bg-gray-100 tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                                  readOnly
                                />
                              </BlockStack>
                              <BlockStack :gap="100" class="tw-grow">
                                <label
                                  for="bookingDescription"
                                  class="tw-text-sm tw-leading-6 tw-text-gray-900"
                                  >Meeting summary</label
                                >
                                <template v-if="!getCampaignBookingDescription">
                                  <div
                                    class="tw-items-center tw-gap-1 tw-w-full"
                                  >
                                    <p
                                      class="tw-text-sm tw-m-0 tw-bg-red-100 tw-inline-block"
                                    >
                                      Required details are missing. Please fix
                                      <a
                                        class="tw-font-bold tw-cursor-pointer"
                                        @click="handleStepChange(2)"
                                        >here</a
                                      >.
                                    </p>
                                  </div>
                                </template>

                                <textarea
                                  rows="3"
                                  v-model="getCampaignBookingDescription"
                                  name="bookingDescription"
                                  id="bookingDescription"
                                  class="tw-block read-only:tw-bg-gray-100 tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm"
                                  readOnly
                                ></textarea>
                              </BlockStack>
                            </BlockStack>
                          </BlockStack>
                        </template>
                        <template v-else>
                          <p
                            class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                          >
                            None
                          </p>
                        </template>
                      </div>
                      <!-- </template> -->
                    </BlockStack>
                  </BlockStack>
                  <!-- </template> -->
                </BlockStack>
              </TheBox>
            </TheCard>
          </div>
        </div>
      </PageSection>
    </BlockStack>
  </ThePage>
</template>

<script>
import ThePage from "../../../components-v2/ui/layout/ThePage.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import PageSection from "../../../components-v2/ui/layout/PageSection.vue";
import CampaignRecipientsSelection from "../../../components-v2/campaigns/CampaignRecipientsSelection";
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import TheTooltip from "../../../components-v2/ui/forms/TheTooltip.vue";
import TheLink from "../../../components-v2/ui/actions/TheLink.vue";
import TheBudget from "../../../components-v2/campaigns/builder/TheBudget.vue";
import TheModal from "../../../components-v2/ui/modals/TheModal.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import LandingPageContent from "../../../components-v2/ui/modals/LandingPageContent.vue";

export default {
  name: "ReviewDetails",
  emits: ["handleStepChange"],
  components: {
    LandingPageContent,
    TheButton,
    TheModal,
    TheBudget,
    ThePage,
    BlockStack,
    PageSection,
    TheCard,
    TheBox,
    CampaignRecipientsSelection,
    TheTooltip,
    TheLink,
  },
  props: {
    customSelection: {},
    isCustomGiftCardSelection: {
      type: Boolean,
    },
    isTemplateBuilder: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      required: true,
    },
  },
  watch: {
    templateStatus(newStatus) {
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_TEMPLATE_BUILDER_STATUS",
        newStatus
      );
    },
  },
  async created() {},
  data() {
    return {
      showEmailPreview: false,
      showLandingPagePreview: false,
      templateStatus: false,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    Avatar() {
      const url = this?.User?.photo;
      if (url) {
        if (url.indexOf("/") === 0) {
          return process.env.VUE_APP_API_BASE_URL + url;
        } else {
          return url;
        }
      } else {
        return `https://api.dicebear.com/7.x/initials/svg?seed=${this?.User?.firstName}%20${this?.User?.lastName}&backgroundColor=3949ab`;
      }
    },
    /** getters */
    getCampaignTemplateStatus() {
      return this.$store.getters["campaigns/getCampaignTemplateStatus"];
    },
    getCampaignRecipients() {
      return this.$store.getters["campaigns/getCampaignRecipients"];
    },
    getCampaignName() {
      return this.$store.getters["campaigns/getCampaignName"];
    },
    getCampaignGiftCardAmount() {
      return this.$store.getters["campaigns/getCampaignGiftCardAmount"];
    },
    getCampaignTotalCost() {
      return this.$store.getters["campaigns/getCampaignTotalCost"];
    },
    getCampaignType() {
      return this.$store.getters["campaigns/getCampaignType"];
    },
    getCampaignEmailSubject() {
      return this.$store.getters["campaigns/getCampaignEmailSubject"];
    },
    getCampaignEmailText() {
      return this.$store.getters["campaigns/getCampaignEmailText"];
    },
    getCampaignEmailHtmlDisplay() {
      return this.$store.getters["campaigns/getCampaignEmailHtmlDisplay"];
    },
    getCampaignGiftingLeadEmail() {
      return this.$store.getters["campaigns/getCampaignGiftingLeadEmail"];
    },
    getCampaignBookingEnabled() {
      return this.$store.getters["campaigns/getCampaignBookingEnabled"];
    },
    getCampaignBookingProvider() {
      return this.$store.getters["campaigns/getCampaignBookingProvider"];
    },
    getCampaignBookingSummary() {
      return this.$store.getters["campaigns/getCampaignBookingSummary"];
    },
    getCampaignBookingDescription() {
      return this.$store.getters["campaigns/getCampaignBookingDescription"];
    },
    getCampaignBudgetId() {
      return this.$store.getters["campaigns/getCampaignBudgetId"];
    },
    getCampaignSelectedBudget() {
      return this.$store.getters["campaigns/getCampaignSelectedBudget"];
    },
    getCampaignSelectedBudgetLimitIsMet() {
      return this.$store.getters[
        "campaigns/getCampaignSelectedBudgetLimitIsMet"
      ];
    },
    getCampaignLandingPageData() {
      return this.$store.getters["campaigns/getCampaignLandingPageData"];
    },
    getCampaignLandingPageStatus() {
      return this.$store.getters["campaigns/getCampaignLandingPageStatus"];
    },
    getCampaignLandingPageTitle() {
      return this.$store.getters["campaigns/getCampaignLandingPageTitle"];
    },
    getCampaignLandingPageMessage() {
      return this.$store.getters["campaigns/getCampaignLandingPageMessage"];
    },
    giftCardSelectionTooltipContent() {
      return this.isCustomGiftCardSelection
        ? "Recipients first see your curated list of e-gift cards, but they can always explore and choose any card from the Bold collection."
        : "Recipients get to explore and pick any e-gift card from the Bold collection.";
    },
    giftCardAmountTooltipContent() {
      return "The total cash value of the e-gift card that recipients can redeem.";
    },
    projectedCampaignCostTooltipContent() {
      return "The total expense calculated by multiplying the gift card amount by the number of recipients.";
    },
    sendModeTooltipContent() {
      return this.getCampaignType === "link"
        ? "You'll receive a unique link to share your campaign with your recipients exactly how you want."
        : "We'll send your campaign through our email builder, complete with an e-gift card link for easy recipient engagement.";
    },
  },
  methods: {
    handleSettingTemplateStatus(isPrivate) {
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_TEMPLATE_BUILDER_STATUS",
        isPrivate
      );
    },
    toggleEmailPreview() {
      this.showEmailPreview = !this.showEmailPreview;
    },
    toggleLandingPagePreview() {
      this.showLandingPagePreview = !this.showLandingPagePreview;
      console.log("show gift note preview");
    },
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    handleStepChange(step) {
      this.$emit("handleStepChange", step);
    },
  },
};
</script>

<style scoped></style>
