<template>
  <!-- Card -->
  <ConfirmDeleteModal
    @cancel="closeConfirmDeleteModal()"
    @confirm="deleteTheseVendors()"
    :vendors="selectedRows"
    v-if="isConfirmDeleteModalVisible"
  >
    <template #heading>Delete selected proposals?</template>
    <template #subheading
      >This action is permanent. You won't be able to recover this data once
      deleted.</template
    >
  </ConfirmDeleteModal>
  <!-- update messages -->
  <template v-if="updateMessage.successful.show">
    <div class="animated fadeInUp">
      <div class="text-start bg-soft-success border-start">
        <div
          class="border-start border-success border-5 p-5 d-flex justify-content-between align-items-center gap-1"
        >
          <p class="fs-4 fw-bold mb-0">
            {{ updateMessage.successful.text }}
          </p>
          <b-icon-x-circle
            type="button"
            class="btn btn-xs btn-outline-dark btn-icon rounded-circle"
            @click="updateMessage.successful.show = false"
          ></b-icon-x-circle>
        </div>
      </div>
    </div>
  </template>
  <template v-if="updateMessage.failed.show">
    <div class="animated fadeInUp">
      <div class="text-start bg-soft-warning border-start">
        <div
          class="border-start border-danger border-5 p-5 d-flex justify-content-between align-items-center gap-1"
        >
          <p class="fs-4 fw-bold mb-0">
            {{ updateMessage.failed.text }}
          </p>
          <b-icon-x-circle
            type="button"
            class="btn btn-xs btn-outline-dark btn-icon rounded-circle"
            @click="updateMessage.failed.show = false"
          ></b-icon-x-circle>
        </div>
      </div>
    </div>
  </template>
  <BulkImportModal
    v-if="showBulkImportModal"
    @cancel="toggleBulkImportModal"
  ></BulkImportModal>

  <template v-else>
    <!-- ========== modal content ========== -->
    <div
      class="modal fade"
      id="vendorsFiltersModal"
      tabindex="-1"
      aria-labelledby="vendorsFiltersModalLabel"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <!-- Header -->
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <p class="mb-0 fw-bold" id="vendorsFiltersModalLabel">
              Filter options
            </p>
            <button
              type="button"
              class="btn btn-xs btn-soft-secondary"
              data-bs-dismiss="modal"
            >
              <b-icon-x-lg></b-icon-x-lg>
            </button>
          </div>
          <!-- End Header -->

          <!-- Body -->
          <div
            class="card-body text-start"
            style="max-height: 600px; overflow-y: auto"
          >
            <div class="row d-flex flex-column gy-5">
              <!-- Category Select -->
              <div class="col">
                <h3 class="fw-bold">Categories</h3>
                <div>
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="(category, index) in visibleCategories"
                      :key="index"
                    >
                      <label class="text-truncate" :for="`category-${index}`">
                        <input
                          type="checkbox"
                          :id="`category-${index}`"
                          :value="category.value"
                          v-model="selectedCategories"
                          @change="handleFilterChange"
                        />
                        {{ category.text }}
                      </label>
                    </div>
                    <a
                      class="mt-2 link link-primary text-start text-decoration-underline"
                      v-if="showMoreCategories"
                      @click="showAllCategories"
                    >
                      Show More
                    </a>
                  </div>
                </div>
              </div>
              <!-- Neighborhoods -->
              <div class="col">
                <h3 class="fw-bold">Neighborhoods</h3>
                <div>
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="(neighborhood, index) in visibleNeighborhoods"
                      :key="index"
                    >
                      <label
                        class="text-truncate"
                        :for="`neighborhood-${index}`"
                      >
                        <input
                          type="checkbox"
                          :id="`neighborhood-${index}`"
                          :value="neighborhood.value"
                          v-model="selectedNeighborhoods"
                          @change="handleFilterChange"
                        />
                        {{ neighborhood.text }}
                      </label>
                    </div>
                    <a
                      class="mt-2 link link-primary text-start text-decoration-underline"
                      v-if="
                        showMoreNeighborhoods && neighborhoodsOptions.length > 6
                      "
                      @click="showAllNeighborhoods"
                    >
                      Show More
                    </a>
                  </div>
                </div>
              </div>
              <!-- cities -->
              <div class="col">
                <h3 class="fw-bold">City</h3>
                <select
                  class="form-select"
                  required
                  v-model="selectedCityValue"
                  @click="setCategoriesList()"
                  @change="handleFilterChange()"
                >
                  <option
                    v-for="option in citiesOptions"
                    :value="option.value"
                    :key="option.index"
                    class="text-white"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- End Body -->

          <div class="card-footer border-top">
            <div class="d-flex justify-content-between">
              <button
                @click="resetFilters()"
                class="btn btn-ghost fw-bold"
                href="#"
              >
                Clear all
              </button>
              <template v-if="filter.count">
                <button
                  @click="loadFilterChanges()"
                  class="btn btn-dark fw-bold"
                  data-bs-dismiss="modal"
                >
                  Show {{ filter.count }} gift cards
                </button>
              </template>
              <template
                v-else-if="
                  !filter.count &&
                  (selectedCityValue ||
                    selectedCategories.length > 0 ||
                    selectedNeighborhoods.length > 0)
                "
              >
                <button
                  disabled
                  class="btn btn-light text-danger fw-bold"
                  data-bs-dismiss="modal"
                >
                  No exact matches
                </button>
              </template>
              <template v-else>
                <button
                  @click="loadFilterChanges()"
                  class="btn btn-dark fw-bold"
                  data-bs-dismiss="modal"
                >
                  Show {{ count }} gift cards
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3 mb-lg-5">
      <!-- Header -->
      <div class="card-header card-header-content-md-between mb-0 bg-light">
        <!-- Search -->
        <div>
          <div class="input-group input-group-merge">
            <input
              @keyup.enter="handleSearchQuery()"
              type="text"
              class="form-control border border-dark"
              placeholder="Search vendors"
              aria-label="Search vendors"
              v-model="searchQuery"
            />
            <button
              type="button"
              class="btn btn-outline-dark d-flex align-items-center"
              @click="handleSearchQuery()"
            >
              <b-icon-search class="me-2"></b-icon-search>Search
            </button>
          </div>
        </div>
        <!-- filter modal -->
        <button
          class="btn"
          :class="{
            'btn-outline-dark fw-bold': !filter.count,
            'btn-outline-dark text-success fw-bold': filter.count,
          }"
          data-bs-toggle="modal"
          data-bs-target="#vendorsFiltersModal"
          @click="resetVisibleCategoriesAndTags()"
        >
          <b-icon-sliders class="me-2"></b-icon-sliders> Filters
        </button>
      </div>
      <!-- End Header -->

      <!-- loading icon -->
      <LoadingGiftIconSmall v-if="vendorsAreLoading"></LoadingGiftIconSmall>

      <div class="container-fluid" v-if="!vendorsAreLoading">
        <div
          class="d-flex align-items-center justify-content-between text-start py-3"
        >
          <p class="mb-0">
            Showing
            <span class="badge bg-warning">{{ this.count }}</span>
            results
          </p>
          <template v-if="selectedRows.length > 0">
            <div class="d-sm-flex justify-content-lg-end align-items-sm-center">
              <span class="d-block d-sm-inline-block fs-5 me-3 mb-2 mb-sm-0">
                <span>{{ selectedRows.length }}</span>
                Selected
              </span>
              <button
                type="button"
                class="btn btn-outline-danger btn-sm mb-2 mb-sm-0 me-2"
                @click="showConfirmDeleteModal()"
                :disabled="selectedRows.length === 0"
              >
                <b-icon-trash class="me-2"></b-icon-trash>Delete selected
                vendors
              </button>
              <button
                type="button"
                class="btn btn-soft-secondary btn-sm mb-2 mb-sm-0 me-2"
                @click="cancelDeleteProductsSelected()"
                :disabled="selectedRows.length === 0"
              >
                Cancel selection
              </button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex gap-2">
              <Tooltip>
                <template #default="{ hideTooltip }">
                  <button
                    type="button"
                    class="btn btn-outline-dark me-2 fs-3 fw-bold"
                    @click="addSingleVendor(hideTooltip)"
                  >
                    <b-icon-building-add class=""></b-icon-building-add>
                  </button>
                </template>
                <template #tooltipContent>Add single vendor</template>
              </Tooltip>
              <Tooltip>
                <template #default="{ hideTooltip }">
                  <button
                    type="button"
                    class="btn btn-outline-dark me-2 fs-3 fw-bold"
                    @click="toggleBulkImportModal(hideTooltip)"
                  >
                    <b-icon-filetype-csv class=""></b-icon-filetype-csv>
                  </button>
                </template>
                <template #tooltipContent>Bulk upload vendors</template>
              </Tooltip>
            </div>
          </template>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center py-10"
          v-if="this.count === 0"
        >
          <img
            class="avatar avatar-xxl mb-3"
            src="../../assets/svg/illustrations/oc-error.svg"
            alt="Image Description"
            data-hs-theme-appearance="default"
          />

          <div class="d-flex flex-column">
            <p class="mb-0">
              Showing
              <span class="badge bg-warning">{{ this.count }}</span>
              results
            </p>
            <p>
              Update your filter/search or refresh the page
              <a class="link primary-link text-primary" @click="resetFilters()"
                >here</a
              >
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="
          this.vendors.length !== 0 && !vendorsAreLoading && this.count !== 0
        "
      >
        <div class="table-responsive" style="min-height: 75vh">
          <table class="table">
            <thead class="thead-light text-start">
              <tr>
                <th class="text-center">
                  <div class="d-flex flex-column gap-1">
                    <input
                      type="checkbox"
                      @change="selectAllRows"
                      v-model="selectAll"
                    />Select all
                  </div>
                </th>
                <th scope="col">Vendor</th>
                <th scope="col" class="text-center">City</th>
                <th scope="col" class="text-center">State</th>
                <th scope="col" class="text-center">Postal Code</th>
                <th scope="col" class="text-center">Country</th>
                <th scope="col" class="text-center">Categories</th>
                <th scope="col" class="text-center">Status</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>

            <tbody>
              <VendorListItem
                v-for="vendor in vendors"
                :key="vendor._id"
                :vendor="vendor"
                :select-all="selectAll"
                :selected-rows="selectedRows"
                @row-selected="handleRowSelection"
                @reloadList="reloadList"
                :quickEditActive="getQuickEditStatus"
                @activateQuickEdit="activateQuickEdit"
                @deactivateQuickEdit="deactivateQuickEdit"
                @showSuccessMessage="showSuccessMessage"
              >
              </VendorListItem>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div
            class="row justify-content-center justify-content-sm-between align-items-sm-center"
          >
            <!-- Pagination -->
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item">
                  <a
                    class="page-link"
                    :class="{ disabled: this.currentPage === 1 }"
                    @click="previousPage()"
                    aria-label="Previous"
                    >Prev</a
                  >
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">{{ currentPage }}</a>
                </li>
                <li class="page-item">
                  <a
                    class="page-link"
                    @click="nextPage()"
                    aria-label="Next"
                    :class="{ disabled: this.isLastPage }"
                    >Next</a
                  >
                </li>
              </ul>
            </nav>
            <!-- End Pagination -->
            <!-- End Col -->
          </div>
          <!-- End Row -->
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import ConfirmDeleteModal from "../../components/ui/modals/ConfirmDeleteModal.vue";
import VendorListItem from "./VendorListItem.vue";
import LoadingGiftIconSmall from "../../components/ui/LoadingGiftIconSmall.vue";
import BulkImportModal from "../../components/ui/modals/BulkImportModal.vue";
import Tooltip from "../../components/ui/Tooltip.vue";

export default {
  name: "VendorList",
  components: {
    LoadingGiftIconSmall,
    BulkImportModal,
    VendorListItem,
    ConfirmDeleteModal,
    Tooltip,
  },
  created() {
    this.$store.dispatch("brands/deactivateQuickEdit");

    // Log route query parameters
    const { query } = this.$route;
    // console.log("Query parameters:", query);

    // Set component data properties
    this.selectedCategories =
      Array.isArray(query.categories) && query.categories.length
        ? query.categories
        : query.categories
        ? [query.categories]
        : [];
    this.selectedNeighborhoods =
      Array.isArray(query.neighborhoods) && query.neighborhoods.length
        ? query.neighborhoods
        : query.neighborhoods
        ? [query.neighborhoods]
        : [];

    this.selectedCityValue = query.city || 0;

    // Call functions
    this.loadFilterChanges();
    this.setCategoriesList();
    this.setCitiesList();
    this.setNeighborhoodsList();
  },

  data() {
    return {
      updateMessage: {
        successful: {
          text: "Vendor successfully updated!",
          class: "bg-success p-3",
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to update your vendor. Please try again.",
          class: "bg-danger p-3",
          show: false,
        },
      },

      isConfirmDeleteModalVisible: false,
      selectedRows: [],
      selectAll: false, // Add this to control the "Select All" checkbox
      filter: {
        active: false,
        matchExists: false,
        count: 0,
      },
      searchCount: 0,
      count: 0,
      pageSize: 10,
      currentPage: 0,
      sortedValue: "sku",
      vendorsAreLoading: true,
      products: [],
      debounce: null,
      vendors: [],
      categoryOptions: [],
      citiesOptions: [],
      neighborhoodsOptions: [],
      selectedCategories: [],
      selectedNeighborhoods: [],
      visibleCategories: [],
      visibleNeighborhoods: [],
      selectedCategoryValue: 0,
      selectedCityValue: 0,
      searchQuery: "",
      showBulkImportModal: false,
      showBulkImportForm: false,
      vendorImportData: [],
      showMoreCategories: true,
      showMoreNeighborhoods: true,
    };
  },
  computed: {
    getQuickEditStatus() {
      const status = this.$store.getters["brands/getQuickEditStatus"];
      return status;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.vendors.length < this.pageSize
      );
    },
    isFilterActive() {
      return this.selectedCategoryValue ? true : false;
    },
    getImportedVendors() {
      return this.$store.getters["brands/getImportedVendors"];
    },
  },
  methods: {
    async loadVendors() {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      this.currentPage = 1;
      // console.log("currentPage", this.currentPage);

      let filterParam = {};

      let selection = "media description metadata name categories address ";

      // if category filter is !== 0
      if (this.selectedCategories && this.selectedCategories.length !== 0) {
        filterParam["categories"] = {
          $all: this.selectedCategories,
        };
      }

      // If neighborhoods is truthy and has items, add to $and array
      if (
        this.selectedNeighborhoods &&
        this.selectedNeighborhoods.length !== 0
      ) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.neighborhoods": { $all: this.selectedNeighborhoods } },
        ];
      }

      // if city filter is !== 0
      if (this.selectedCityValue !== 0) {
        filterParam["address.city"] = {
          $in: [this.selectedCityValue],
        };
      }

      // console.log("filterParam", filterParam);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: "name",
        expand: "media address",
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
    async loadFilterCount() {
      const api = await this.getBoldClient();

      let filterParam = {};

      let selection = "metadata name categories address contact";

      // if category filter is !== 0
      if (this.selectedCategories && this.selectedCategories.length !== 0) {
        filterParam["categories"] = {
          $all: this.selectedCategories,
        };
      }

      // If neighborhoods is truthy and has items, add to $and array
      if (
        this.selectedNeighborhoods &&
        this.selectedNeighborhoods.length !== 0
      ) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.neighborhoods": { $all: this.selectedNeighborhoods } },
        ];
      }

      // if city filter is !== 0
      if (this.selectedCityValue !== 0) {
        filterParam["address.city"] = {
          $in: [this.selectedCityValue],
        };
      }

      // console.log("filterParam", filterParam);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: "name",
        expand: "media address",
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        const count = response.data.result.count;
        if (count) {
          this.filter.matchExists = true;
        } else {
          this.filter.matchExists = false;
        }
        this.filter.count = count;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
    async nextPage(page) {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      if (page === undefined) {
        this.currentPage++;
      } else {
        this.currentPage = page;
      }
      // console.log("currentPage", this.currentPage);

      let filterParam = {};

      let selection = "media description metadata name categories address ";

      // if category filter is !== 0
      if (this.selectedCategories && this.selectedCategories.length !== 0) {
        filterParam["categories"] = {
          $all: this.selectedCategories,
        };
      }

      // If neighborhoods is truthy and has items, add to $and array
      if (
        this.selectedNeighborhoods &&
        this.selectedNeighborhoods.length !== 0
      ) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.neighborhoods": { $all: this.selectedNeighborhoods } },
        ];
      }

      // if city filter is !== 0
      if (this.selectedCityValue !== 0) {
        filterParam["address.city"] = {
          $in: [this.selectedCityValue],
        };
      }

      // if status filter is !== ''
      if (this.searchQuery !== "") {
        filterParam = {
          $or: [
            { name: { $regex: this.searchQuery } },
            { categories: { $regex: this.searchQuery } },
            { "address.city": { $regex: this.searchQuery } },
            { "address.state": { $regex: this.searchQuery } },
            { "address.postalCode": { $regex: this.searchQuery } },
            { "metadata.tags": { $regex: this.searchQuery } },
            { "metadata.neighborhoods": { $regex: this.searchQuery } },
            { "metadata.gift_card_minimum": { $regex: this.searchQuery } },
            { "metadata.website_url": { $regex: this.searchQuery } },
          ],
        };
      }

      // console.log("filterParam", filterParam);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: "name",
        expand: "media address",
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
    async previousPage(page) {
      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      if (page === undefined) {
        this.currentPage--;
      } else {
        this.currentPage = page;
      }
      // console.log("currentPage", this.currentPage);

      let filterParam = {};

      let selection = "media description metadata name categories address ";

      // if category filter is !== 0
      if (this.selectedCategories && this.selectedCategories.length !== 0) {
        filterParam["categories"] = {
          $all: this.selectedCategories,
        };
      }

      // If neighborhoods is truthy and has items, add to $and array
      if (
        this.selectedNeighborhoods &&
        this.selectedNeighborhoods.length !== 0
      ) {
        filterParam.$and = [
          ...(filterParam.$and || []),
          { "metadata.neighborhoods": { $all: this.selectedNeighborhoods } },
        ];
      }

      // if city filter is !== 0
      if (this.selectedCityValue !== 0) {
        filterParam["address.city"] = {
          $in: [this.selectedCityValue],
        };
      }

      // if status filter is !== ''
      if (this.searchQuery !== "") {
        filterParam = {
          $or: [
            { name: { $regex: this.searchQuery } },
            { categories: { $regex: this.searchQuery } },
            { "address.city": { $regex: this.searchQuery } },
            { "address.state": { $regex: this.searchQuery } },
            { "address.postalCode": { $regex: this.searchQuery } },
            { "metadata.tags": { $regex: this.searchQuery } },
            { "metadata.neighborhoods": { $regex: this.searchQuery } },
            { "metadata.gift_card_minimum": { $regex: this.searchQuery } },
            { "metadata.website_url": { $regex: this.searchQuery } },
          ],
        };
      }

      // console.log("filterParam", filterParam);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: "name",
        expand: "media address",
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
    async getVendorsBySearchQuery(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.vendorsAreLoading = true;
      if (page === undefined) {
        this.currentPage = 1;
      } else {
        this.currentPage = page;
      }
      let filterParam = {
        $or: [
          { name: { $regex: this.searchQuery } },
          { categories: { $regex: this.searchQuery } },
          { "address.city": { $regex: this.searchQuery } },
          { "address.state": { $regex: this.searchQuery } },
          { "address.postalCode": { $regex: this.searchQuery } },
          { "metadata.tags": { $regex: this.searchQuery } },
          { "metadata.neighborhoods": { $regex: this.searchQuery } },
          { "metadata.gift_card_minimum": { $regex: this.searchQuery } },
          { "metadata.website_url": { $regex: this.searchQuery } },
        ],
      };
      let selection = "media description metadata name categories address ";
      // console.log("filterParam", filterParam);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: "name",
        expand: "media address",
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        this.vendorsAreLoading = false;
        this.searchCount = response.data.result.count;
        this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
    async getAllVendorNeighborhoods() {
      /**@type {import('@boldexchange/sdk/defs').Client} */
      const api = await this.getBoldClient();
      let filterParam = {};

      const queryParameters = {
        distinct: "metadata.neighborhoods",
      };

      try {
        const neighborhoodsRes = await api.listVendors(queryParameters, {
          filter: filterParam,
        });
        // console.log(neighborhoodsRes);
        const neighborhoodsArr = neighborhoodsRes.data.result.records;
        // console.log("tagsArr", neighborhoodsArr);
        return neighborhoodsArr;
      } catch (error) {
        this.vendorsAreLoading = false;
        console.log("tags did not load", error);
      }
    },
    async getAllVendorCategories() {
      try {
        const api = await this.getBoldClient();
        const categoriesRes = await api.listVendors({
          distinct: "categories",
        });
        return categoriesRes.data.result.records;
      } catch (error) {
        // Handle errors appropriately
        console.error("Error fetching product categories:", error);
        return []; // Return an empty array or handle error condition
      }
    },
    async getAllCities() {
      try {
        const api = await this.getBoldClient();
        const citiesRes = await api.listAddresses({
          distinct: "city",
        });
        return citiesRes.data.result.records;
      } catch (error) {
        // Handle errors appropriately
        console.error("Error fetching product categories:", error);
        return []; // Return an empty array or handle error condition
      }
    },
    async setCategoriesList() {
      try {
        const categories = await this.getAllVendorCategories();
        const categoryOptions = categories.map((category) => ({
          text: category,
          value: category,
        }));

        // Adding the default option at the beginning of the array
        // categoryOptions.unshift({ text: "All Categories", value: 0 });

        this.categoryOptions = categoryOptions;
        this.visibleCategories = categoryOptions.slice(0, 10); // Adjust the number as needed
      } catch (error) {
        // Handle errors appropriately
        console.error("Error transforming vendors:", error);
        // You might want to maintain the existing options or set to an empty array
        this.categoryOptions = []; // Or maintain the existing options
      }
    },
    async setCitiesList() {
      try {
        const cities = await this.getAllCities();
        const options = cities.map((category) => ({
          text: category,
          value: category,
        }));

        // Adding the default option at the beginning of the array
        options.unshift({ text: "All Cities", value: 0 });

        this.citiesOptions = options;
      } catch (error) {
        // Handle errors appropriately
        console.error("Error transforming vendors:", error);
        // You might want to maintain the existing options or set to an empty array
        this.categoryOptions = []; // Or maintain the existing options
      }
    },
    async setNeighborhoodsList() {
      try {
        const neighborhoods = await this.getAllVendorNeighborhoods();
        const options = neighborhoods.map((neighborhood) => ({
          text: neighborhood,
          value: neighborhood,
        }));

        this.neighborhoodsOptions = options;
        this.visibleNeighborhoods = options.slice(0, 6); // Adjust the number as needed
      } catch (error) {
        // Handle errors appropriately
        console.error("Error transforming neighborhoods:", error);
        // You might want to maintain the existing options or set to an empty array
        this.neighborhoodsOptions = []; // Or maintain the existing options
      }
    },
    async deleteTheseVendors() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      try {
        const api = await this.getBoldClient();
        const proposalIds = this.selectedRows.map((proposal) => proposal._id);

        await Promise.all([this.bulkDeleteVendors(api, proposalIds)]);
        this.handleSuccess();
      } catch (error) {
        this.handleError(error);
      }
    },
    async bulkDeleteVendors(api, proposalIds) {
      await api.deleteVendors(null, {
        filter: {
          _id: { $in: proposalIds },
        },
      });
    },
    resetVisibleCategoriesAndTags() {
      this.visibleCategories = this.categoryOptions.slice(0, 10); // Adjust the number as needed
      this.visibleNeighborhoods = this.neighborhoodsOptions.slice(0, 10); // Adjust the number as needed
      this.showMoreCategories = true;
      this.showMoreNeighborhoods = true;
    },
    showAllCategories() {
      this.visibleCategories = [...this.categoryOptions];
      this.showMoreCategories = false;
    },
    showAllNeighborhoods() {
      this.visibleNeighborhoods = [...this.neighborhoodsOptions];
      this.showMoreNeighborhoods = false;
    },
    handleFilterChange() {
      this.loadFilterCount();
    },
    updateFilters() {
      this.searchQuery = "";
      const query = {};
      // categories
      if (this.selectedCategories.length === 1)
        query.categories = [this.selectedCategories[0]];
      if (this.selectedCategories.length > 1)
        query.categories = this.selectedCategories;
      // neighborhoods
      if (this.selectedNeighborhoods.length === 1)
        query.neighborhoods = [this.selectedNeighborhoods[0]];
      if (this.selectedNeighborhoods.length > 1)
        query.neighborhoods = this.selectedNeighborhoods;
      // city
      if (this.selectedCityValue !== 0) query.city = this.selectedCityValue;

      const nonEmptyFilters = Object.fromEntries(
        Object.entries(query).filter(
          ([_, value]) => value !== 0 && value !== ""
        )
      );

      const updatedQuery =
        Object.keys(nonEmptyFilters).length > 0 ? nonEmptyFilters : undefined;

      this.$router.push({ path: "/vendors", query: updatedQuery });
    },
    clearFilterQuery() {
      const query = {};

      // categories
      if (this.selectedCategories.length === 1)
        query.categories = [this.selectedCategories[0]];
      if (this.selectedCategories.length > 1)
        query.categories = this.selectedCategories;
      // neighborhoods
      if (this.selectedNeighborhoods.length === 1)
        query.neighborhoods = [this.selectedNeighborhoods[0]];
      if (this.selectedNeighborhoods.length > 1)
        query.neighborhoods = this.selectedNeighborhoods;
      // city
      if (this.selectedCityValue !== 0) query.city = this.selectedCityValue;

      const nonEmptyFilters = Object.fromEntries(
        Object.entries(query).filter(
          ([_, value]) => value !== 0 && value !== ""
        )
      );

      const updatedQuery =
        Object.keys(nonEmptyFilters).length > 0 ? nonEmptyFilters : undefined;

      this.$router.push({ path: "/vendors", query: updatedQuery });
    },
    loadFilterChanges() {
      this.updateFilters();
      this.loadVendors();
      if (
        this.selectedCategories.length > 0 ||
        this.selectedNeighborhoods.length > 0 ||
        this.selectedCityValue !== 0
      ) {
        this.loadFilterCount();
      }
    },
    handleSearchQuery() {
      this.clearFilters();
      this.clearFilterQuery();
      this.getVendorsBySearchQuery();
      if (
        this.selectedCategories.length > 0 ||
        this.selectedNeighborhoods.length > 0 ||
        this.selectedCityValue !== 0
      ) {
        this.loadFilterCount();
      }
    },
    handleSuccess() {
      console.log("Bulk delete of proposals successful");
      this.isConfirmDeleteModalVisible = false;
      this.selectedRows = [];
      this.selectAll = false;
      this.showSuccessMessage();
    },
    handleError(error) {
      console.log("Bulk delete of proposals failed", error);
      this.isConfirmDeleteModalVisible = false;
      this.selectedRows = [];
      this.selectAll = false;
      this.showErrorMessage();
    },
    activateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to true
      this.$store.dispatch("brands/activateQuickEdit");
    },
    deactivateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to false
      this.$store.dispatch("brands/deactivateQuickEdit");
    },
    selectAllRows() {
      if (this.selectAll) {
        this.selectedRows = this.vendors.map((vendor) => vendor);
      } else {
        this.selectedRows = [];
      }
    },
    cancelDeleteProductsSelected() {
      this.selectAll = false;
      this.selectAllRows();
    },
    handleRowSelection(vendor) {
      const existingVendorIndex = this.selectedRows.findIndex(
        (v) => v._id === vendor._id
      );

      if (existingVendorIndex !== -1) {
        // If the row is already selected, deselect it
        this.selectedRows.splice(existingVendorIndex, 1);
      } else {
        // If the row is not selected, select it
        this.selectedRows.push(vendor);
      }
    },
    clearFilters() {
      this.selectedCategories = [];
      this.selectedNeighborhoods = [];
      this.selectedCityValue = 0;
      this.filter.count = 0;
      this.$store.dispatch("brands/deactivateQuickEdit");
    },
    reloadList() {
      const page = this.currentPage;
      this.$store.dispatch("brands/deactivateQuickEdit");
      if (this.searchQuery !== "") {
        this.getVendorsBySearchQuery(page);
      } else {
        this.nextPage(page);
      }
    },
    resetFilters() {
      this.selectedCategories = [];
      this.selectedNeighborhoods = [];
      this.selectedCityValue = 0;
      this.filter.count = 0;
      this.currentPage = 0;
      this.$store.dispatch("brands/deactivateQuickEdit");
      this.clearFilterQuery();
      this.nextPage();
    },
    showConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    showSuccessMessage() {
      this.updateMessage.successful.show = true;
      setTimeout(() => {
        this.updateMessage.successful.show = false;
      }, 3000);
      this.reloadList();
    },
    showErrorMessage() {
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
      this.reloadList();
    },
    toggleBulkImportModal() {
      this.showBulkImportModal = !this.showBulkImportModal;
      this.$router.push({ path: "/vendors" });
    },
    addSingleVendor() {
      this.$router.push({ path: "/add/vendor" });
    },
    toggleBulkVendorView() {
      this.showBulkImportModal = false;
      this.showBulkImportForm = true;
    },
  },
};
</script>

<style scoped>
th,
a {
  cursor: pointer;
}
.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}
</style>
