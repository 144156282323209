<template>
  <FixedModalSmall>
    <!-- buttons -->
    <div class="row">
      <div class="d-flex justify-content-end">
        <div class="d-flex gap-2">
          <button
            type="button"
            class="btn btn-xs btn-primary"
            @click.stop="editVendor()"
          >
            <b-icon-pencil></b-icon-pencil>
          </button>
          <button
            type="button"
            class="btn btn-xs btn-info"
            @click.stop="viewVendorDetails()"
          >
            <b-icon-arrow-up-right></b-icon-arrow-up-right>
          </button>
          <button
            type="button"
            class="btn btn-xs btn-soft-secondary"
            @click.prevent="$emit('cancel')"
          >
            <b-icon-x-lg class=""></b-icon-x-lg>
          </button>
        </div>
      </div>
      <div class="col">
        <div class="card-body pb-0">
          <h1 class="text-bold-xchange-blue p-0">
            {{ vendorName }}
          </h1>
        </div>
      </div>
    </div>
    <!-- content -->
    <div
      id="vendorModalContent"
      class="card-body text-start position-relative pt-0"
      style="max-height: 450px; overflow: auto; overflow-x: hidden"
    >
      <div class="row">
        <!-- media -->
        <div class="col-12">
          <!-- media -->
          <template v-if="media.length === 1">
            <ProductCarousel :items="media" :options="options_1">
              <template v-slot:default="{ item }">
                <img
                  class="img-fluid object-fit-cover rounded"
                  :src="item.url"
                  alt="card image"
                  style="max-height: 250px; width: 100%"
                />
              </template>
            </ProductCarousel>
          </template>
          <template v-if="media.length > 1">
            <ProductCarousel :items="media" :options="options_2">
              <template v-slot:default="{ item }">
                <img
                  class="img-fluid object-fit-cover rounded"
                  :src="item.url"
                  alt="card image"
                />
              </template>
            </ProductCarousel>
          </template>
          <template v-if="media.length === 0">
            <img
              class="img-fluid object-fit-cover rounded"
              src="../../../assets/img/1920x400/Bold_Xchange_Gift_Card_Placeholder_WhiteOnBlue_1200x630.jpg"
              alt="card image"
              style="max-height: 250px; width: 100%"
            />
          </template>
        </div>
        <template v-if="scrollPositionIsAtTop">
          <div class="show-more m-0 py-2 text-primary fw-bold">
            <b-icon-arrow-down-circle
              class="text-primary fw-bold me-1"
            ></b-icon-arrow-down-circle
            >see more
          </div>
        </template>
        <!-- general information -->
        <div class="col-12 my-2">
          <div class="alert bg-soft-secondary text-center">
            <h3 class="mb-0 fw-bold text-secondary">General Information</h3>
          </div>
          <div class="row">
            <!-- Bio -->
            <div class="col-12 text-start d-flex flex-column">
              <h4 class="mb-0">Brand Partner Bio</h4>
              <p
                :class="{
                  'text-danger': !vendorDescription,
                  'text-info': vendorDescription,
                }"
              >
                {{
                  vendorDescription ? vendorDescription : noDataPlaceholderText
                }}
              </p>
            </div>
            <!-- Categories -->
            <div class="col text-start d-flex flex-column">
              <div class="mb-0"><h4>Categories</h4></div>
              <div class="d-flex flex-row flex-wrap justify-content-left mt-1">
                <template
                  v-if="vendorCategories && vendorCategories.length > 0"
                >
                  <div
                    v-for="(category, key) in vendorCategories"
                    :key="'item-' + key"
                  >
                    <!-- badge -->
                    <span class="bg-warning rounded me-1 px-2 py-1">
                      <span class="text-dark ms-1">{{ category }}</span>
                    </span>
                  </div>
                </template>
                <template v-else>
                  <p class="text-danger">{{ noDataPlaceholderText }}</p>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- Contact Information -->
        <div class="col-12 my-2">
          <div class="alert bg-soft-secondary text-center">
            <h3 class="mb-0 fw-bold text-secondary">Contact Information</h3>
          </div>
          <div class="row">
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">Email:</h4>
              <span
                :class="{
                  'text-danger': !vendorContactEmail,
                  'text-info': vendorContactEmail,
                }"
                >{{
                  vendorContactEmail
                    ? vendorContactEmail
                    : noDataPlaceholderText
                }}</span
              >
            </div>
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">Phone:</h4>
              <span
                :class="{
                  'text-danger': !vendorContactPhone,
                  'text-info': vendorContactPhone,
                }"
                >{{
                  vendorContactPhone
                    ? vendorContactPhone
                    : noDataPlaceholderText
                }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">Name:</h4>
              <span
                :class="{
                  'text-danger': !vendorContactName,
                  'text-info': vendorContactName,
                }"
                >{{
                  vendorContactName ? vendorContactName : noDataPlaceholderText
                }}</span
              >
            </div>
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">Title:</h4>
              <span
                :class="{
                  'text-danger': !vendorContactTitle,
                  'text-info': vendorContactTitle,
                }"
                >{{
                  vendorContactTitle
                    ? vendorContactTitle
                    : noDataPlaceholderText
                }}</span
              >
            </div>
          </div>
        </div>
        <!-- address -->
        <div class="col-12 my-2">
          <div class="alert bg-soft-secondary text-center">
            <h3 class="mb-0 fw-bold text-secondary">Address Information</h3>
          </div>
          <div class="row">
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">City:</h4>
              <span
                :class="{
                  'text-danger': !vendorCity,
                  'text-info': vendorCity,
                }"
                >{{ vendorCity ? vendorCity : noDataPlaceholderText }}</span
              >
            </div>
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">State:</h4>
              <span
                :class="{
                  'text-danger': !vendorState,
                  'text-info': vendorState,
                }"
                >{{ vendorState ? vendorState : noDataPlaceholderText }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">Postal Code:</h4>
              <span
                :class="{
                  'text-danger': !vendorPostal,
                  'text-info': vendorPostal,
                }"
                >{{ vendorPostal ? vendorPostal : noDataPlaceholderText }}</span
              >
            </div>
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-0">Country:</h4>
              <span
                :class="{
                  'text-danger': !vendorCountry,
                  'text-info': vendorPostal,
                }"
                >{{
                  vendorCountry ? vendorCountry : noDataPlaceholderText
                }}</span
              >
            </div>
          </div>
        </div>
        <!-- social media -->
        <div class="col-12 my-2">
          <div class="alert bg-soft-secondary text-center">
            <h3 class="mb-0 fw-bold text-secondary">Social Media</h3>
          </div>
          <div class="row">
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="">Website:</h4>
              <span
                :class="{
                  'text-danger': !vendorWebsite,
                  'text-info': vendorWebsite,
                }"
              >
                <template v-if="vendorWebsite">
                  <div class="d-flex align-items-center gap-1">
                    <a
                      class="link"
                      title="Website URL"
                      :href="vendorWebsite"
                      target="_blank"
                      >{{ vendorWebsite }}</a
                    >
                  </div>
                </template>
                <template v-else>
                  {{ noDataPlaceholderText }}
                </template>
              </span>
            </div>
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-1">Twitter:</h4>
              <span
                :class="{
                  'text-danger': !vendorTwitter,
                  'text-info': vendorTwitter,
                }"
              >
                <template v-if="vendorTwitter">
                  <div class="d-flex">
                    <a
                      class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                      title="Website URL"
                      :href="vendorTwitter"
                      target="_blank"
                      ><b-icon-twitter></b-icon-twitter></a
                    ><span>{{ vendorTwitter }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ noDataPlaceholderText }}
                </template>
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-1">LinkedIn:</h4>
              <span
                :class="{
                  'text-danger': !vendorLinkedIn,
                  'text-info': vendorLinkedIn,
                }"
              >
                <template v-if="vendorLinkedIn">
                  <div class="d-flex">
                    <a
                      class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                      title="Website URL"
                      :href="vendorLinkedIn"
                      target="_blank"
                      ><b-icon-linkedin></b-icon-linkedin></a
                    ><span>{{ vendorLinkedIn }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ noDataPlaceholderText }}
                </template>
              </span>
            </div>
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-1">TikTok:</h4>
              <span
                :class="{
                  'text-danger': !vendorTikTok,
                  'text-info': vendorTikTok,
                }"
              >
                <template v-if="vendorTikTok">
                  <div class="d-flex">
                    <a
                      class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                      title="Website URL"
                      :href="vendorTikTok"
                      target="_blank"
                      ><b-icon-tiktok></b-icon-tiktok></a
                    ><span>{{ vendorTikTok }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ noDataPlaceholderText }}
                </template>
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-1">Instagram:</h4>
              <span
                :class="{
                  'text-danger': !vendorInstagram,
                  'text-info': vendorInstagram,
                }"
              >
                <template v-if="vendorInstagram">
                  <div class="d-flex">
                    <a
                      class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                      title="Website URL"
                      :href="vendorInstagram"
                      target="_blank"
                      ><b-icon-instagram></b-icon-instagram></a
                    ><span>{{ vendorInstagram }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ noDataPlaceholderText }}
                </template>
              </span>
            </div>
            <div class="col-6 text-start d-flex flex-column">
              <h4 class="mb-1">Gift card page:</h4>
              <span
                :class="{
                  'text-danger': !vendorGiftCardPage,
                  'text-info': vendorGiftCardPage,
                }"
              >
                <template v-if="vendorGiftCardPage">
                  <div class="d-flex align-items-center gap-1">
                    <a
                      class="link"
                      title="gift card page"
                      :href="vendorGiftCardPage"
                      target="_blank"
                      ><span>{{ vendorGiftCardPage }}</span></a
                    >
                  </div>
                </template>
                <template v-else>
                  {{ noDataPlaceholderText }}
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../ui/modals/templates/FixedModalSmall.vue";
import ProductCarousel from "../../../components/catalog/kits/ProductCarousel.vue";

export default {
  name: "QuickViewVendorModal",
  components: {
    FixedModalSmall,
    ProductCarousel,
  },
  // props: ["selectedVendor"],
  props: {
    selectedVendor: {
      type: Object,
      required: true,
    },
    isGiftCardPopUp: {
      type: Boolean,
      required: false,
    },
    media: {
      type: Array,
      required: false,
    },
  },
  created() {},
  mounted() {
    const modalContent = document.querySelector("#vendorModalContent");
    modalContent.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    const modalContent = document.querySelector("#vendorModalContent");
    modalContent.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      vendorMedia: [],
      options_1: {
        responsive: [{ size: 1 }],
      },
      options_2: {
        responsive: [{ size: 2 }],
      },
      scrollPositionIsAtTop: true,
      noDataPlaceholderText: "No information",
    };
  },
  computed: {
    vendorId() {
      return this.selectedVendor._id;
    },
    vendorName() {
      return this.selectedVendor.name;
    },
    vendorDescription() {
      return this?.selectedVendor?.description;
    },
    vendorCategories() {
      const categories = this?.selectedVendor?.categories;
      if (categories) {
        return categories.filter(
          (category) =>
            category.toLowerCase() !== "gift card" &&
            category.toLowerCase() !== "st. louis, mo"
        );
      }
      return categories;
    },
    vendorNeighborhoods() {
      const categories = this?.selectedVendor?.metadata?.neighborhoods;
      if (categories) {
        return categories.filter(
          (category) =>
            category.toLowerCase() !== "gift card" &&
            category.toLowerCase() !== "st. louis, mo"
        );
      }
      return categories;
    },
    vendorStreet() {
      return this?.selectedVendor?.address?.lines[0];
    },
    vendorCity() {
      return this?.selectedVendor?.address?.city;
    },
    vendorState() {
      return this?.selectedVendor?.address?.state;
    },
    vendorCountry() {
      return this?.selectedVendor?.address?.country;
    },
    vendorPostal() {
      return this?.selectedVendor?.address?.postalCode;
    },
    vendorContactName() {
      return this?.selectedVendor?.contact?.name;
    },
    vendorContactTitle() {
      return this?.selectedVendor?.contact?.title;
    },
    vendorContactEmail() {
      return this?.selectedVendor?.contact?.email;
    },
    vendorContactPhone() {
      return this?.selectedVendor?.contact?.phone;
    },
    vendorWebsite() {
      return this?.selectedVendor?.metadata?.website_url;
    },
    vendorTwitter() {
      return this?.selectedVendor?.metadata?.twitter_url;
    },
    vendorLinkedIn() {
      return this?.selectedVendor?.metadata?.linkedin_url;
    },
    vendorTikTok() {
      return this?.selectedVendor?.metadata?.tiktok_url;
    },
    vendorInstagram() {
      return this?.selectedVendor?.metadata?.instagram_url;
    },
    vendorGiftCardPage() {
      return this?.selectedVendor?.metadata?.gift_card_page;
    },
    vendorTypeformId() {
      const match =
        this?.selectedVendor?.metadata?.typeform_url.match(/\/to\/([^/]+)$/);
      if (match && match[1]) {
        return match[1];
      } else {
        return null;
      }
    },
    hasSocialLinks() {
      return (
        this.vendorWebsite ||
        this.vendorLinkedIn ||
        this.vendorTwitter ||
        this.vendorTikTok ||
        this.vendorInstagram
      );
    },
  },
  methods: {
    /** scroll behavior */
    handleScroll() {
      // Check if the scroll position is at the top
      this.scrollPositionIsAtTop =
        document.querySelector("#vendorModalContent").scrollTop === 0;
    },

    /** router */
    editVendor() {
      this.$router.push(`/edit/vendor/${this.vendorId}`);
    },
    viewVendorDetails() {
      this.$router.push(`/vendor/${this.vendorId}`);
    },
  },
};
</script>

<style scoped>
.badge {
  cursor: auto;
}
.card-img {
  object-fit: cover;
}

.custom-underline {
  position: relative;
  display: inline-block;
}

.custom-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px; /* Adjust the thickness of the underline */
  width: 100%;
  height: 4px; /* Adjust the height/thickness of the underline */
  background-color: #004aad; /* Replace with your desired color */
}
</style>
