<template>
  <template v-if="dataInfoArray">
    <div class="tw-flex tw-gap-3">
      <!-- Delete sections -->
      <template v-for="(data, index) in dataInfoArray" :key="index">
        <template v-if="data.type !== 'media'">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <template v-if="groupRecipients">
              <p class="tw-m-0 tw-text-lg tw-text-warning-500">
                You're about to remove {{ data.data.length }} recipients from
                this group.
              </p>
            </template>
            <template v-else>
              <p class="tw-m-0 tw-text-lg tw-text-red-500">
                This action is permanent. You won't be able to recover this data
                once deleted.
              </p>
            </template>

            <div class="tw-flex tw-gap-2">
              <TheButton variant="critical" size="large" @click="confirm()">
                <template #text>{{
                  groupRecipients ? "Remove" : "Delete"
                }}</template>
              </TheButton>
              <TheButton variant="tertiary" size="large" @click="cancel()">
                <template #text>Cancel</template>
              </TheButton>
            </div>
          </div>
          <div
            class="tw-w-full tw-bg-gray-50 tw-border-solid tw-border tw-rounded-lg"
          >
            <ul class="tw-list-none tw-h-full tw-p-4 tw-m-0">
              <template v-if="data.type === 'campaignTemplates'">
                <li v-for="item in data.data" :key="item.id" class="tw-h-full">
                  <template v-if="!isPrivate">
                    <p
                      class="tw-text-sm tw-m-0 tw-text-red-900 tw-underline tw-mb-2"
                    >
                      <b-icon-exclamation-circle
                        class="tw-mr-2"
                      ></b-icon-exclamation-circle
                      >Deleting a Bold Xchange template
                    </p>
                  </template>

                  <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                    <b-icon-check-circle class="tw-mr-2"></b-icon-check-circle
                    >{{ item.metadata.campaign_template.name }}
                  </p>
                  <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                    <b-icon-credit-card class="tw-mr-2"></b-icon-credit-card>${{
                      item.metadata.gift_card_amount
                    }}
                    gift card
                  </p>
                  <p
                    class="tw-pointer-events-none tw-block tw-truncate tw-text-sm tw-m-0 tw-text-gray-900"
                  >
                    <b-icon-geo-alt class="tw-mr-2"></b-icon-geo-alt>
                    <template v-if="neighborhoodList(item)">{{
                      neighborhoodList
                    }}</template>
                    <template v-else>St. Louis, MO</template>
                  </p>
                </li>
              </template>
              <template v-else>
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-h-full"
                >
                  <p class="tw-text-3xl tw-m-0 tw-font-bold">
                    {{ data.data.length }}
                  </p>
                  <template v-if="data.type === 'groupRecipients'"> </template>
                  <p class="tw-text-xl tw-m-0">
                    {{
                      data.type === "groupRecipients" ? "Recipients" : data.type
                    }}
                    will be
                    {{
                      data.type === "groupRecipients" ? "removed" : "deleted"
                    }}
                  </p>
                </div>
              </template>
              <!-- <li class="tw-h-full">
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-h-full"
                >
                  <p class="tw-text-3xl tw-m-0 tw-font-bold">
                    {{ data.data.length }}
                  </p>
                  <p class="tw-text-xl tw-m-0">
                    {{ data.type }} will be deleted
                  </p>
                </div>
              </li> -->
            </ul>
          </div>
        </template>
      </template>
    </div>
  </template>
</template>

<script>
import TheButton from "../actions/TheButton.vue";

export default {
  name: "DeleteData",
  components: {
    TheButton,
  },
  props: {
    budgets: {
      type: Array,
      required: false,
    },
    vendors: {
      type: Array,
      required: false,
    },
    members: {
      type: Array,
      required: false,
    },
    campaigns: {
      type: Array,
      required: false,
    },
    groups: {
      type: Array,
      required: false,
    },
    segments: {
      type: Array,
      required: false,
    },
    recipients: {
      type: Array,
      required: false,
    },
    groupRecipients: {
      type: Array,
      default: null,
    },
    invoices: {
      type: Array,
      required: false,
    },
    media: {
      type: Boolean,
      required: false,
    },
    /** campaign templates */
    campaignTemplates: {
      type: Array,
      required: false,
    },
    isPrivate: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    dataInfoArray() {
      const dataTypes = [
        { type: "budgets", data: this.budgets },
        { type: "campaigns", data: this.campaigns },
        { type: "campaignTemplates", data: this.campaignTemplates },
        { type: "vendors", data: this.vendors },
        { type: "members", data: this.members },
        { type: "groups", data: this.groups },
        { type: "segments", data: this.segments },
        { type: "recipients", data: this.recipients },
        { type: "groupRecipients", data: this.groupRecipients },
        { type: "invoices", data: this.invoices },
        { type: "media", data: this.media },
      ];

      return dataTypes.filter((dataInfo) => dataInfo.data);
    },
    neighborhoodList() {
      return (item) => {
        if (item?.metadata?.collection_query?.requestBody?.filter?.$and) {
          const andConditions =
            item?.metadata?.collection_query?.requestBody?.filter?.$and;
          if (andConditions && Array.isArray(andConditions)) {
            for (let condition of andConditions) {
              if (condition["metadata.neighborhoods"]) {
                return condition["metadata.neighborhoods"].$in.join(", ");
              }
            }
          }
        }
        return false;
      };
    },
  },
  methods: {
    confirm() {
      if (this.groupRecipients) {
        this.$emit("confirmRemove");
      } else {
        this.$emit("confirmDelete");
      }
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped></style>
