<template>
  <tr class="text-start align-middle">
    <QuickViewVendorModal
      v-if="isQuickViewActive"
      @cancel="toggleQuickView"
      :selectedVendor="this.vendor"
      :media="this.vendorMedia"
    ></QuickViewVendorModal>
    <CategoriesListModal
      v-if="isCategoryListModalVisible"
      @cancel="closeCategoryListModal"
      @setNewCategories="handleNewCategories"
    ></CategoriesListModal>
    <CreateCategoryModal
      v-if="isNewCategoryModalVisible"
      @cancel="closeCreateCategoryModal"
      @setNewCategory="setExistingCategory"
    ></CreateCategoryModal>
    <ConfirmDeleteModal
      :vendors="[this.selectedVendor]"
      @cancel="closeConfirmDeleteModal()"
      @confirm="deleteThisVendor()"
      v-if="isConfirmDeleteModalVisible"
    >
    </ConfirmDeleteModal>
    <td class="col-1">
      <div class="d-flex flex-column gap-2 text-center">
        <input
          :disabled="quickEditActive"
          type="checkbox"
          @change="selectRow"
          :checked="isSelected(this.vendor)"
        />
        <button
          :disabled="quickEditActive"
          v-if="!areQuickEditsActive"
          class="btn btn-xs"
          @click="toggleQuickEditView()"
          :class="{
            'btn-soft-danger': areQuickEditsActive,
            'btn-soft-secondary': !areQuickEditsActive,
          }"
        >
          quick edit
        </button>
        <button
          v-if="areQuickEditsActive"
          class="btn btn-xs"
          @click="revertChanges()"
          :class="{
            'btn-soft-danger': areQuickEditsActive,
            'btn-soft-secondary': !areQuickEditsActive,
          }"
        >
          cancel
        </button>
      </div>
    </td>
    <td class="col-auto">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1" v-if="areQuickEditsActive">
          <span v-if="selectedVendor.name === ''" class="mb-1"
            ><span class="text-danger">Vendor must have a name</span>
          </span>
          <input
            type="text"
            class="form-control border border-primary"
            name="vendorName"
            id="vendorName"
            placeholder="Your vendor name"
            aria-label="Your vendor name"
            v-model="selectedVendor.name"
          />
        </div>
        <div class="flex-grow-1" v-else>
          <h4 class="mb-0">
            {{ selectedVendor.name }}
          </h4>
        </div>
      </div>
    </td>
    <td class="col-auto text-center">
      <div class="flex-grow-1">
        <p class="text-inherit mb-0" v-if="selectedVendor?.address?.city">
          {{ selectedVendor.address.city }}
        </p>
        <p class="text-danger mb-0" v-if="!selectedVendor?.address?.city">
          {{ noDataPlaceholderText }}
        </p>
      </div>
    </td>
    <td class="col-auto text-center">
      <div class="flex-grow-1">
        <p class="text-inherit mb-0" v-if="selectedVendor?.address?.state">
          {{ selectedVendor.address.state }}
        </p>
        <p class="text-danger mb-0" v-if="!selectedVendor?.address?.state">
          {{ noDataPlaceholderText }}
        </p>
      </div>
    </td>
    <td class="col-1 text-center">
      <div class="flex-grow-1">
        <p class="text-inherit mb-0" v-if="selectedVendor?.address?.postalCode">
          {{ selectedVendor.address.postalCode }}
        </p>
        <p class="text-danger mb-0" v-if="!selectedVendor?.address?.postalCode">
          {{ noDataPlaceholderText }}
        </p>
      </div>
    </td>
    <td class="col-1 text-center">
      <div class="flex-grow-1">
        <p class="text-inherit mb-0" v-if="selectedVendor?.address?.country">
          {{ selectedVendor.address.country }}
        </p>
        <p class="text-danger mb-0" v-if="!selectedVendor?.address?.country">
          {{ noDataPlaceholderText }}
        </p>
      </div>
    </td>
    <td class="col-auto text-center">
      <span
        v-if="selectedVendor.categories.length === 0"
        class="mb-1 small text-danger d-block"
        >Add minimum one category
      </span>
      <div v-if="areQuickEditsActive" class="btn-group">
        <button
          type="button"
          class="form-select border border-primary"
          id="dropdownCategoryList"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="badge bg-soft-info me-2">{{
            selectedVendor.categories.length
          }}</span
          >Categories
        </button>
        <div
          class="dropdown-menu dropdown-menu-end mt-1"
          aria-labelledby="dropdownCategoryList"
        >
          <span class="dropdown-header">Edit Categories</span>
          <ul class="list-checked text-muted">
            <li
              class="list-item text-start p-1"
              v-for="(category, key) in selectedVendor.categories"
              :key="'item-' + key"
            >
              <button
                class="dropdown-item btn btn-soft-danger"
                @click.stop="removeCategorySelected(category)"
              >
                <b-icon-x-circle></b-icon-x-circle
                ><span class="ms-2">{{ category }}</span>
              </button>
            </li>
            <li
              class="list-item text-start text-danger p-1"
              v-if="selectedVendor.categories.length === 0"
            >
              No categories exist for this kit. Please add a minimum of one
              category.
            </li>
          </ul>
          <div class="dropdown-divider"></div>
          <button
            class="dropdown-item text-primary text-start"
            @click="showCategoryListModal()"
          >
            <b-icon-view-list class="me-2"></b-icon-view-list>Choose from list
          </button>
          <div class="dropdown-divider"></div>
          <button
            class="dropdown-item text-primary text-start"
            @click="showCreateCategoryModal()"
          >
            <b-icon-plus-square class="me-2"></b-icon-plus-square>Create new
            category
          </button>
        </div>
      </div>
      <div v-else class="btn-group">
        <button
          type="button"
          class="form-select"
          id="dropdownCurrentCategoryList"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="fw-bold text-underline text-info me-2">{{
            selectedVendor.categories.length
          }}</span
          >Categories
        </button>
        <div
          class="dropdown-menu dropdown-menu-end mt-1"
          aria-labelledby="dropdownCurrentCategoryList"
        >
          <span class="dropdown-header">Current Categories</span>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              v-for="item in selectedVendor.categories"
              :key="item.index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </td>
    <!-- status -->
    <td class="col-auto text-center">
      <!-- <template v-if="isOnline"> -->
      <template v-if="!areQuickEditsActive">
        <TooltipStatus>
          <span
            class="badge text-capitalize"
            :class="onlineStatusClass"
            @mouseover="showTooltip = true"
            @mouseout="showTooltip = false"
          >
            {{ isOnline ? "Online" : "Offline" }}
          </span>
          <template #tooltipContent> {{ tooltipContent }} </template>
        </TooltipStatus>
      </template>
      <template v-if="areQuickEditsActive">
        <select
          class="form-select border-dark"
          id="online_status"
          aria-label="online status"
          required
          v-model="selectedVendor.metadata.isOnline"
        >
          <option value="" class="text-dark">Select an option</option>
          <option :value="true">Online</option>
          <option :value="false">Offline</option>
        </select>
      </template>
      <!-- </template> -->
    </td>
    <!-- actions -->
    <td class="col-auto text-center">
      <template v-if="areQuickEditsActive">
        <div>
          <p class="text-danger mb-1" v-if="!checkIfQuickEditsAreComplete">
            Form is incomplete.
          </p>
          <template v-if="checkIfQuickEditsAreComplete">
            <button
              @click.stop="updateThisVendor()"
              type="button"
              class="btn btn-primary btn-xs fw-bold"
            >
              Save changes
            </button>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="btn-group" role="group" aria-label="Button group">
          <button
            @click.stop="toggleQuickView()"
            type="button"
            class="btn btn-xs fw-bold btn-info"
          >
            Quick View
          </button>
          <div class="btn-group" role="group">
            <button
              type="button"
              class="d-flex align-items-center btn btn-xs btn-soft-primary border-0"
              id="dropdownActionList"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <b-icon-chevron-down class=""></b-icon-chevron-down>
            </button>
            <ul class="dropdown-menu">
              <span class="dropdown-header">Options</span>

              <li>
                <a class="dropdown-item" @click="viewVendorDetails()"
                  ><b-icon-eyeglasses class="me-2"></b-icon-eyeglasses>
                  View
                </a>
              </li>
              <li>
                <a class="dropdown-item" @click="editVendor()"
                  ><b-icon-pencil class="me-2"></b-icon-pencil>
                  Edit
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item text-danger text-center"
                  @click="showConfirmDeleteModal()"
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </td>
  </tr>
</template>
<script>
import ConfirmDeleteModal from "../../components/ui/modals/ConfirmDeleteModal.vue";
import CategoriesListModal from "../../components/ui/modals/lists/CategoriesListModal.vue";
import CreateCategoryModal from "../../components/ui/modals/create/CreateCategoryModal.vue";
import QuickViewVendorModal from "../../components/ui/modals/QuickViewVendorModal.vue";

export default {
  name: "VendorListItem",
  components: {
    ConfirmDeleteModal,
    // UpdateProductMetadataModal,
    CategoriesListModal,
    CreateCategoryModal,
    QuickViewVendorModal,
  },
  props: ["vendor", "selectedRows", "selectAll", "quickEditActive"],
  data() {
    return {
      noDataPlaceholderText: "no info",
      isQuickViewActive: false,
      areQuickEditsActive: false,
      selectedVendor: {},
      categories: [],
      isProductMetadataPrivate: true,
      isUpdateProductMetadataModalVisible: false,
      isConfirmDeleteModalVisible: false,
      vendorMedia: [],
      isCategoryListModalVisible: false,
      isNewCategoryModalVisible: false,
      doesCategoryExist: false,
      originalCategories: [],
      originalCity: "Update city",
      originalState: "Update state",
      originalPostal: "Update postal",
      currentCategories: [],
      currentCity: null,
      currentState: null,
      currentPostal: null,
    };
  },
  created() {
    this.handleVendorMedia();

    this.selectedVendor = JSON.parse(JSON.stringify(this.vendor));
  },
  computed: {
    checkIfQuickEditsAreComplete() {
      if (this.selectedVendor.name === "" || this.selectedVendor.length === 0) {
        return false;
      }
      return true;
    },
    getCategories() {
      return [];
    },
    getVendorName() {
      const name = this.vendor.name;
      return name;
    },
    getVendorId() {
      return this.vendor._id;
    },
    isMediaAvailable() {
      if (this.vendorMedia.length < 1) {
        return false;
      }
      return true;
    },
    getMetadataStatus() {
      return true;
    },
    isOnline() {
      if (this?.vendor?.metadata?.isOnline !== undefined) {
        return this?.vendor?.metadata?.isOnline;
      }
      return false;
    },
    /** classes */
    onlineStatusClass() {
      return {
        "bg-soft-success text-dark fw-bold": this.isOnline,
        "bg-danger text-white": !this.isOnline,
      };
    },
  },
  methods: {
    /** update actions */
    async updateThisVendor() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      try {
        const api = await this.getBoldClient();
        const vendorId = this.getVendorId;

        await Promise.all([this.updateSingleVendor(api, vendorId)]);
        console.log("vendor update successful");
        this.$emit("showSuccessMessage");
      } catch (error) {
        console.log("error updating vendor", error);
        this.$emit("showErrorMessage");
      }
    },
    async updateSingleVendor(api, vendorId) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      await api.upsertVendor(null, {
        filter: {
          _id: vendorId,
        },
        patch: {
          name: this.selectedVendor.name,
          categories: this.selectedVendor.categories,
          "metadata.isOnline": this.selectedVendor.metadata.isOnline || false,
        },
      });
    },
    async deleteThisVendor() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      try {
        const api = await this.getBoldClient();
        const vendorId = this.getVendorId;

        await Promise.all([this.deleteSingleVendor(api, vendorId)]);
        console.log("vendor update successful");
        this.$emit("showSuccessMessage");
      } catch (error) {
        console.log("error updating vendor", error);
        this.$emit("showErrorMessage");
      }
    },
    async deleteSingleVendor(api, vendorId) {
      api.deleteVendors(null, {
        filter: {
          _id: vendorId,
        },
      });
    },

    /** navigation */
    viewVendorDetails() {
      this.$router.push(`/vendor/${this.getVendorId}`);
    },
    editVendor() {
      this.$router.push(`/vendor/edit/${this.getVendorId}`);
    },

    /** modals */
    showConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    closeUpdateProductMetadataModal() {
      this.isUpdateProductMetadataModalVisible = false;
    },
    showCreateCategoryModal() {
      this.isNewCategoryModalVisible = true;
    },
    closeCreateCategoryModal() {
      this.isNewCategoryModalVisible = false;
    },
    showCategoryListModal() {
      this.isCategoryListModalVisible = true;
    },
    closeCategoryListModal() {
      this.isCategoryListModalVisible = false;
    },
    handleNewCategories(newCategories) {
      // Handle the array of selected categories
      for (const category of newCategories) {
        this.setExistingCategory(category);
      }
      this.isCategoryListModalVisible = false;
    },
    setExistingCategory(category) {
      const existingCategory = this.selectedVendor.categories.find(
        (cat) => cat === category
      );

      if (!existingCategory) {
        this.selectedVendor.categories.push(category);
        this.doesCategoryExist = false;
      } else {
        this.doesCategoryExist = true;
        setTimeout(() => {
          this.doesCategoryExist = false;
        }, 2000);
      }
      this.isNewCategoryModalVisible = false;
    },
    removeCategorySelected(category) {
      // Create a copy of selectedVendor.categories
      let newArray = this.selectedVendor.categories.slice();

      // Find the index of the category to remove
      let index = newArray.indexOf(category);

      // Remove the category from the copied array
      if (index > -1) {
        newArray.splice(index, 1);
      }

      // Update selectedVendor.categories with the modified array
      this.selectedVendor.categories = newArray;
    },

    /** quick edits */
    isSelected(vendor) {
      return (
        this.selectAll ||
        this.selectedRows.some((selected) => selected._id === vendor._id)
      );
    },
    selectRow() {
      this.$emit("row-selected", this.vendor);
    },
    revertChanges() {
      this.$emit("deactivateQuickEdit"); // Emit event to deactivate quick edit
      this.selectedVendor = JSON.parse(JSON.stringify(this.vendor));
      this.areQuickEditsActive = false; // You might want to reset this as well
    },
    toggleQuickEditView() {
      this.$emit("activateQuickEdit"); // Emit event to activate quick edit
      this.selectedVendor = JSON.parse(JSON.stringify(this.vendor));
      // this.areQuickEditsActive = false;
      this.areQuickEditsActive = !this.areQuickEditsActive;
    },
    toggleQuickView() {
      this.isQuickViewActive = !this.isQuickViewActive;
    },

    /** handle media */
    async handleVendorMedia() {
      const api = await this.getBoldClient();
      const mediaArr = this.vendor.media;

      const giftCardsMedia = this.filterMediaByTag(mediaArr, "gift cards"); // array of media files
      const brandingMedia = this.filterMediaByTag(mediaArr, "vendor branding"); // array of media files

      const combinedMedia = [...giftCardsMedia, ...brandingMedia]; // Combine both arrays
      this.createMediaUrls(combinedMedia, this.vendorMedia, api);
    },
    createMediaUrls(mediaArr, mediaUrlArr, api) {
      mediaUrlArr.length = 0;

      for (const media of mediaArr) {
        const mediaId = media._id;
        const mediaUrl = api.utils.getMediaUrl(mediaId);
        mediaUrlArr.push({ _id: mediaId, url: mediaUrl });
      }
      this.vendorMedia = mediaUrlArr;
    },
    filterMediaByTag(mediaArr, tag) {
      return mediaArr.filter((media) => {
        return media.metadata.tags.includes(tag);
      });
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}

.badge {
  cursor: default;
}

.overlay {
  position: relative; /* Adjust position to your specific needs */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure it's above other elements */
  pointer-events: none; /* Allow click-through for non-overlay elements */
}
</style>
