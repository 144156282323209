import { createRouter, createWebHistory } from "vue-router";
/** STORE */
import store from "./store/index.js";

/** AUTH */
import AccountSettings from "./pages-v2/account/AccountSettings.vue";
import LoginPortal from "./pages-v2/auth/LoginPortal";
import OnetimePasscodeLogin from "./pages-v2/auth/OnetimePasscodeLogin";
import PageNotFound from "./pages-v2/auth/PageNotFound";
import ResetPassword from "./pages-v2/auth/ResetPassword.vue";
import FinishResetPassword from "./pages-v2/auth/FinishResetPassword.vue";
import LandingPage from "./pages-v2/auth/LandingPage.vue";

/** ONBOARDING */
import CustomerRegistration from "./pages-v2/onboarding/customer-registration/CustomerRegistration.vue";
import CustomerRegistrationSuccess from "./pages-v2/onboarding/customer-registration/CustomerRegistrationSuccess.vue";
import CustomerRegistrationFailure from "./pages-v2/onboarding/customer-registration/CustomerRegistrationFailure.vue";

/** DASHBOARDS */
import TheDashboard from "./pages-v2/analytics/TheDashboard.vue";

/** PAYMENTS */
import PaymentSetupSuccess from "./pages-v2/payments/PaymentSetupSuccess.vue";
import PaymentSetupFailure from "./pages-v2/payments/PaymentSetupFailure.vue";

/** MEMBERS */
import ViewMembers from "./pages-v2/members/ViewMembers.vue";
import AddMember from "./pages-v2/members/AddMember";
import EditMember from "./pages-v2/members/EditMember.vue";
import MemberDetails from "./pages-v2/members/MemberDetails.vue";

/** CAMPAIGNS */
import ViewCampaigns from "./pages-v2/campaigns/ViewCampaigns.vue";
import CampaignDetails from "./pages-v2/campaigns/CampaignDetailsV2.vue";
import CampaignGift from "./pages-v2/campaigns/CampaignGift.vue";
// import CampaignBuilder from "./pages-v2/campaigns/CampaignBuilder.vue";
import CampaignBuilderV2 from "./pages-v2/campaigns/CampaignBuilderV2.vue";

/** BUDGETS */
import ViewBudgets from "./pages-v2/budgets/ViewBudgets.vue";
import BudgetDetails from "./pages-v2/budgets/BudgetDetails.vue";

/** RECIPIENTS */
import ViewRecipients from "./pages-v2/recipients/ViewRecipients.vue";
import ImportRecipients from "./pages-v2/recipients/ImportRecipients.vue";
import RecipientDetails from "./pages-v2/recipients/RecipientDetails.vue";

/** VENDORS */
import ViewVendors from "./pages-v2/vendors/ViewVendors.vue";
import AddVendor from "./pages-v2/vendors/AddVendor";
import ImportVendors from "./pages-v2/vendors/ImportVendors.vue";
import VendorsOptions from "./pages-v2/vendors/VendorsOptions.vue";
import EditVendor from "./pages-v2/vendors/EditVendor.vue";
import VendorDetails from "./pages-v2/vendors/VendorDetails.vue";

/** GIFT CARDS */
import GiftCardsCollection from "./pages-v2/gift-cards/GiftCardsCollection.vue";

/** INVOICES */
import ViewInvoices from "./pages-v2/invoices/ViewInvoices.vue";
import InvoiceDetails from "./pages-v2/invoices/InvoiceDetails.vue";

/** REDEMPTIONS */
import ViewRedemptions from "./pages-v2/redemptions/ViewRedemptions.vue";

/** INTEGRATIONS */
import ViewIntegrations from "./pages-v2/integrations/ViewIntegrations.vue";
import GoogleCalendarIntegration from "./pages-v2/integrations/GoogleCalendarIntegration.vue";
import Office365Integration from "./pages-v2/integrations/Office365Integration.vue";
import SalesforceIntegration from "./pages-v2/integrations/SalesforceIntegration.vue";
import HubSpotIntegration from "./pages-v2/integrations/HubSpotIntegration.vue";

/** TEMPLATES */
import ViewTemplates from "./pages-v2/templates/ViewTemplates.vue";
// import CampaignTemplateBuilder from "./pages-v2/templates/CampaignTemplateBuilder.vue";
import CampaignTemplateBuilderV2 from "./pages-v2/templates/CampaignTemplateBuilderV2.vue";

/** GROUPS & SEGMENTS */
import ViewRecipientGroups from "./pages-v2/groups/ViewRecipientGroups.vue";
import RecipientGroupDetails from "./pages-v2/groups/RecipientGroupDetails.vue";
import RecipientSegmentDetails from "./pages-v2/segments/RecipientSegmentDetails.vue";

// Utility functions for role verification
function hasRole(user, requiredRoles) {
  return requiredRoles.includes(user.role);
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    /** AUTH */
    {
      path: "/login",
      name: "LoginPortal",
      component: LoginPortal,
      meta: {
        authOnly: false,
        noUser: true,
      },
    },
    {
      path: "/onetime-passcode-login",
      name: "OnetimePasscodeLogin",
      component: OnetimePasscodeLogin,
      meta: {
        authOnly: false,
        noUser: true,
      },
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        authOnly: false,
        noUser: true,
      },
    },
    {
      path: "/reset-password/:token",
      name: "FinishResetPassword",
      component: FinishResetPassword,
      meta: {
        authOnly: false,
        noUser: true,
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "PageNotFound",
      component: PageNotFound,
      meta: {
        authOnly: false,
      },
    },
    {
      path: "/",
      name: "LandingPage",
      component: LandingPage,
      meta: {
        authOnly: false,
      },
    },
    {
      path: "/account",
      component: AccountSettings,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead", "recipient"],
      },
    },

    /** ONBOARDING */
    {
      path: "/signup/plan/:metadataTag",
      name: "CustomerRegistration",
      component: CustomerRegistration,
      meta: {
        authOnly: false,
        noUser: true,
      },
    },
    // {
    //   path: "/signup/plan/:metadataTag",
    //   name: "SubscriptionSignup",
    //   component: CustomerRegistration,
    //   meta: {
    //     authOnly: false,
    //   },
    // },
    // {
    //   path: "/signup/new/yearly-individual",
    //   name: "CustomerRegistration",
    //   component: CustomerRegistration,
    //   meta: {
    //     authOnly: false,
    //   },
    // },
    {
      path: "/subscription-payment/success/:CHECKOUT_SESSION_ID",
      name: "CustomerRegistrationSuccess",
      component: CustomerRegistrationSuccess,
      meta: {
        authOnly: false,
        noUser: true,
      },
    },
    {
      path: "/gc-marketplace/setup-failure",
      name: "CustomerRegistrationFailure",
      component: CustomerRegistrationFailure,
      meta: {
        authOnly: false,
      },
    },

    /** SETUP PAYMENT METHOD */
    {
      path: "/payment-method/save/:CHECKOUT_SESSION_ID",
      name: "PaymentSetupSuccess",
      component: PaymentSetupSuccess,
      meta: {
        authOnly: true,
        adminOnly: false,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/payment-setup/setup-failure",
      name: "PaymentSetupFailure",
      component: PaymentSetupFailure,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },

    /** MEMBERS */
    {
      path: "/members",
      name: "ViewMembers",
      component: ViewMembers,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/add/member",
      component: AddMember,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/member/edit/:memberId",
      component: EditMember,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/member/:memberId",
      name: "MemberDetails",
      component: MemberDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },

    /** CAMPAIGNS */
    {
      path: "/campaigns",
      name: "ViewCampaigns",
      component: ViewCampaigns,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/campaign/report/:campaignId",
      name: "CampaignDetails",
      component: CampaignDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/gift/:campaignId",
      name: "CampaignGift",
      component: CampaignGift,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead", "recipient"],
      },
    },
    {
      path: "/campaign/edit/:campaignId/:CHECKOUT_SESSION_ID?",
      name: "CampaignBuilderV2",
      component: CampaignBuilderV2,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },

    /** BUDGETS */
    {
      path: "/budgets/:CHECKOUT_SESSION_ID?",
      name: "ViewBudgets",
      component: ViewBudgets,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/budget/:budgetId",
      name: "BudgetDetails",
      component: BudgetDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },

    /** RECIPIENTS */
    {
      path: "/recipients",
      name: "ViewRecipients",
      component: ViewRecipients,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/recipients/import/:campaignId?",
      name: "ImportRecipients",
      component: ImportRecipients,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/recipient/:recipientId",
      name: "RecipientDetails",
      component: RecipientDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },

    /** VENDORS */
    {
      path: "/vendors",
      name: "ViewVendors",
      component: ViewVendors,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/import/vendors",
      name: "ImportVendors",
      component: ImportVendors,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/vendors/options",
      component: VendorsOptions,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/vendor/add",
      component: AddVendor,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/vendor/edit/:vendorId",
      name: "EditVendor",
      component: EditVendor,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/vendor/:vendorId",
      name: "VendorDetails",
      component: VendorDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },

    /** GIFT CARDS */
    {
      path: "/home",
      name: "GiftCardsCollection",
      component: GiftCardsCollection,
      meta: {
        authOnly: false,
      },
    },

    /** DASHBOARDS */
    {
      path: "/dashboard",
      name: "TheDashboard",
      component: TheDashboard,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },

    /** INVOICES */
    {
      path: "/invoices",
      name: "ViewInvoices",
      component: ViewInvoices,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
    {
      path: "/invoice/:invoiceId",
      name: "InvoiceDetails",
      component: InvoiceDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },

    /** REDEMPTIONS */
    {
      path: "/gifts",
      name: "ViewRedemptions",
      component: ViewRedemptions,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead", "recipient"],
      },
    },

    /** INTEGRATIONS */
    {
      path: "/integrations",
      name: "ViewIntegrations",
      component: ViewIntegrations,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/integrations/salesforce",
      name: "SalesforceIntegration",
      component: SalesforceIntegration,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/integrations/google",
      name: "GoogleCalendarIntegration",
      component: GoogleCalendarIntegration,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/integrations/office365",
      name: "Office365Integration",
      component: Office365Integration,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/integrations/hubspot",
      name: "HubSpotIntegration",
      component: HubSpotIntegration,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },

    /** TEMPLATES */
    {
      path: "/templates",
      name: "ViewTemplates",
      component: ViewTemplates,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    // {
    //   path: "/template/edit/:campaignId",
    //   name: "CampaignTemplateBuilder",
    //   component: CampaignTemplateBuilder,
    //   meta: {
    //     authOnly: true,
    //     permittedRoles: ["boldxchange/admin", "giftingLead"],
    //   },
    // },
    {
      path: "/template/edit/:campaignId",
      name: "CampaignTemplateBuilderV2",
      component: CampaignTemplateBuilderV2,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    /** GROUPS */
    {
      path: "/groups",
      name: "ViewRecipientGroups",
      component: ViewRecipientGroups,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/recipients/group/:groupId",
      name: "RecipientGroupDetails",
      component: RecipientGroupDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin", "giftingLead"],
      },
    },
    {
      path: "/recipients/segment/:segmentId",
      name: "RecipientSegmentDetails",
      component: RecipientSegmentDetails,
      meta: {
        authOnly: true,
        permittedRoles: ["boldxchange/admin"],
      },
    },
  ],
});
router.beforeEach(async (to, from, next) => {
  let isAuthenticated = !!store.state.auth.user;
  let currentUser = store.state.auth.user;

  if (!isAuthenticated && window.localStorage.getItem("jwt")) {
    store.commit("auth/SET_TOKEN", window.localStorage.getItem("jwt"));
    try {
      await store.dispatch("auth/refreshUser");
      // Re-check authentication state after attempting to refresh user details
      isAuthenticated = !!store.state.auth.user;
      currentUser = store.state.auth.user;
    } catch (e) {
      console.error(e);
    }
  }

  if (to.meta.authOnly) {
    if (!isAuthenticated) {
      store.commit("auth/SET_TARGET_ROUTE", to);
      // console.log("targetroute: ", to);
      return next({ name: "LoginPortal" });
    }

    if (
      to.meta.permittedRoles &&
      !hasRole(currentUser, to.meta.permittedRoles)
    ) {
      console.error(
        "Authenticated user does not have the necessary role for this page"
      );
      return next({ name: "PageNotFound" });
    }
  } else {
    if (
      to.meta.permittedRoles &&
      isAuthenticated &&
      !hasRole(currentUser, to.meta.permittedRoles)
    ) {
      console.error(
        "Authenticated user does not have the necessary role for this public page"
      );
      return next({ name: "PageNotFound" });
    }
  }

  if (to.meta.noUser && isAuthenticated) {
    return next({ path: "/" });
  }

  next();
});

export default router;
